import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { renderClasses } from '../../utils'
import { Icon } from '../Icon'
import { HeaderContainer } from '../Header'
import { Modal, ModalContainer } from '../Modal'

import './_authView.scss'

const AuthView = ({ children, isAuthenticated, ...classProps }) => (
    <div className={renderClasses('authView', classProps)}>
        <div className="authView_logo_station_container">
            <div className="authView_logo_station u-anim -delay1">
                <Icon name="logo-station" />
            </div>
        </div>
        {isAuthenticated && <HeaderContainer type="simple" />}
        {children}
        <ModalContainer
            render={({ modalId, handleClickClose, handleClickOpen, handleModalSubmit, isOpen, data }) => (
                <Modal
                    modalId={modalId}
                    handleClickClose={handleClickClose}
                    handleClickOpen={handleClickOpen}
                    handleModalSubmit={handleModalSubmit}
                    isOpen={isOpen}
                    data={data}
                />
            )}
        />
        <ToastContainer position="bottom-right" hideProgressBar draggable={false} theme="colored" />
    </div>
)

AuthView.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(AuthView)
