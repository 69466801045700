import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import './_calendarDate.scss'

const CalendarDate = ({ date = null }) => {
    date = _.isEmpty(date) ? moment() : moment(date)

    return (
        <div className="calendarDate">
            <div className="calendarDate_header">{date.format('MMMM')}</div>
            <div className="calendarDate_main">{date.format('DD')}</div>
            <div className="calendarDate_footer">{date.format('YYYY')}</div>
        </div>
    )
}

CalendarDate.propTypes = {
    date: PropTypes.string,
}

export default CalendarDate
