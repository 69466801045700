import {
    FETCH_PROJECT_DETAILS_REQUEST,
    FETCH_PROJECT_DETAILS_SUCCESS,
    FETCH_PROJECT_DETAILS_FAILURE,
    CREATE_PROJECT_REQUEST,
    CREATE_PROJECT_SUCCESS,
    CREATE_PROJECT_FAILURE,
    UPDATE_PROJECT_DETAILS_REQUEST,
    UPDATE_PROJECT_DETAILS_SUCCESS,
    UPDATE_PROJECT_DETAILS_FAILURE,
    REFRESH_PROJECT_REQUEST,
} from '../actions/projectActions'
import { SUBMIT_MODAL } from '../actions/viewActions'

const initialState = {
    data: {},
    errors: null,
    id: null,
    isLoading: true,
    wasQueried: false,
    shouldRefresh: false,
}

export function currentProject(state = initialState, action) {
    switch (action.type) {
        case FETCH_PROJECT_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
                errors: null,
            }
        case FETCH_PROJECT_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.result.data,
                errors: null,
                id: action.result.data.id,
                isLoading: false,
                wasQueried: true,
                shouldRefresh: false,
            }
        case FETCH_PROJECT_DETAILS_FAILURE:
            return {
                ...initialState,
                errors: action.error.response.data,
                isLoading: false,
                wasQueried: true,
                shouldRefresh: false,
            }

        /**
         * Create a new project.
         */
        case CREATE_PROJECT_REQUEST:
            return {
                ...state,
            }
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                data: action.result.data,
            }
        case CREATE_PROJECT_FAILURE:
            return {
                ...state,
            }

        /**
         * Update the current project.
         */
        case UPDATE_PROJECT_DETAILS_REQUEST:
            return {
                ...state,
            }
        case UPDATE_PROJECT_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.result.data,
            }
        case UPDATE_PROJECT_DETAILS_FAILURE:
            return {
                ...state,
            }

        /**
         * Force project refresh
         */
        case REFRESH_PROJECT_REQUEST:
        case SUBMIT_MODAL:
            return {
                ...state,
                shouldRefresh: true,
            }

        default:
            return state
    }
}
