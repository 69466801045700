import { apiClient } from './apiClient'
export const baseUrl = 'uploads'

/**
 * Load a public upload.
 */
export function loadPublicUpload(path, config = {}) {
    return apiClient.get(`${baseUrl}/public?load=${path}`, {}, config)
}

/**
 * Create a public upload.
 */
export function createPublicUpload(formData, config = {}) {
    return apiClient.post(`${baseUrl}/public`, formData, config)
}

/**
 * Delete a public upload.
 */
export function deletePublicUpload(config = {}) {
    return apiClient.delete(`${baseUrl}/public`, config)
}
