import moment from 'moment'
import React from 'react'

import { renderClasses } from '../../utils'
import { ButtonLabel } from '../Button'
import { CalendarDate } from '../CalendarDate'
import { PanelContainer } from '../Panel'
import { Translation } from '../Translation'

import './_panelFooter.scss'

const PanelFooter = ({ project, ...classProps }) => (
    <div className={renderClasses('panelFooter', classProps)}>
        <PanelContainer>
            {(project.timeline !== null || project.launchDate !== null) && (
                <div className="panelFooter_wrap">
                    {project.timeline !== null && (
                        <React.Fragment>
                            <div className="panelFooter_icon">
                                <ButtonLabel href={project.timeline.downloadUrl} isBlank icon="gant">
                                    <Translation value="common.planning" />
                                </ButtonLabel>
                            </div>
                            {project.timeline.revisionDate !== null && (
                                <div className="panelFooter_infos">
                                    <p>
                                        <Translation value="milestoneDetails.timelineModified" />
                                        {` ${moment(project.timeline.revisionDate).format('MMM DD, YYYY')}`}
                                    </p>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {project.launchDate !== null && (
                        <div className="panelFooter_calendar">
                            <div className="panelFooter_date">
                                <CalendarDate date={project.launchDate} />
                            </div>
                            <p>
                                <Translation value="milestoneDetails.launchDate" />
                            </p>
                        </div>
                    )}
                </div>
            )}
        </PanelContainer>
    </div>
)

export default PanelFooter
