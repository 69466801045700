import classNames from 'classnames'
import Immutable from 'immutable'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { createResource, updateResource } from '../../actions/resourceActions'
import { formatFormNotifications, isURL, translate as utilTranslate } from '../../utils'

import { Button, ButtonCircle } from '../Button'
import {
    FormContainer,
    FormError,
    FormInput,
    FormInputChoice,
    FormItem,
    FormLabel,
    FormNotifications,
    FormSelect,
} from '../Form'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { ResourceTypeContainer } from '../Resource'
import { Translation } from '../Translation'

const ModalResourceDetails = ({
    data: { resource = null, group },
    formActions,
    handleModalSubmit,
    handleClickClose,
    project,
    translate,
}) => {
    const isEdit = !_.isEmpty(resource) ? true : false
    const [initialValues, setInitialValues] = useState(Immutable.Map({}))

    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(resource)) {
            setInitialValues(Immutable.Map(resource))
        }
        return () => {
            setInitialValues(Immutable.Map({}))
        }
    }, [resource])

    const milestones = project.milestones.map(item => ({
        id: item.id,
        label: utilTranslate(item.name),
    }))

    const resourceGroups = project.resources.map(item => ({
        id: item.id,
        label: utilTranslate(item.name),
    }))

    const handleSubmit = useCallback(
        ({ event, form: { resetFields, validateFields } }) => {
            event.preventDefault()

            if (isLoading) {
                return false
            }

            validateFields(async (formErrors, formValues) => {
                if (!formErrors) {
                    const action = isEdit
                        ? formActions['update'](project.id, resource.id, formValues.modal)
                        : formActions['create'](project.id, formValues.modal)

                    setIsLoading(true)
                    action
                        .then(() => handleModalSubmit())
                        .then(() => {
                            setIsLoading(false)
                            handleClickClose()
                            resetFields()
                        })
                        .catch(error => {
                            setErrors(error.response.data)
                            setIsLoading(false)
                        })
                }
            })
            return false
        },
        [formActions, handleClickClose, handleModalSubmit, isEdit, isLoading, project, resource]
    )

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-resources-form">
            {isEdit ? (
                <header className="modal-heading u-padding-small">
                    <ModalContainer
                        render={({ handleClickOpen }) => (
                            <ButtonCircle
                                icon="close"
                                option={['small', 'gray']}
                                onClick={handleClickOpen}
                                htmlAttrs={{
                                    'data-item-id': 'resourceList',
                                    'data-item-data': `${JSON.stringify({
                                        group: group,
                                        items: group.resources,
                                    })}`,
                                }}
                            />
                        )}
                    />
                    <span className="u-margin-small-x">
                        <Translation value="modal.resource.form-title-edit" />
                    </span>
                </header>
            ) : (
                <ModalHeading handleClickClose={handleClickClose}>
                    <Translation value="modal.resource.form-title-add" />
                </ModalHeading>
            )}
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormLabel inputId="modal.group" isRequired>
                                    <Translation value="modal.resource.form-label-group" />
                                </FormLabel>
                                <FormSelect
                                    id="modal.group"
                                    name="modal.group"
                                    form={form}
                                    items={resourceGroups}
                                    defaultItem={{
                                        label: translate('modal.resource.form-label-choose-group'),
                                        value: '',
                                    }}
                                    fieldOptions={{
                                        initialValue: group.id,
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.group')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="modal.name" isRequired>
                                    <Translation value="modal.resource.form-label-name" />
                                </FormLabel>
                                <FormInput
                                    id="modal.name"
                                    name="modal.name"
                                    form={form}
                                    fieldOptions={{
                                        initialValue:
                                            initialValues.get('name') instanceof Object
                                                ? utilTranslate(initialValues.get('name'))
                                                : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.name')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="modal.milestone" isRequired>
                                    <Translation value="modal.resource.form-label-milestone" />
                                </FormLabel>
                                <FormSelect
                                    id="modal.milestone"
                                    name="modal.milestone"
                                    form={form}
                                    items={milestones}
                                    defaultItem={{
                                        label: translate('modal.resource.form-label-choose-milestone'),
                                        value: '',
                                    }}
                                    fieldOptions={{
                                        initialValue: initialValues.get('milestone')
                                            ? initialValues.get('milestone').id
                                            : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.milestone')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="modal.url" isRequired>
                                    <Translation value="modal.resource.form-label-link" />
                                </FormLabel>
                                <FormInput
                                    id="modal.url"
                                    name="modal.url"
                                    form={form}
                                    fieldOptions={{
                                        initialValue: initialValues.get('url') ?? '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                            {
                                                validator: (rule, value, callback) => {
                                                    if (!isURL(value)) {
                                                        callback(translate('form.invalidURL'))
                                                    } else {
                                                        callback()
                                                    }
                                                },
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.url')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="modal.type" isRequired>
                                    <Translation value="modal.resource.form-label-type" />
                                </FormLabel>
                                <ResourceTypeContainer
                                    render={({ resourceTypes }) => (
                                        <FormSelect
                                            id="modal.type"
                                            name="modal.type"
                                            form={form}
                                            items={resourceTypes}
                                            defaultItem={{
                                                label: translate('modal.resource.form-label-choose-type'),
                                                value: '',
                                            }}
                                            fieldOptions={{
                                                initialValue: initialValues.get('type')
                                                    ? initialValues.get('type').id
                                                    : '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: translate('form.requiredField'),
                                                    },
                                                ],
                                            }}
                                        />
                                    )}
                                />
                                <FormError>{form.getFieldError('modal.type')}</FormError>
                            </FormItem>
                            {!isEdit && (
                                <FormItem>
                                    <FormInputChoice
                                        id="modal.sendEmail"
                                        name="modal.sendEmail"
                                        form={form}
                                        type="checkbox"
                                        className="formCheckbox"
                                        label={translate('modal.resource.form-label-sendEmail')}
                                        fieldOptions={{
                                            initialValue: false,
                                        }}
                                    />
                                    <FormError>{form.getFieldError('modal.sendEmail')}</FormError>
                                </FormItem>
                            )}
                            <ModalActions>
                                {isEdit && (
                                    <ModalActionsItem>
                                        <ModalContainer
                                            render={({ handleClickOpen }) => (
                                                <Button
                                                    onClick={handleClickOpen}
                                                    htmlAttrs={{
                                                        'data-item-id': 'resourceList',
                                                        'data-item-data': `${JSON.stringify({
                                                            group: group,
                                                            items: group.resources,
                                                        })}`,
                                                    }}
                                                >
                                                    <Translation value="modal.action-cancel" />
                                                </Button>
                                            )}
                                        />
                                    </ModalActionsItem>
                                )}
                                <ModalActionsItem>
                                    <Button
                                        className={classNames({ 'is-loading': isLoading })}
                                        hasWrapper
                                        option={['blue', 'small', 'block', 'spinner']}
                                        type="submit"
                                    >
                                        <Translation value="modal.action-save" />
                                    </Button>
                                </ModalActionsItem>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({ currentProject }) => ({
    project: currentProject.data,
})

const mapDispatchToProps = dispatch => ({
    formActions: {
        update: (projectId, resourceId, formValues) => {
            return updateResource(projectId, resourceId, formValues)
        },
        create: (projectId, formValues) => {
            return createResource(projectId, formValues)
        },
    },
})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(ModalResourceDetails))
