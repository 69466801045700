import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchClients } from '../../actions/clientActions'

import { LoadingError } from '../LoadingError'

const ClientContainer = ({ dispatch, render, isLoading, entities, newClient, wasQueried, errors, data }) => {
    useEffect(() => {
        if (!wasQueried) {
            dispatch(fetchClients())
        }
    }, [dispatch, wasQueried])

    if (isLoading) {
        return null
    } else if (errors !== null) {
        console.warn(errors)
        return <LoadingError />
    } else {
        return render({ clients: entities, newClient })
    }
}

const mapStateToProps = ({ clients }) => ({
    ...clients,
})

export default connect(mapStateToProps)(ClientContainer)
