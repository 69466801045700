import classNames from 'classnames'
import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, ButtonCircle } from '../Button'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { Translation } from '../Translation'
import { Validator } from '../Validator'

import { updateResource } from '../../actions/resourceActions'
import { useReorderItems, useRemoveItem } from '../../utils'

const ModalResourceList = ({ data: { group, items }, handleClickClose, handleModalSubmit, project, update }) => {
    const { projectId } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [resources, setResources] = useState(items)

    useEffect(() => {
        setResources(items)
        return () => {
            setResources([])
        }
    }, [items])

    const close = useCallback(
        event => {
            setResources([])
            handleClickClose()
        },
        [handleClickClose, setResources]
    )

    const reorder = useReorderItems(resources, setResources)
    const remove = useRemoveItem(resources, setResources)

    const handleSubmit = useCallback(
        event => {
            event.preventDefault()

            if (isLoading) {
                return false
            }

            setIsLoading(true)

            Promise.all(
                resources.map(resource => {
                    const formData = {
                        position: resource.position,
                        active: resource.active,
                    }
                    return update(projectId, resource.id, formData)
                        .then(response => response.data)
                        .catch(error => {
                            console.error(error)
                        })
                        .finally(() => {})
                })
            )
                .then(() => handleModalSubmit())
                .then(() => {
                    setIsLoading(false)
                    handleClickClose()
                })
        },
        [handleClickClose, handleModalSubmit, isLoading, projectId, resources, update]
    )

    return (
        <div className="modal-resources">
            <ModalHeading handleClickClose={close}>
                <Translation value="modal.resource.list-title" />
            </ModalHeading>
            <div className="modal_container">
                <ul className="modal-list">
                    {resources
                        .filter(item => item.active)
                        .map((item, index) => (
                            <li
                                className={classNames('modal-list_item', { '-single': resources.length <= 1 })}
                                key={`modaleresources-${item.id}`}
                            >
                                {resources.length > 1 && (
                                    <div className="modal-list_item_controls">
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === 0,
                                            })}
                                        >
                                            <ButtonCircle
                                                option={['small', 'border']}
                                                icon="arrow-up-small"
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'up',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === resources.length - 1,
                                            })}
                                        >
                                            <ButtonCircle
                                                option={['small', 'border']}
                                                icon="arrow-down-small"
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'down',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="modal-list_item_inner">
                                    <div className="modal-list_item_content">
                                        <h3 className="modal-list_item_title">
                                            <Translation value={item.name} />
                                        </h3>
                                        <p className="modal-list_item_text">
                                            <Translation value={item.type.name} />
                                        </p>
                                    </div>
                                    <div className="modal-list_item_actions">
                                        <div className="modal-list_item_action">
                                            <Validator
                                                label="validator.remove-resource"
                                                data={{
                                                    itemId: item.id,
                                                }}
                                            >
                                                <ButtonCircle
                                                    option={['small', 'gray']}
                                                    icon="trash"
                                                    onClick={remove}
                                                />
                                            </Validator>
                                        </div>
                                        <div className="modal-list_item_action">
                                            <ModalContainer
                                                render={({ handleClickOpen }) => (
                                                    <ButtonCircle
                                                        option={['small', 'gray']}
                                                        icon="edit-simple"
                                                        onClick={handleClickOpen}
                                                        htmlAttrs={{
                                                            'data-item-id': 'resourceDetails',
                                                            'data-item-data': `${JSON.stringify({
                                                                resource: item,
                                                                group: group,
                                                            })}`,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
                <ModalActions utility={['padding-small']}>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button onClick={handleClickClose}>
                                    <Translation value="modal.action-cancel" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button
                                    className={classNames({ 'is-loading': isLoading })}
                                    onClick={handleSubmit}
                                    hasWrapper
                                    option={['blue', 'small', 'block', 'spinner']}
                                    htmlAttrs={{
                                        disabled: resources === items,
                                    }}
                                >
                                    <Translation value="modal.action-save" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                </ModalActions>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    update: (projectId, resourceId, formValues) => {
        return updateResource(projectId, resourceId, formValues)
    },
})

export default connect(null, mapDispatchToProps)(ModalResourceList)
