import _ from 'lodash'
import { getActiveLanguage } from 'react-localize-redux'
import isExternal from 'is-url-external'

import routes from '../config/routes.json'
import { getStore } from '../store'
import { translate } from './l10n'

export const getCurrentRoute = path => {
    const lang = getActiveLanguage(getStore().getState().localize).code
    const route = _.find(routes, [`path.${lang}`, path])
    return typeof route !== 'undefined' ? route : null
}

export const normalizeRouteData = data => {
    const defaultData = {
        id: '',
        replacements: {},
        lang: null,
    }

    if (typeof data[0] === 'object') {
        data = { ...defaultData, ...data[0] }
    } else if (typeof data[1] === 'object') {
        data = { ...defaultData, ...{ id: data[0], replacements: data[1], lang: data[2] } }
    } else {
        data = { ...defaultData, ...{ id: data[0] } }
    }
    return data
}

export const normalizePath = path => {
    if (typeof path !== 'string' || path === '') {
        throw new Error('Path is empty.')
    }

    if (isExternal(path)) {
        return path
    }

    return path[0] === '/' ? path : `/${path}`
}

export const localizePath = (path, lang = null) => {
    if (typeof path === 'string') {
        if (lang === null) {
            lang = getActiveLanguage(getStore().getState().localize).code
        }
        return normalizePath(path.replace(':lang', lang))
    } else if (typeof path === 'object') {
        if (lang === null) {
            return normalizePath(translate(path))
        } else if (_.has(path, lang)) {
            return normalizePath(path[lang])
        }
    }

    throw new Error('Invalid path format given for localization.')
}

/**
 * Render a route according to a route identifier and parameter data.
 */
export function renderRoute() {
    const { id, replacements, lang } = normalizeRouteData(arguments)

    const route = _.find(routes, ['id', id])
    if (typeof route === 'undefined') {
        return ''
    }

    if (!_.isEmpty(replacements)) {
        return _.reduce(
            replacements,
            (path, replacement, pattern) => {
                return path.replace(`:${pattern}`, replacement)
            },
            localizePath(route.path, lang)
        )
    } else if (typeof route.url !== 'undefined') {
        return localizePath(route.url, lang)
    } else {
        return localizePath(route.path, lang)
    }
}

/**
 * Render an absolute URL according to a route identifier and parameter data.
 */
export const renderAbsoluteRoute = routeData => {
    const origin = window.location.origin
    const route = renderRoute(routeData)
    return `${origin}${route}`
}
