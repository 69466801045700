import React from 'react'
import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import './_modalActions.scss'

const ModalActions = ({ children, ...classProps }) => {
    return (
        <div className={renderClasses('modalActions', classProps)}>
            <div className="modalActions_item">{children}</div>
        </div>
    )
}

ModalActions.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

export default ModalActions
