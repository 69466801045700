import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import './_panel.scss'

const Panel = ({ children, ...classProps }) => <div className={renderClasses('panel', classProps)}>{children}</div>

Panel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

export default Panel
