import classNames from 'classnames'
import Immutable from 'immutable'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { REFRESH_PROJECT_REQUEST } from '../../actions/projectActions'
import { createResourceGroup, updateResourceGroup } from '../../actions/resourceGroupActions'
import { formatFormNotifications, translate as utilTranslate } from '../../utils'
import { Button, ButtonCircle } from '../Button'
import { FormContainer, FormError, FormInput, FormItem, FormLabel, FormNotifications } from '../Form'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { Translation } from '../Translation'

const ModalResourceGroupDetails = ({ data: resourceGroup, dispatch, handleClickClose, project, translate }) => {
    const isEdit = !_.isEmpty(resourceGroup) ? true : false
    const [initialValues, setInitialValues] = useState(Immutable.Map({}))

    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(resourceGroup)) {
            setInitialValues(Immutable.Map(resourceGroup))
        }
        return () => {
            setInitialValues(Immutable.Map({}))
        }
    }, [resourceGroup])

    const handleSubmit = ({ event, form: { resetFields, validateFields } }) => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                const action = isEdit
                    ? () => {
                          return updateResourceGroup(project.id, resourceGroup.id, formValues.modal)
                      }
                    : () => {
                          return createResourceGroup(project.id, formValues.modal)
                      }

                setIsLoading(true)
                action()
                    .then(() => {
                        dispatch({
                            type: REFRESH_PROJECT_REQUEST,
                        })
                        setIsLoading(false)
                        handleClickClose()
                        resetFields()
                    })
                    .catch(error => {
                        setErrors(error.response.data)
                        setIsLoading(false)
                    })
            }
        })
        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-resources-form">
            {isEdit ? (
                <header className="modal-heading u-padding-small">
                    <ModalContainer
                        render={({ handleClickOpen }) => (
                            <ButtonCircle
                                icon="close"
                                option={['small', 'gray']}
                                onClick={handleClickOpen}
                                htmlAttrs={{
                                    'data-item-id': 'resourceGroupList',
                                    'data-item-data': `${JSON.stringify(project.resources)}`,
                                }}
                            />
                        )}
                    />
                    <span className="u-margin-small-x">
                        <Translation value="modal.resource-group.form-title-edit" />
                    </span>
                </header>
            ) : (
                <ModalHeading handleClickClose={handleClickClose}>
                    <Translation value="modal.resource-group.form-title-add" />
                </ModalHeading>
            )}
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormLabel inputId="modal.name" isRequired>
                                    <Translation value="modal.resource-group.form-label-name" />
                                </FormLabel>
                                <FormInput
                                    id="modal.name"
                                    name="modal.name"
                                    form={form}
                                    fieldOptions={{
                                        initialValue:
                                            initialValues.get('name') instanceof Object
                                                ? utilTranslate(initialValues.get('name'))
                                                : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.name')}</FormError>
                            </FormItem>
                            <ModalActions>
                                {isEdit && (
                                    <ModalActionsItem>
                                        <ModalContainer
                                            render={({ handleClickOpen }) => (
                                                <React.Fragment>
                                                    <Button
                                                        onClick={handleClickOpen}
                                                        htmlAttrs={{
                                                            'data-item-id': 'resourceGroupList',
                                                            'data-item-data': `${JSON.stringify(project.resources)}`,
                                                        }}
                                                    >
                                                        <Translation value="modal.action-cancel" />
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        />
                                    </ModalActionsItem>
                                )}
                                <ModalActionsItem>
                                    <Button
                                        className={classNames({ 'is-loading': isLoading })}
                                        hasWrapper
                                        option={['blue', 'small', 'block', 'spinner']}
                                        type="submit"
                                    >
                                        <Translation value="modal.action-save" />
                                    </Button>
                                </ModalActionsItem>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </div>
    )
}

const mapStateToProps = ({ currentProject }) => ({
    project: currentProject.data,
})

export default withLocalize(connect(mapStateToProps)(ModalResourceGroupDetails))
