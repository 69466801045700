import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'
import _ from 'lodash'

import { ModalActions, ModalHeading } from '.'
import { Button } from '../Button'
import { FormContainer, FormError, FormInput, FormInputFile, FormItem, FormLabel, FormNotifications } from '../Form'
import { Translation } from '../Translation'

import { updateProject } from '../../actions/projectActions'
import { formatFormNotifications } from '../../utils'

const ModalProjectPlanningDetails = ({ handleClickClose, data: project, dispatch }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [timelineFile, setTimelineFile] = useState(null)

    const formSelectedFileResource = useCallback(resourceId => {
        setTimelineFile(resourceId)
    }, [])

    const handleSubmit = useCallback(
        ({ event, form: { validateFields } }) => {
            event.preventDefault()

            if (isLoading) {
                return false
            }

            validateFields(async (formErrors, formValues) => {
                if (timelineFile !== null) {
                    formValues.projectForm.timeline = timelineFile
                }

                if (!formErrors) {
                    setIsLoading(true)

                    dispatch(updateProject(project.id, formValues.projectForm))
                        .then(response => {
                            setIsLoading(false)
                            handleClickClose()
                            return response.data
                        })
                        .catch(error => {
                            setErrors(error.response.data)
                            setIsLoading(false)
                        })
                }
            })
        },
        [dispatch, handleClickClose, isLoading, project, timelineFile]
    )

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-planning-edit">
            <ModalHeading handleClickClose={handleClickClose}>
                <Translation value="modal.projectTimeline.title" />
            </ModalHeading>
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form, form: { getFieldError } }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormLabel isDecorative>
                                    <Translation value="projectForm.planning" />
                                </FormLabel>
                                <FormInputFile
                                    id="projectForm.timeline"
                                    name="projectForm.timeline"
                                    form={form}
                                    label="projectForm.planningInfo"
                                    selectedFileResource={serverId => formSelectedFileResource(serverId)}
                                    currentFileUrl={
                                        project.timeline instanceof Object ? project.timeline.downloadUrl : ''
                                    }
                                />
                                <FormError>{getFieldError('projectForm.timeline')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="projectForm.timelineDate">
                                    <Translation value="projectForm.date-update" />
                                </FormLabel>
                                <FormInput
                                    id="projectForm.timelineDate"
                                    name="projectForm.timelineDate"
                                    form={form}
                                    type="date"
                                    fieldOptions={{
                                        initialValue:
                                            project.timeline instanceof Object && project.timeline.revisionDate !== null
                                                ? moment(project.timeline.timelineDate).format('YYYY-MM-DD')
                                                : '',
                                    }}
                                />
                                <FormError>{getFieldError('projectForm.timelineDate')}</FormError>
                            </FormItem>
                            <ModalActions>
                                <Button
                                    className={classNames({ 'is-loading': isLoading })}
                                    hasWrapper
                                    option={['blue', 'small', 'block', 'spinner']}
                                    type="submit"
                                >
                                    <Translation value="modal.action-save" />
                                </Button>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </div>
    )
}

export default connect()(ModalProjectPlanningDetails)
