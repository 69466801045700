import classNames from 'classnames'
import Immutable from 'immutable'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import {
    clearMilestoneCache,
    createMilestone,
    REFRESH_MILESTONE_REQUEST,
    updateMilestone,
} from '../../actions/milestoneActions'
import { REFRESH_PROJECT_REQUEST } from '../../actions/projectActions'
import { formatFormNotifications, renderRoute, translate as utilTranslate } from '../../utils'
import { Button, ButtonCircle } from '../Button'
import { FormContainer, FormError, FormInput, FormInputChoice, FormItem, FormLabel, FormNotifications } from '../Form'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { Translation } from '../Translation'

const ModalMilestoneDetails = ({ data: milestone = null, dispatch, handleClickClose, project, translate }) => {
    const isEdit = !_.isEmpty(milestone) ? true : false
    const projectHasNoMilestones = _.isEmpty(project.milestones) ? true : false
    const [initialValues, setInitialValues] = useState(Immutable.Map({}))

    const [redirectToMilestone, setRedirectToMilestone] = useState(null)
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [sendEmail, setSendEmail] = useState(true)

    const lastPosition = !projectHasNoMilestones ? project.milestones[project.milestones.length - 1].position : 0

    useEffect(() => {
        if (!_.isEmpty(milestone)) {
            setInitialValues(Immutable.Map(milestone))
            setSendEmail(milestone.sendEmail)
        }
        return () => {
            setInitialValues(Immutable.Map({}))
            setSendEmail(false)
        }
    }, [milestone])

    const onCheckboxChange = event => {
        const value = event.target.checked
        setSendEmail(value)
        return value
    }

    const handleSubmit = ({ event, form: { resetFields, setFieldsValue, validateFields } }) => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        setFieldsValue({ 'modal.sendEmail': sendEmail })
        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                if (!isEdit) {
                    formValues.modal.position = lastPosition
                }
                const action = isEdit
                    ? () => {
                          return updateMilestone(project.id, milestone.id, formValues.modal)
                      }
                    : () => {
                          return createMilestone(project.id, formValues.modal)
                      }

                setIsLoading(true)
                action()
                    .then(response => {
                        dispatch({
                            type: REFRESH_PROJECT_REQUEST,
                        })
                        if (projectHasNoMilestones) {
                            setRedirectToMilestone(response.data.id)
                        } else {
                            clearMilestoneCache(project.id, milestone.id)
                            dispatch({
                                type: REFRESH_MILESTONE_REQUEST,
                            })
                            setIsLoading(false)
                            resetFields()
                        }
                        handleClickClose()
                    })
                    .catch(error => {
                        setErrors(error.response.data)
                        setIsLoading(false)
                    })
            }
        })
        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    if (!_.isEmpty(redirectToMilestone)) {
        return (
            <Redirect
                to={renderRoute({
                    id: 'milestone-details',
                    replacements: {
                        projectId: project.id,
                        milestoneId: redirectToMilestone,
                    },
                })}
            />
        )
    }

    return (
        <>
            {isEdit ? (
                <header className="modal-heading u-padding-small">
                    <ModalContainer
                        render={({ handleClickOpen }) => (
                            <ButtonCircle
                                icon="close"
                                option={['small', 'gray']}
                                onClick={handleClickOpen}
                                htmlAttrs={{
                                    'data-item-id': 'milestoneList',
                                    'data-item-data': `${JSON.stringify(project.milestones)}`,
                                }}
                            />
                        )}
                    />
                    <span className="u-margin-small-x">
                        <Translation value="modal.milestone.form-title-edit" />
                    </span>
                </header>
            ) : (
                <ModalHeading handleClickClose={handleClickClose}>
                    <Translation value="modal.milestone.form-title-add" />
                </ModalHeading>
            )}
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form, form: { getFieldError } }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormLabel inputId="modal.name" isRequired>
                                    <Translation value="modal.milestone.form-label-name" />
                                </FormLabel>
                                <FormInput
                                    id="modal.name"
                                    name="modal.name"
                                    form={form}
                                    fieldOptions={{
                                        initialValue:
                                            initialValues.get('name') instanceof Object
                                                ? utilTranslate(initialValues.get('name'))
                                                : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{getFieldError('modal.name')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormInputChoice
                                    id="modal.sendEmail"
                                    name="modal.sendEmail"
                                    form={form}
                                    type="checkbox"
                                    className="formCheckbox"
                                    label={translate('modal.milestone.form-label-sendEmail')}
                                    checked={sendEmail === true}
                                    fieldOptions={{
                                        getValueFromEvent: onCheckboxChange,
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.sendEmail')}</FormError>
                            </FormItem>
                            <ModalActions>
                                {isEdit && (
                                    <ModalActionsItem>
                                        <ModalContainer
                                            render={({ handleClickOpen }) => (
                                                <Button
                                                    onClick={handleClickOpen}
                                                    htmlAttrs={{
                                                        'data-item-id': 'milestoneList',
                                                        'data-item-data': `${JSON.stringify(project.milestones)}`,
                                                    }}
                                                >
                                                    <Translation value="modal.action-cancel" />
                                                </Button>
                                            )}
                                        />
                                    </ModalActionsItem>
                                )}
                                <ModalActionsItem>
                                    <Button
                                        className={classNames({ 'is-loading': isLoading })}
                                        hasWrapper
                                        option={['blue', 'small', 'block', 'spinner']}
                                        type="submit"
                                    >
                                        <Translation value="modal.action-save" />
                                    </Button>
                                </ModalActionsItem>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </>
    )
}

const mapStateToProps = ({ currentProject }) => ({
    project: currentProject.data,
})

export default withLocalize(connect(mapStateToProps)(ModalMilestoneDetails))
