import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { renderClasses, renderRoute } from '../../utils'
import { LinkIcon, LinkLabel } from '../Link'

import './_link.scss'

const Link = ({
    children,
    href = null,
    hash,
    icon = null,
    isBlank = false,
    route = null,
    withLabel = true,
    ...classProps
}) => {
    const classes = renderClasses('link', classProps)
    const blankAtts = isBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {}
    const LabelWrapper = ({ children, display = true }) =>
        display === true ? <LinkLabel>{children}</LinkLabel> : children

    return route !== null ? (
        <RouterLink
            className={classes}
            to={{
                pathname: renderRoute(route),
                hash: hash,
            }}
        >
            <LinkIcon name={icon} />
            <LabelWrapper display={withLabel}>{children}</LabelWrapper>
        </RouterLink>
    ) : (
        <a href={href} className={classes} {...blankAtts}>
            <LinkIcon name={icon} />
            <LabelWrapper display={withLabel}>{children}</LabelWrapper>
        </a>
    )
}

Link.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    href: PropTypes.string,
    icon: PropTypes.string,
    isBlank: PropTypes.bool,
    route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    withLabel: PropTypes.bool,
}

export default Link
