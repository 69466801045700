import React from 'react'
import { Lisa } from '../Lisa'

import greetingEn from '../../assets/audio/greeting.en.mp3'
import greetingFr from '../../assets/audio/greeting.fr.mp3'
import tourProposalEn from '../../assets/audio/tourProposal.en.mp3'
import tourProposalFr from '../../assets/audio/tourProposal.fr.mp3'

const steps = [
    {
        title: {
            en: 'Welcome to Station, your project companion at Locomotive',
            fr: 'Bienvenue sur Station, votre assistant de projet chez Locomotive',
        },
        audio: {
            en: greetingEn,
            fr: greetingFr,
        },
    },
    {
        title: {
            en: 'Would you like a guided tour of the app to help you get around?',
            fr: "Désirez-vous un tour guidé de l'interface pour débuter?",
        },
        audio: {
            en: tourProposalEn,
            fr: tourProposalFr,
        },
        choices: [
            {
                label: {
                    en: 'Start Tutorial',
                    fr: 'Débuter le tutoriel',
                },
                action: 'startTutorial',
            },
            {
                label: {
                    en: 'Skip',
                    fr: 'Sauter',
                },
                action: 'skipTutorial',
            },
        ],
    },
]

const Introduction = () => <Lisa steps={steps} />

export default Introduction
