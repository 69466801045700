import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { createClient } from '../../actions/clientActions'
import { formatFormNotifications } from '../../utils/forms'

import { Button } from '../Button'
import { FormContainer, FormError, FormInput, FormInputChoice, FormItem, FormLabel, FormNotifications } from '../Form'
import { ModalActions, ModalActionsItem, ModalHeading } from '../Modal'
import { Translation } from '../Translation'

const LOCALES = [
    {
        id: 'en',
        label: 'English',
    },
    {
        id: 'fr',
        label: 'Français',
    },
]

const ModalClientDetails = ({ data: { companyId }, handleClickClose, dispatch, translate }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [userLocale, setUserLocale] = useState(null)

    const handleOnChangeLocaleCheckbox = event => {
        const value = event.target.id.replace('locale_', '')
        setUserLocale(value)
        return value
    }

    const handleSubmit = ({ event, form: { resetFields, setFieldsValue, validateFields } }) => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        setFieldsValue({
            'formCreateClient.preferences.locale': userLocale,
            'formCreateClient.companyIds': [companyId],
        })
        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                setIsLoading(true)

                dispatch(createClient(formValues.formCreateClient))
                    .then(response => {
                        setIsLoading(false)
                        handleClickClose()
                        resetFields()
                    })
                    .catch(error => {
                        setErrors(error.response.data)
                        setIsLoading(false)
                    })
            }
        })
        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <>
            <ModalHeading handleClickClose={handleClickClose}>
                <Translation value="modal.client.form-title-add" />
            </ModalHeading>
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            {/* Hidden input that saves data to form. */}
                            <FormInput
                                id="formCreateClient.companyIds"
                                name="formCreateClient.companyIds"
                                form={form}
                                type="hidden"
                                fieldOptions={{
                                    initialValue: [companyId],
                                }}
                            />
                            <FormItem>
                                <FormLabel inputId="formCreateClient.fullName" isRequired>
                                    <Translation value="modal.client.form-label-fullName" />
                                </FormLabel>
                                <FormInput
                                    id="formCreateClient.fullName"
                                    name="formCreateClient.fullName"
                                    form={form}
                                    fieldOptions={{
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('formCreateClient.fullName')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="formCreateClient.shortName">
                                    <Translation value="modal.client.form-label-shortName" />
                                </FormLabel>
                                <FormInput
                                    id="formCreateClient.shortName"
                                    name="formCreateClient.shortName"
                                    form={form}
                                    fieldOptions={{
                                        initialValue: '',
                                    }}
                                />
                                <FormError>{form.getFieldError('formCreateClient.shortName')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="formCreateClient.email" isRequired>
                                    <Translation value="modal.client.form-label-email" />
                                </FormLabel>
                                <FormInput
                                    id="formCreateClient.email"
                                    name="formCreateClient.email"
                                    form={form}
                                    fieldOptions={{
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('formCreateClient.email')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="formCreateClient.phone">
                                    <Translation value="modal.client.form-label-phone" />
                                </FormLabel>
                                <FormInput
                                    id="formCreateClient.phone"
                                    name="formCreateClient.phone"
                                    form={form}
                                    fieldOptions={{
                                        initialValue: '',
                                    }}
                                />
                                <FormError>{form.getFieldError('formCreateClient.phone')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel isDecorative>
                                    <Translation value="form.locale" />
                                </FormLabel>
                                {LOCALES.map(locale => (
                                    <FormInputChoice
                                        key={locale.id}
                                        id={`locale_${locale.id}`}
                                        name="formCreateClient.preferences.locale"
                                        form={form}
                                        label={locale.label}
                                        checked={userLocale === locale.id}
                                        fieldOptions={{
                                            getValueFromEvent: handleOnChangeLocaleCheckbox,
                                        }}
                                    />
                                ))}
                            </FormItem>
                            <ModalActions>
                                <ModalActionsItem>
                                    <Button
                                        className={classNames({ 'is-loading': isLoading })}
                                        hasWrapper
                                        option={['blue', 'small', 'block', 'spinner']}
                                        type="submit"
                                    >
                                        <Translation value="modal.action-save" />
                                    </Button>
                                </ModalActionsItem>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </>
    )
}

ModalClientDetails.propTypes = {
    modalId: PropTypes.string,
    data: PropTypes.shape({
        companyId: PropTypes.string,
    }),
    handleClickClose: PropTypes.func.isRequired,
}

export default withLocalize(connect()(ModalClientDetails))
