import React from 'react'

import './_loadingError.scss'

import { Translation } from '../Translation'

const LoadingError = () => (
    <div className="loadingError">
        <Translation value="common.loadingError" />
    </div>
)

export default LoadingError
