import classNames from 'classnames'
import React from 'react'

import { Translation } from '../Translation'

import './_timelineCheckbox.scss'

const TimelineCheckbox = ({ handleOnChange, labels, task, taskProperty }) => {
    const isChecked = task[taskProperty]
    const id = task.id

    return (
        <div className={classNames('timelineCheckbox_wrap', { 'is-checked': isChecked })}>
            <input
                id={id}
                type="checkbox"
                checked={isChecked}
                name={taskProperty}
                className="timelineCheckbox"
                onChange={handleOnChange}
            />
            <label htmlFor={id} className="timelineCheckbox_label">
                <Translation value={isChecked ? labels.checked : labels.unchecked} />
            </label>
        </div>
    )
}

export default TimelineCheckbox
