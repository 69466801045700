import React from 'react'

import PropTypes from 'prop-types'

import './_link.scss'

const LinkLabel = ({ children }) => <span className="link_label">{children}</span>

LinkLabel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

export default LinkLabel
