import React from 'react'

import { renderClasses } from '../../utils'

import './_cardMain.scss'

const CardMain = ({ children, ...classProps }) => (
    <div className={renderClasses('cardMain', classProps)}>{children}</div>
)

export default CardMain
