import { useEffect, useReducer } from 'react'
import _ from 'lodash'

export const FETCH_REQUEST = 'FETCH_REQUEST'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAILURE = 'FETCH_FAILURE'
export const UPDATE_REQUEST = 'UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
export const UPDATE_FAILURE = 'UPDATE_FAILURE'
export const NO_FETCH = 'NO_FETCH'

export const itemDetailsInitialState = {
    error: null,
    id: null,
    isLoading: true,
    item: {},
}

export const itemListInitialState = {
    error: null,
    isLoading: true,
    items: [],
}

export const itemDetailsReducer = (state, action) => {
    switch (action.type) {
        case FETCH_REQUEST:
            return {
                ...itemDetailsInitialState,
                isLoading: true,
            }
        case UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case FETCH_SUCCESS:
        case UPDATE_SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                item: action.payload,
                isLoading: false,
            }
        case FETCH_FAILURE:
        case UPDATE_FAILURE:
            return {
                ...state,
                error: action.payload.data,
                isLoading: false,
            }
        case NO_FETCH:
            return {
                ...itemDetailsInitialState,
                isLoading: false,
            }
        default:
            throw new Error('Invalid action type.')
    }
}

const itemListReducer = (state, action) => {
    switch (action.type) {
        case FETCH_REQUEST:
            return {
                ...itemListInitialState,
                isLoading: true,
            }
        case FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                items: action.payload,
            }
        case FETCH_FAILURE:
            return {
                ...state,
                error: action.payload.data,
                isLoading: false,
            }
        case NO_FETCH:
            return {
                ...itemListInitialState,
                isLoading: false,
            }
        default:
            throw new Error('Invalid action type.')
    }
}

/**
 * Retrieve item list data based on an action.
 *
 * @param  {Function} fetchAction
 * @return {Object}
 */
export const useFetchItemList = fetchAction => {
    return useFetchDataWithState(fetchAction, itemListReducer, itemListInitialState)
}

/**
 * Retrieve item list data based on an action.
 *
 * @param  {Function} fetchAction
 * @return {Object}
 */
export const useFetchItemDetails = fetchAction => {
    return useFetchDataWithState(fetchAction, itemDetailsReducer, itemDetailsInitialState)
}

/**
 * Retrieve data based on action using a reducer
 *
 * @param  {Function} fetchAction
 * @param  {Boolean} isMultiple
 * @return {Object}
 */
export function useFetchDataWithState(fetchAction, reducer, initialState) {
    const [state, dispatch] = useReducer(reducer, initialState)

    return useFetchData(fetchAction, state, dispatch)
}

export function useFetchData(fetchAction, state, dispatch, timestamp = null) {
    useEffect(() => {
        let didCancel = false
        const fetchData = async () => {
            dispatch({ type: FETCH_REQUEST })
            try {
                const result = await fetchAction()

                // for functions that are not noop
                // used to dispatch a specific type
                // mostly used for NO_FETCH type
                if (_.isFunction(result) && !_.isUndefined(result())) {
                    dispatch(result())
                }

                // check on result.data for noop function
                // avoiding to dispatch empty success on reducer
                if (!didCancel && !_.isNil(result.data)) {
                    dispatch({ type: FETCH_SUCCESS, payload: result.data })
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: FETCH_FAILURE, payload: error.response })
                }
            }
        }

        fetchData()

        return () => {
            didCancel = true
        }
    }, [fetchAction, dispatch, timestamp])

    return state
}
