import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { clearProjectCache, fetchProject } from '../../actions/projectActions'
import { NotFoundView } from '../../views'

const ProjectContainer = ({ dispatch, errors, isLoading, project, projectId, render, shouldRefresh, wasQueried }) => {
    useEffect(() => {
        if (shouldRefresh) {
            // Clear axios cache in order to properly refresh the current project
            clearProjectCache(project.id)
        }
        const wasNotQueried = !wasQueried
        if (wasNotQueried || (projectId !== project.id && errors === null) || shouldRefresh) {
            dispatch(fetchProject(projectId))
        }
    }, [dispatch, errors, project, projectId, shouldRefresh, wasQueried])

    if (isLoading && !shouldRefresh) {
        return null
    } else if (errors !== null) {
        console.warn(errors)
        return <NotFoundView />
    } else {
        return render({ project })
    }
}

const mapStateToProps = state => ({
    errors: state.currentProject.errors,
    isLoading: state.currentProject.isLoading,
    project: state.currentProject.data,
    wasQueried: state.currentProject.wasQueried,
    shouldRefresh: state.currentProject.shouldRefresh,
})

export default connect(mapStateToProps)(ProjectContainer)
