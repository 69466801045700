import React, { useCallback, useState } from 'react'
import classNames from 'classnames'

import { Layout, LayoutItem } from '../Layout'
import { ButtonLink } from '../Button'
import { Translation } from '../Translation'

import './_validator.scss'

const Validator = ({ label, children, data = {} }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [isValidate, setIsValidate] = useState(false)

    const abort = useCallback(
        event => {
            setIsOpen(false)
        },
        [setIsOpen]
    )

    const validate = useCallback(
        event => {
            setIsOpen(false)
            setIsValidate(true)
            children.props.onClick(event, data)
        },
        [children, data, setIsOpen, setIsValidate]
    )

    const open = useCallback(
        event => {
            setIsOpen(true)
        },
        [setIsOpen]
    )

    return (
        <React.Fragment>
            {!isValidate && (
                <div className="button-wrap" onClick={open} type="button">
                    <span className="validator_button_inner">{children}</span>
                </div>
            )}
            {isValidate && <React.Fragment>{children}</React.Fragment>}
            <div className={classNames('validator', { 'is-open': isOpen })}>
                <Layout option={['middle']}>
                    <LayoutItem utility={['1/2']}>
                        <h3>
                            <Translation value={label} />
                        </h3>
                    </LayoutItem>
                    <LayoutItem utility={['1/2', 'text-right']}>
                        <div className="validator_actions_item">
                            <ButtonLink onClick={abort} option={['red', 'small']}>
                                <Translation value="validator.abort" />
                            </ButtonLink>
                        </div>
                        <div className="validator_actions_item">
                            <ButtonLink onClick={validate} option={['blue', 'small']}>
                                <Translation value="validator.validate" />
                            </ButtonLink>
                        </div>
                    </LayoutItem>
                </Layout>
            </div>
        </React.Fragment>
    )
}

export default Validator
