import classNames from 'classnames'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { REFRESH_PROJECT_REQUEST } from '../../actions/projectActions'
import { updateResourceGroup } from '../../actions/resourceGroupActions'
import { formatFormNotifications, useRemoveItem, useReorderItems } from '../../utils'
import { Button, ButtonCircle } from '../Button'
import { FormNotifications } from '../Form'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { Translation } from '../Translation'
import { Validator } from '../Validator'

const ModalResourceGroupList = ({ data: resourceGroupList, dispatch, handleClickClose }) => {
    const { projectId } = useParams()
    const [resourceGroups, setResourceGroups] = useState(resourceGroupList)

    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setResourceGroups(resourceGroupList)
        return () => {
            setResourceGroups([])
        }
    }, [resourceGroupList])

    const close = event => {
        setResourceGroups([])
        handleClickClose()
    }

    const reorder = useReorderItems(resourceGroups, setResourceGroups)
    const remove = useRemoveItem(resourceGroups, setResourceGroups)

    const handleSubmit = event => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        setIsLoading(true)

        Promise.all(
            resourceGroups.map(resourceGroup => {
                const formData = {
                    position: resourceGroup.position,
                    active: resourceGroup.active,
                }
                return updateResourceGroup(projectId, resourceGroup.id, formData)
                    .then(response => response.data)
                    .catch(error => {
                        throw new Error(error.response.data)
                    })
                    .finally(() => {})
            })
        )
            .then(() => {
                dispatch({
                    type: REFRESH_PROJECT_REQUEST,
                })
                setIsLoading(false)
                handleClickClose()
            })
            .catch(error => {
                setErrors(error)
                setIsLoading(false)
            })
        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-resources">
            <ModalHeading handleClickClose={close}>
                <Translation value="modal.resource-group.list-title" />
            </ModalHeading>
            <div className="modal_container">
                {hasNotifications && <FormNotifications hasErrors={hasErrors} items={notifications} />}
                <ul className="modal-list">
                    {resourceGroups
                        .filter(group => group.active)
                        .map((item, index) => (
                            <li
                                className={classNames('modal-list_item', { '-single': resourceGroups.length <= 1 })}
                                key={`modaleresources-${item.id}`}
                            >
                                {resourceGroups.length > 1 && (
                                    <div className="modal-list_item_controls">
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === 0,
                                            })}
                                        >
                                            <ButtonCircle
                                                icon="arrow-up-small"
                                                option={['small', 'border']}
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'up',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === resourceGroups.length - 1,
                                            })}
                                        >
                                            <ButtonCircle
                                                icon="arrow-down-small"
                                                option={['small', 'border']}
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'down',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="modal-list_item_inner">
                                    <div className="modal-list_item_content">
                                        <h3 className="modal-list_item_title">
                                            <Translation value={item.name} />
                                        </h3>
                                        <p className="modal-list_item_text">
                                            {item.resources.length}{' '}
                                            <Translation
                                                value={
                                                    item.resources.length === 1 ? 'common.resource' : 'common.resources'
                                                }
                                            />
                                        </p>
                                    </div>
                                    <div className="modal-list_item_actions">
                                        <div className="modal-list_item_action">
                                            <Validator
                                                label="validator.remove-resource"
                                                data={{
                                                    itemId: item.id,
                                                }}
                                            >
                                                <ButtonCircle
                                                    onClick={remove}
                                                    option={['small', 'gray']}
                                                    icon="trash"
                                                />
                                            </Validator>
                                        </div>
                                        <div className="modal-list_item_action">
                                            <ModalContainer
                                                render={({ handleClickOpen }) => (
                                                    <ButtonCircle
                                                        option={['small', 'gray']}
                                                        icon="edit-simple"
                                                        onClick={handleClickOpen}
                                                        htmlAttrs={{
                                                            'data-item-id': 'resourceGroupDetails',
                                                            'data-item-data': `${JSON.stringify(item)}`,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
                <ModalActions utility={['padding-small']}>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button onClick={handleClickClose}>
                                    <Translation value="modal.action-cancel" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button
                                    className={classNames({ 'is-loading': isLoading })}
                                    onClick={handleSubmit}
                                    hasWrapper
                                    option={['blue', 'small', 'block', 'spinner']}
                                    htmlAttrs={{
                                        disabled: resourceGroups === resourceGroupList,
                                    }}
                                >
                                    <Translation value="modal.action-save" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                </ModalActions>
            </div>
        </div>
    )
}

export default connect()(ModalResourceGroupList)
