import axios from 'axios'
import classNames from 'classnames'
import _ from 'lodash'
import React, { useRef } from 'react'
import { connect } from 'react-redux'

import { updateSelf } from '../../actions/userActions'
import { SET_DISMISSED_POINTERS, NEXT_TUTORIAL_STEP, DISABLE_TUTORIAL } from '../../actions/viewActions'
import { Layout, LayoutItem } from '../Layout'
import { Translation } from '../Translation'

import './_pointer.scss'

const Pointer = ({
    ident,
    tutorial,
    position,
    align,
    mobilePosition,
    mobileAlign,
    scrollTo,
    dismissedPointers,
    dispatch,
}) => {
    const signal = axios.CancelToken.source()
    const pointerRef = useRef(null)

    const next = () => {
        const currentStep = tutorial.currentStep
        if (currentStep + 1 < tutorial.steps.length) {
            dispatch({
                type: NEXT_TUTORIAL_STEP,
            })
        } else {
            end()
        }
    }

    const end = () => {
        const pointers = _.uniq(_.compact([...dismissedPointers, 'introduction']))
        dispatch(updateSelf({ dismissedPointers: pointers }, { cancelToken: signal.token }))
            .then(data => {
                dispatch({
                    type: SET_DISMISSED_POINTERS,
                    pointers,
                })
            })
            .then(data => {
                dispatch({
                    type: DISABLE_TUTORIAL,
                })
            })
            .catch(error => {
                console.log(`User's dismissed pointers couldn't be updated.`)
            })
    }

    // position can be : top, left, bottom, right
    // align can be : top, left, bottom, right, left, centerX, centerY
    // mobilePosition can be : top, bottom
    // mobileAlign can be : right, left, centerX
    const { currentStep } = tutorial
    const step = tutorial.steps[ident]
    const isActive = ident === currentStep && tutorial.isActive
    const isLastStep = currentStep + 1 === tutorial.steps.length

    if (isActive && scrollTo) {
        window.scrollTo(0, pointerRef.current.getBoundingClientRect().top - 20)
    }

    return (
        <div
            className={classNames(
                'pointer',
                `-${position}`,
                `-align-${align}`,
                `-mobile-${mobilePosition}`,
                `-mobile-align-${mobileAlign}`,
                {
                    'is-active': isActive,
                }
            )}
            ref={pointerRef}
        >
            <div className="pointer_wrap">
                <h3 className="pointer_title">
                    <Translation value={step.title} />
                </h3>
                <p className="pointer_content">
                    <Translation value={step.content} />
                </p>
                <Layout className="pointer_actions" option={['middle']}>
                    <LayoutItem utility={['1/2']}>
                        <button onClick={next} className="pointer_action_button">
                            <Translation value="tutorial.next" />
                        </button>
                    </LayoutItem>
                    {!isLastStep && (
                        <LayoutItem utility={['1/2']}>
                            <div className="u-text-right">
                                <button onClick={end} className="link -gray -classic" type="button">
                                    <Translation value="tutorial.skip" />
                                </button>
                            </div>
                        </LayoutItem>
                    )}
                </Layout>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    tutorial: state.view.tutorial,
    dismissedPointers: state.view.pointers.dismissed,
})

export default connect(mapStateToProps)(Pointer)
