import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { Pointer } from '../Pointer'
import { renderNavItems } from '../../utils/nav'
import { Translation } from '../Translation'

import navConfig from '../../config/nav.json'
import './_nav.scss'

const Nav = ({ pathname, project }) => {
    let items = renderNavItems(pathname, navConfig.main, {
        projectId: project.id,
        milestoneId: project.currentMilestone.id,
    })

    if (!project.currentMilestone.id) {
        items = items.filter(item => item.route !== 'milestone-details')
    } else {
        items = items.filter(item => item.route !== 'milestone-empty')
    }

    return (
        <nav className="nav">
            <ul className="nav_list">
                {items.map((item, index) => (
                    <li className="nav_item" key={index}>
                        <RouterLink
                            className={classNames('nav_link', { 'is-active': item.isActive || item.hasActiveNavItems })}
                            to={item.url}
                        >
                            <span className="nav_link_inner">
                                <Translation value={item.label} />
                            </span>
                        </RouterLink>
                    </li>
                ))}
            </ul>
            <Pointer ident={0} position="right" mobilePosition="bottom" align="top" mobileAlign="right" />
        </nav>
    )
}

Nav.propTypes = {
    pathname: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
}

export default Nav
