import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { userCanDo } from '../../utils'
import { clearMilestoneCache, REFRESH_MILESTONE_REQUEST } from '../../actions/milestoneActions'
import { REFRESH_PROJECT_REQUEST } from '../../actions/projectActions'
import { requestTaskApproval, updateTask } from '../../actions/taskActions'

import { Button } from '../Button'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { TimelineSub, TimelineCheckbox } from '../Timeline'
import { Translation } from '../Translation'

const PARTICIPANTS = ['agency', 'client']
const PRIMARY_PARTICIPANT = PARTICIPANTS[0]

const TimelineTaskItem = ({ dispatch, milestoneIsCompleted = false, task: initialTask, user }) => {
    const { projectId, milestoneId } = useParams()
    const [task, setTask] = useState(initialTask)

    const [emailButtonLabel, setEmailButtonLabel] = useState('milestoneDetails.sendApprovalEmail')
    const [emailButtonClasses, setEmailButtonClasses] = useState(['blue', 'xsmall'])
    const [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState(false)

    const handleChangeCheckbox = event => {
        event.preventDefault()

        const value = event.target.checked
        const property = event.target.name
        const taskId = event.target.id

        updateTask(projectId, taskId, { [property]: value })
            .then(response => {
                setTask(response.data)
                // Make sure to clear milestone cache here so that when we navigate
                // back to this MilestoneDetailsView, the previously queried task list
                // (from MilestoneContainer) is refreshed.
                clearMilestoneCache(projectId, milestoneId)
                if (response.data.milestone.isCompleted) {
                    dispatch({
                        type: REFRESH_MILESTONE_REQUEST,
                    })
                    dispatch({
                        type: REFRESH_PROJECT_REQUEST,
                    })
                }
            })
            .catch(error => {
                console.log(error.response.data)
            })
        return false
    }

    const handleEmailButtonClick = event => {
        event.preventDefault()
        if (!isEmailButtonDisabled) {
            const taskId = event.target.id
            requestTaskApproval(projectId, taskId)
                .then(response => {
                    setEmailButtonLabel('milestoneDetails.approvalEmailSent')
                    setEmailButtonClasses(['green', 'xsmall'])
                    setIsEmailButtonDisabled(true)
                })
                .catch(error => {
                    console.log(error.response.data)
                })
        }
        return false
    }

    const canCompleteTask = userCanDo(user, 'task.complete')
    const canApproveTask = userCanDo(user, 'task.approve')
    const canSendApprovalEmail = userCanDo(user, 'task.sendApprovalEmail')

    const displayApprovalCheckbox =
        task.isApprovalRequired && canApproveTask && !task.isApproved && !milestoneIsCompleted
    const displayEmailApprovalButton =
        task.isApprovalRequired && canSendApprovalEmail && !task.isApproved && !milestoneIsCompleted

    return (
        <div
            className={classNames('timeline_item', {
                'is-done': task.isCompleted,
                'is-approved': task.isApproved,
            })}
        >
            <div className="timeline_item_dot">{task.isCompleted && <Icon name="check" />}</div>
            <div className="timeline_item_content">
                {PARTICIPANTS.map(participantId => {
                    const participant = _.find(task.participants, { id: participantId })
                    const hasBothParticipants = task.participants.length === PARTICIPANTS.length
                    const displayCompletionCheckbox = hasBothParticipants ? participantId === PRIMARY_PARTICIPANT : true

                    return typeof participant !== 'undefined' ? (
                        <TimelineSub key={participantId}>
                            <Heading el="h3" option={['h5', 'medium']} utility={['none@from-small', 'blue']}>
                                <Translation value={participant.name} />
                            </Heading>
                            <Heading el="h3" option={['h5', 'medium']}>
                                <Translation value={task.name} />
                            </Heading>
                            <Translation value={task.description} htmlEl="p" isHtml />
                            {task.isApprovalRequired && task.isApproved && (
                                <p>
                                    <strong>
                                        <Translation value="milestoneDetails.taskApprovedOn" /> {task.approvedAt}
                                    </strong>
                                </p>
                            )}
                            {displayApprovalCheckbox && (
                                <TimelineCheckbox
                                    handleOnChange={handleChangeCheckbox}
                                    task={task}
                                    taskProperty="isApproved"
                                    labels={{
                                        checked: 'milestoneDetails.checkbox.isApprovedChecked',
                                        unchecked: 'milestoneDetails.checkbox.isApprovedUnchecked',
                                    }}
                                />
                            )}
                            {displayCompletionCheckbox && canCompleteTask && !milestoneIsCompleted && (
                                <TimelineCheckbox
                                    handleOnChange={handleChangeCheckbox}
                                    task={task}
                                    taskProperty="isCompleted"
                                    labels={{
                                        checked: 'milestoneDetails.checkbox.isCompletedChecked',
                                        unchecked: 'milestoneDetails.checkbox.isCompletedUnchecked',
                                    }}
                                />
                            )}
                            {displayEmailApprovalButton && (
                                <div>
                                    <Button
                                        htmlAttrs={{
                                            id: task.id,
                                        }}
                                        onClick={handleEmailButtonClick}
                                        option={emailButtonClasses}
                                        utility={['margin-top-tiny']}
                                    >
                                        <Translation value={emailButtonLabel} />
                                    </Button>
                                </div>
                            )}
                        </TimelineSub>
                    ) : (
                        <TimelineSub option={['empty']} key={participantId} />
                    )
                })}
            </div>
        </div>
    )
}

TimelineTaskItem.propTypes = {
    milestoneIsCompleted: PropTypes.bool,
    task: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(TimelineTaskItem)
