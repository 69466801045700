import classNames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useReorderItems, useRemoveItem } from '../../utils'
import { updateMilestone } from '../../actions/milestoneActions'
import { Button, ButtonCircle } from '../Button'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { Translation } from '../Translation'
import { Validator } from '../Validator'

const ModalMilestoneList = ({ data: items, handleClickClose, update, handleModalSubmit }) => {
    const { projectId } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [milestones, setMilestones] = useState([])

    useEffect(() => {
        setMilestones(items)
        return () => {
            setMilestones([])
        }
    }, [items])

    const close = useCallback(
        event => {
            setMilestones([])
            handleClickClose()
        },
        [handleClickClose, setMilestones]
    )

    const reorder = useReorderItems(milestones, setMilestones)
    const remove = useRemoveItem(milestones, setMilestones)

    const handleSubmit = event => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        setIsLoading(true)

        Promise.all(
            milestones.map(milestone => {
                const formData = {
                    position: milestone.position,
                    active: milestone.active,
                }
                return updateMilestone(projectId, milestone.id, formData)
                    .then(response => response.data)
                    .catch(error => {
                        console.error(error)
                    })
                    .finally(() => {})
            })
        )
            .then(() => handleModalSubmit())
            .then(() => {
                setIsLoading(false)
                handleClickClose()
            })
    }

    return (
        <>
            <ModalHeading handleClickClose={close}>
                <Translation value="modal.milestone.list-title" />
            </ModalHeading>
            <div className="modal_container">
                <ul className="modal-list">
                    {milestones
                        .filter(item => item.active)
                        .map((item, index) => (
                            <li
                                className={classNames('modal-list_item', { '-single': milestones.length <= 1 })}
                                key={`modal-milestone-${item.id}`}
                            >
                                {milestones.length > 1 && (
                                    <div className="modal-list_item_controls">
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === 0,
                                            })}
                                        >
                                            <ButtonCircle
                                                option={['small', 'border']}
                                                icon="arrow-up-small"
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'up',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === milestones.length - 1,
                                            })}
                                        >
                                            <ButtonCircle
                                                option={['small', 'border']}
                                                icon="arrow-down-small"
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'down',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="modal-list_item_inner">
                                    <div className="modal-list_item_content">
                                        <h3 className="modal-list_item_title">
                                            <Translation value={item.name} />
                                        </h3>
                                        <p className="modal-list_item_text">
                                            {item.taskCount}{' '}
                                            <Translation value={item.taskCount > 1 ? 'common.tasks' : 'common.task'} />
                                        </p>
                                    </div>
                                    {!item.isCompleted && (
                                        <div className="modal-list_item_actions">
                                            <div className="modal-list_item_action">
                                                <Validator
                                                    label="validator.remove-milestone"
                                                    data={{
                                                        itemId: item.id,
                                                    }}
                                                >
                                                    <ButtonCircle
                                                        onClick={remove}
                                                        option={['small', 'gray']}
                                                        icon="trash"
                                                    />
                                                </Validator>
                                            </div>
                                            <div className="modal-list_item_action">
                                                <ModalContainer
                                                    render={({ handleClickOpen }) => (
                                                        <ButtonCircle
                                                            option={['small', 'gray']}
                                                            icon="edit-simple"
                                                            onClick={handleClickOpen}
                                                            htmlAttrs={{
                                                                'data-item-id': 'milestoneDetails',
                                                                'data-item-data': `${JSON.stringify(item)}`,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </li>
                        ))}
                </ul>
                <ModalActions utility={['padding-small']}>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button onClick={handleClickClose}>
                                    <Translation value="modal.action-cancel" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button
                                    className={classNames({ 'is-loading': isLoading })}
                                    onClick={handleSubmit}
                                    hasWrapper
                                    option={['blue', 'small', 'block', 'spinner']}
                                >
                                    <Translation value="modal.action-update" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                </ModalActions>
            </div>
        </>
    )
}

export default ModalMilestoneList
