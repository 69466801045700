export const baseUrl = 'companies'

/**
 * Retrieve all companies.
 */
export const FETCH_COMPANY_LIST_REQUEST = 'FETCH_COMPANY_LIST_REQUEST'
export const FETCH_COMPANY_LIST_SUCCESS = 'FETCH_COMPANY_LIST_SUCCESS'
export const FETCH_COMPANY_LIST_FAILURE = 'FETCH_COMPANY_LIST_FAILURE'
export const FETCH_COMPANY_LIST_PENDING = 'FETCH_COMPANY_LIST_PENDING'
export function fetchCompanies(config = {}) {
    return {
        promise: client => client.get(baseUrl, config),
        types: [
            FETCH_COMPANY_LIST_REQUEST,
            FETCH_COMPANY_LIST_SUCCESS,
            FETCH_COMPANY_LIST_FAILURE,
            FETCH_COMPANY_LIST_PENDING,
        ],
    }
}

/**
 * Create new company.
 */
export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FAILURE = 'CREATE_COMPANY_FAILURE'
export const CREATE_COMPANY_PENDING = 'CREATE_COMPANY_PENDING'
export function createCompany(formData, config = {}) {
    return {
        promise: client => client.post(baseUrl, formData, config),
        types: [CREATE_COMPANY_REQUEST, CREATE_COMPANY_SUCCESS, CREATE_COMPANY_FAILURE, CREATE_COMPANY_PENDING],
    }
}
