import PropTypes from 'prop-types'
import React from 'react'

import { formatPlansForNav, filterSupportItemsForNav, renderNavItems } from '../../utils'
import { PanelNav } from '../Panel'

import navConfig from '../../config/nav'

const SupportNav = ({ pathname, project }) => (
    <PanelNav
        items={renderNavItems(
            pathname,
            [...filterSupportItemsForNav(navConfig.support, project), ...formatPlansForNav(project.plans)],
            {
                projectId: project.id,
            }
        )}
        option={['delay2']}
        utility={['anim']}
    />
)

SupportNav.propTypes = {
    pathname: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
}

export default SupportNav
