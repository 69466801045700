import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'
import { Heading } from '../Heading'

import './_cardHeading.scss'

const CardHeading = ({ children, el = 'h2', ...classProps }) => (
    <div className={renderClasses('cardHeading', classProps)}>
        <Heading className="cardHeading_title" el={el} option={['noMargin']}>
            {children}
        </Heading>
    </div>
)

CardHeading.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    el: PropTypes.string,
}

export default CardHeading
