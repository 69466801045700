import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import './_tabs.scss'

const TabSelector = ({ className, isActive = false, children, onClick = () => {}, ...classProps }) => (
    <button
        className={classNames('tabSelector', { 'is-active': isActive }, className, renderClasses(classProps))}
        onClick={onClick}
    >
        {children}
    </button>
)

TabSelector.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
}

export default TabSelector
