import React from 'react'

import { renderClasses } from '../../utils'

const LayoutItem = ({ children, el = 'div', ...classProps }) => {
    const El = el
    return <El className={renderClasses('layout_item', classProps)}>{children}</El>
}

export default LayoutItem
