import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { renderClasses } from '../../utils'

import './_tabs.scss'

const TabPanel = ({ children, className, isHidden, render = 'always', ...classProps }) => {
    const [shouldRender, setShouldRender] = useState(render === 'always')

    useEffect(() => {
        if (isHidden) {
            return
        }

        setShouldRender(true)
    }, [isHidden])

    return (
        <div className={classNames('tabPabel', { 'is-hidden': isHidden }, className, renderClasses(classProps))}>
            {shouldRender ? children : null}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
    isHidden: PropTypes.bool,
    render: PropTypes.oneOf(['always', 'lazy']),
}

export default TabPanel
