import _ from 'lodash'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { NotFoundView } from '../views'
import { CardContent } from '../components/CardContent'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { InfoText } from '../components/InfoText'
import { Layout, LayoutItem } from '../components/Layout'
import { Panel, PanelContainer, PanelFooter } from '../components/Panel'
import { ProjectContainer } from '../components/Project'
import { Section, SectionHeader } from '../components/Section'
import { SupportNav } from '../components/SupportNav'
import { Translation } from '../components/Translation'
import { View } from '../components/View'

const PlanDetailsView = () => {
    const { pathname } = useLocation()
    const { planId, projectId } = useParams()
    return (
        <div>
            <ProjectContainer
                projectId={projectId}
                render={({ project }) => {
                    const plan = _.find(project.plans, { id: planId })
                    if (typeof plan === 'undefined') {
                        return <NotFoundView />
                    } else {
                        return (
                            <View>
                                <Layout>
                                    <LayoutItem option={['panelSmall']}>
                                        <Panel option={['small']}>
                                            <PanelContainer>
                                                <Section>
                                                    <div className="panel_header">
                                                        <Heading el="h1" option={['h1', 'delay1']} utility={['anim']}>
                                                            <Translation value="support.header" />
                                                        </Heading>
                                                    </div>
                                                    <SupportNav pathname={pathname} project={project} />
                                                </Section>
                                            </PanelContainer>
                                            <PanelFooter project={project} option={['delay3']} utility={['anim']} />
                                        </Panel>
                                    </LayoutItem>

                                    <LayoutItem option={['contentSmall']}>
                                        <Container>
                                            <Section>
                                                <SectionHeader>
                                                    <Layout>
                                                        <LayoutItem utility={['5/8@from-small']}>
                                                            <Heading
                                                                el="h2"
                                                                option={['h1', 'noMargin', 'delay1']}
                                                                utility={['anim']}
                                                            >
                                                                <Translation value={plan.type.name} />
                                                            </Heading>
                                                            <Heading
                                                                el="h2"
                                                                option={['h1', 'blue', 'delay2']}
                                                                utility={['anim']}
                                                            >
                                                                <Translation value="common.pricing" />
                                                                {` – `}
                                                                <Translation value={plan.price + `$`} />
                                                                {`/`}
                                                                <Translation value={plan.priceType.toLowerCase()} />
                                                            </Heading>
                                                        </LayoutItem>
                                                        {typeof _.get(plan, 'faq.content') !== 'undefined' && (
                                                            <LayoutItem utility={['3/8@from-small']}>
                                                                <InfoText option={['delay1']} utility={['anim']}>
                                                                    <Translation isHtml value={plan.faq.content} />
                                                                </InfoText>
                                                            </LayoutItem>
                                                        )}
                                                    </Layout>
                                                </SectionHeader>
                                                <CardContent content={plan} option={['delay3']} utility={['anim']} />
                                            </Section>
                                        </Container>
                                    </LayoutItem>
                                </Layout>
                            </View>
                        )
                    }
                }}
            />
        </div>
    )
}

export default PlanDetailsView
