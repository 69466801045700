import classNames from 'classnames'
import _ from 'lodash'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { isFirstTaskCurrent, prepareTasks, renderNavItems, userCanDo } from '../utils'

import { Button } from '../components/Button'
import { CardList } from '../components/CardList'
import { CardProgress } from '../components/CardProgress'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { InfoText } from '../components/InfoText'
import { Layout, LayoutItem } from '../components/Layout'
import { Lightbox } from '../components/Lightbox'
import { MilestoneContainer } from '../components/Milestone'
import { ModalContainer } from '../components/Modal'
import { NavActions } from '../components/NavActions'
import { Panel, PanelContainer, PanelFooter, PanelNav } from '../components/Panel'
import { ProjectContainer } from '../components/Project'
import { Section, SectionHeader } from '../components/Section'
import { Signature } from '../components/Signature'
import { Translation } from '../components/Translation'
import { View } from '../components/View'

const formatMilestonesForNav = milestones => {
    let previousMilestoneIsCompleted = true
    let previousMilestoneIsCurrent = false
    return milestones.map(milestone => {
        const isCurrent =
            milestone.isCompleted === false &&
            previousMilestoneIsCurrent === false &&
            previousMilestoneIsCompleted === true
        previousMilestoneIsCompleted = milestone.isCompleted
        previousMilestoneIsCurrent = isCurrent
        return {
            classes: classNames({
                'is-current': isCurrent,
                'is-done': milestone.isCompleted,
                'is-upcoming': isCurrent === false && milestone.isCompleted === false,
            }),
            label: milestone.name,
            route: 'milestone-details',
            replacements: {
                milestoneId: milestone.id,
            },
            isCurrent: isCurrent,
            isCompleted: milestone.isCompleted,
        }
    })
}

const renderStatusText = (milestones, currentMilestone) => {
    if (typeof currentMilestone.tasks === 'undefined') {
        return ' '
    }
    if (currentMilestone.isCompleted) {
        return 'common.completed'
    } else {
        const tasks = prepareTasks(currentMilestone.tasks)
        const currentTaskIndex = _.findIndex(tasks, ['isCurrent', true])

        if (currentTaskIndex !== -1) {
            const firstTaskIsCurrent = isFirstTaskCurrent(milestones, currentMilestone.id)
            if (currentTaskIndex === 0 && !firstTaskIsCurrent) {
                return 'milestoneDetails.notStarted'
            }
            const currentTask = tasks[currentTaskIndex]
            return currentTask.name
        }
    }
    console.warn('Something is wrong with the milestone/tasks. INVESTIGATE.')
    return null
}

const MilestoneDetailsView = ({ user }) => {
    const { milestoneId, projectId } = useParams()
    const { pathname } = useLocation()

    const [signature, setSignature] = useState(null)
    const [isLightboxOpen, setIsLightboxOpen] = useState(false)
    const handleLightboxClickClose = event => setIsLightboxOpen(false)

    const handleSignatureOnchange = signatureData => setSignature(signatureData)
    const handleCardProgressClickOpenLightbox = event => {
        setIsLightboxOpen(true)
    }
    const onApproveSuccess = () => {
        setIsLightboxOpen(false)
    }

    const navActions = milestones => {
        return [
            {
                label: 'modal.milestone.add-button',
                modalId: 'milestoneDetails',
            },
            {
                label: 'modal.milestone.edit-button',
                modalId: 'milestoneList',
                data: milestones,
            },
        ]
    }

    return (
        <div>
            <ProjectContainer
                projectId={projectId}
                render={({ project }) => (
                    <MilestoneContainer
                        project={project}
                        milestoneId={milestoneId}
                        signature={signature}
                        onApproveSuccess={onApproveSuccess}
                        render={({ milestone, handleClickApprove }) => {
                            const statusText = renderStatusText(project.milestones, milestone)

                            const isCompletedClassName = classNames({
                                'is-completed': milestone.isCompleted,
                                'is-not-started': statusText === 'milestoneDetails.notStarted',
                            })

                            return (
                                <View>
                                    <Layout>
                                        <LayoutItem option={['panelSmall']}>
                                            <Panel option={['small']}>
                                                <PanelContainer>
                                                    <Section>
                                                        <div className="panel_header">
                                                            <Heading
                                                                el="h1"
                                                                option={['h1', 'delay1']}
                                                                utility={['anim']}
                                                            >
                                                                {userCanDo(user, 'project.update') && (
                                                                    <NavActions
                                                                        items={navActions(project.milestones)}
                                                                    />
                                                                )}
                                                                <span className="u-margin-tiny-x">
                                                                    <Translation value="common.tasks" />
                                                                </span>
                                                            </Heading>
                                                        </div>
                                                        <PanelNav
                                                            items={renderNavItems(
                                                                pathname,
                                                                formatMilestonesForNav(project.milestones),
                                                                {
                                                                    projectId: projectId,
                                                                }
                                                            )}
                                                            projectId={projectId}
                                                            option={['delay2']}
                                                            utility={['anim']}
                                                        />
                                                    </Section>
                                                </PanelContainer>
                                                <PanelFooter project={project} option={['delay3']} utility={['anim']} />
                                            </Panel>
                                        </LayoutItem>
                                        <LayoutItem option={['contentSmall']}>
                                            <Container>
                                                <Section>
                                                    <SectionHeader>
                                                        <Layout>
                                                            <LayoutItem utility={['5/8@from-small', 'anim']}>
                                                                <Heading
                                                                    el="h2"
                                                                    option={['h1', 'noMargin', 'delay1']}
                                                                    utility={['anim']}
                                                                >
                                                                    <Translation value={milestone.name} />
                                                                </Heading>
                                                                {statusText !== null && (
                                                                    <Heading
                                                                        el="h2"
                                                                        className={isCompletedClassName}
                                                                        option={['h1', 'blue', 'delay2']}
                                                                        utility={['anim']}
                                                                    >
                                                                        <Translation value="common.status" />
                                                                        {` — `}
                                                                        <Translation value={statusText} />
                                                                    </Heading>
                                                                )}
                                                                {milestone.isCompleted && (
                                                                    <div className="u-anim -delay3">
                                                                        <Translation value="common.since" />{' '}
                                                                        {milestone.updatedAt}
                                                                    </div>
                                                                )}
                                                                {typeof _.get(milestone, 'faq.content') !==
                                                                    'undefined' &&
                                                                    milestone.faq.content && (
                                                                        <InfoText
                                                                            option={['delay3']}
                                                                            utility={['anim', 'margin-small-y']}
                                                                        >
                                                                            <Translation
                                                                                isHtml
                                                                                value={milestone.faq.content}
                                                                            />
                                                                        </InfoText>
                                                                    )}
                                                            </LayoutItem>
                                                            {!milestone.isCompleted &&
                                                                userCanDo(user, 'project.update') && (
                                                                    <LayoutItem
                                                                        option={['delay1']}
                                                                        utility={[
                                                                            'anim',
                                                                            '3/8@from-small',
                                                                            'text-right',
                                                                        ]}
                                                                    >
                                                                        <ModalContainer
                                                                            render={({ handleClickOpen }) => (
                                                                                <>
                                                                                    <Button
                                                                                        icon="plus"
                                                                                        utility={['margin-bottom-tiny']}
                                                                                        option={[
                                                                                            'blue',
                                                                                            'xsmall',
                                                                                            'icon',
                                                                                        ]}
                                                                                        htmlAttrs={{
                                                                                            'data-item-id':
                                                                                                'taskDetails',
                                                                                            'data-item-data': `${JSON.stringify(
                                                                                                {
                                                                                                    taskList:
                                                                                                        milestone.tasks,
                                                                                                }
                                                                                            )}`,
                                                                                        }}
                                                                                        onClick={handleClickOpen}
                                                                                    >
                                                                                        <Translation value="modal.task.add-button" />
                                                                                    </Button>
                                                                                    <Button
                                                                                        icon="edit-simple"
                                                                                        utility={[
                                                                                            'margin-left-tiny',
                                                                                            'margin-bottom-tiny',
                                                                                        ]}
                                                                                        option={[
                                                                                            'blue',
                                                                                            'xsmall',
                                                                                            'icon',
                                                                                        ]}
                                                                                        htmlAttrs={{
                                                                                            'data-item-id': 'taskList',
                                                                                            'data-item-data': `${JSON.stringify(
                                                                                                milestone.tasks
                                                                                            )}`,
                                                                                        }}
                                                                                        onClick={handleClickOpen}
                                                                                    >
                                                                                        <Translation value="modal.task.edit-button" />
                                                                                    </Button>
                                                                                </>
                                                                            )}
                                                                        />
                                                                    </LayoutItem>
                                                                )}
                                                        </Layout>
                                                    </SectionHeader>
                                                    <CardProgress
                                                        handleClickOpenLightbox={handleCardProgressClickOpenLightbox}
                                                        milestone={milestone}
                                                        project={project}
                                                        signature={signature}
                                                        className={isCompletedClassName}
                                                        option={['delay3']}
                                                        utility={['anim']}
                                                        heading={statusText}
                                                    />
                                                    {!_.isEmpty(milestone.resources) && (
                                                        <CardList
                                                            heading="common.resources"
                                                            items={milestone.resources}
                                                        />
                                                    )}
                                                </Section>
                                            </Container>
                                        </LayoutItem>
                                    </Layout>
                                    {milestone.isCompleted &&
                                        milestone.isApprovalRequired &&
                                        userCanDo(
                                            user,
                                            'milestone.approve'
                                        )(
                                            <Lightbox isOpen={isLightboxOpen} onClose={handleLightboxClickClose}>
                                                <div className="lightbox_header">
                                                    <Layout>
                                                        <LayoutItem utility={['3/8@from-small']}>
                                                            <Heading option={['h1']}>
                                                                <Translation value="milestoneDetails.signBelow.line1" />
                                                                <br />
                                                                <Translation value="milestoneDetails.signBelow.line2" />
                                                            </Heading>
                                                        </LayoutItem>
                                                        <LayoutItem utility={['1/8@from-small']}></LayoutItem>
                                                        <LayoutItem utility={['3/8@from-small']}>
                                                            <p>
                                                                <Translation value="milestoneDetails.signatureText" />
                                                            </p>
                                                        </LayoutItem>
                                                    </Layout>
                                                </div>
                                                <Signature onChange={handleSignatureOnchange} />
                                                <div className="lightbox_footer">
                                                    <Layout>
                                                        <LayoutItem utility={['3/8@from-small']}>
                                                            <div className="lightbox_button">
                                                                <Button
                                                                    isDisabled={signature === null}
                                                                    option={['greenLight', 'block', 'large']}
                                                                    onClick={handleClickApprove}
                                                                >
                                                                    <Translation value="milestoneDetails.approveMilestone" />
                                                                    {` `}
                                                                    <Translation value={milestone.name} />
                                                                </Button>
                                                            </div>
                                                        </LayoutItem>
                                                    </Layout>
                                                </div>
                                            </Lightbox>
                                        )}
                                </View>
                            )
                        }}
                    />
                )}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(MilestoneDetailsView)
