import PropTypes from 'prop-types'
import React from 'react'

import './_formError.scss'

const FormError = ({ children = null }) => {
    if (children === null) {
        return children
    } else {
        return <div className="formError">{children}</div>
    }
}

FormError.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
}

export default FormError
