import React from 'react'

import { ButtonCircle } from '../Button'

const ModalHeading = ({ children, handleClickClose }) => (
    <header className="modal-heading u-padding-small">
        <ButtonCircle icon="close" option={['small', 'gray']} onClick={handleClickClose} />
        <span className="u-margin-small-x">{children}</span>
    </header>
)

export default ModalHeading
