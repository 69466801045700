import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { userCanDo } from '../utils'

import { Button } from '../components/Button'
import { CardContact } from '../components/CardContact'
import { CardListAccordion } from '../components/CardListAccordion'
import { CardPreview } from '../components/CardPreview'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { HelpContainer } from '../components/Help'
import { Layout, LayoutItem } from '../components/Layout'
import { ModalContainer } from '../components/Modal'
import { NavActions } from '../components/NavActions'
import { Panel } from '../components/Panel'
import { Pointer } from '../components/Pointer'
import { ProjectContainer } from '../components/Project'
import { Section } from '../components/Section'
import { Translation } from '../components/Translation'
import { View } from '../components/View'

const ProjectDetailsView = ({ user }) => {
    const { projectId } = useParams()

    const navActions = project => [
        {
            label: 'navActions.update-project',
            route: { id: 'project-edit', replacements: { projectId: projectId } },
        },
        {
            label: 'modal.projectTimeline.edit-button',
            modalId: 'projectPlanningDetails',
            data: project,
        },
        {
            label: 'modal.projectDates.edit-button',
            modalId: 'projectDatesDetails',
            data: project,
        },
    ]

    return (
        <div>
            <ProjectContainer
                projectId={projectId}
                render={({ project }) => (
                    <View>
                        <Layout>
                            <LayoutItem option={['panelLarge']}>
                                <Panel option={['delay']} utility={['anim']}>
                                    <Container>
                                        <Section>
                                            <Heading el="h1" option={['h1', 'delay1']} utility={['anim']}>
                                                {userCanDo(user, 'project.update') && (
                                                    <NavActions items={navActions(project)} />
                                                )}
                                                <span className="u-margin-tiny-x">
                                                    <Translation value={project.name} />
                                                </span>
                                            </Heading>
                                            <CardPreview project={project} option={['delay2']} utility={['anim']} />
                                            {!_.isEmpty(project.producers) && (
                                                <CardContact
                                                    contact={project.producers[0]}
                                                    option={['delay3']}
                                                    utility={['anim']}
                                                />
                                            )}
                                        </Section>
                                    </Container>
                                </Panel>
                            </LayoutItem>

                            <LayoutItem option={['contentLarge']}>
                                <Container>
                                    <Section option={['delay3']} utility={['anim']}>
                                        <div className="u-relative">
                                            <div className="u-margin-small-y">
                                                <Heading el="h2" option={['h1', 'noMargin']}>
                                                    <Translation value="common.resources" />
                                                </Heading>
                                            </div>
                                            {userCanDo(user, 'project.update') && (
                                                <div className="u-margin-small-y u-text-right">
                                                    <ModalContainer
                                                        render={({ handleClickOpen }) => (
                                                            <React.Fragment>
                                                                <Button
                                                                    icon="plus"
                                                                    option={['blue', 'xsmall', 'icon']}
                                                                    htmlAttrs={{
                                                                        'data-item-id': 'resourceGroupDetails',
                                                                    }}
                                                                    onClick={handleClickOpen}
                                                                >
                                                                    <Translation value="modal.resource-group.add-button" />
                                                                </Button>
                                                                {project.resources.length > 0 && (
                                                                    <Button
                                                                        icon="edit-simple"
                                                                        utility={['margin-left-tiny']}
                                                                        option={['blue', 'xsmall', 'icon']}
                                                                        htmlAttrs={{
                                                                            'data-item-id': 'resourceGroupList',
                                                                            'data-item-data': `${JSON.stringify(
                                                                                project.resources
                                                                            )}`,
                                                                        }}
                                                                        onClick={handleClickOpen}
                                                                    >
                                                                        <Translation value="modal.resource-group.edit-button" />
                                                                    </Button>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                    />
                                                </div>
                                            )}
                                            <Pointer
                                                ident={1}
                                                position="left"
                                                mobilePosition="top"
                                                align="top"
                                                mobileAlign="right"
                                                scrollTo={true}
                                            />
                                        </div>
                                        <HelpContainer
                                            render={({ handleClickOpenContent }) =>
                                                project.resources.map(group => {
                                                    const footerCta =
                                                        group.faq !== null
                                                            ? {
                                                                  label: group.faq.name,
                                                                  id: group.faq.id,
                                                                  onClick: handleClickOpenContent,
                                                              }
                                                            : null
                                                    return (
                                                        <CardListAccordion
                                                            key={group.id}
                                                            group={group}
                                                            footerCta={footerCta}
                                                            heading={group.name}
                                                            items={group.resources}
                                                            showButtons
                                                        />
                                                    )
                                                })
                                            }
                                        />
                                    </Section>
                                </Container>
                            </LayoutItem>
                        </Layout>
                    </View>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ProjectDetailsView)
