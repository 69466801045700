import { apiClient } from './apiClient'

export const baseUrl = 'projects'

export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT'
export const SET_PROJECT_DATA = 'SET_PROJECT_DATA'

export const REFRESH_PROJECT_REQUEST = 'REFRESH_PROJECT_REQUEST'

export function projectApiUrl(projectId) {
    return `${baseUrl}/${projectId}`
}

const projectsUrl = `${baseUrl}/self`

/**
 * Create new project.
 */
export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE'
export const CREATE_PROJECT_PENDING = 'CREATE_PROJECT_PENDING'
export function createProject(formData, config = {}) {
    return {
        promise: client => client.post(baseUrl, formData, config),
        types: [CREATE_PROJECT_REQUEST, CREATE_PROJECT_SUCCESS, CREATE_PROJECT_FAILURE, CREATE_PROJECT_PENDING],
    }
}

/**
 * Retrieve all projects from a user ID.
 */
export const FETCH_PROJECT_LIST_REQUEST = 'FETCH_PROJECT_LIST_REQUEST'
export const FETCH_PROJECT_LIST_SUCCESS = 'FETCH_PROJECT_LIST_SUCCESS'
export const FETCH_PROJECT_LIST_FAILURE = 'FETCH_PROJECT_LIST_FAILURE'
export const FETCH_PROJECT_LIST_PENDING = 'FETCH_PROJECT_LIST_PENDING'
export function fetchProjects(config = {}) {
    return {
        promise: client => client.get(projectsUrl, config),
        types: [
            FETCH_PROJECT_LIST_REQUEST,
            FETCH_PROJECT_LIST_SUCCESS,
            FETCH_PROJECT_LIST_FAILURE,
            FETCH_PROJECT_LIST_PENDING,
        ],
    }
}

/**
 * Retrieve all projects from a user ID.
 */
export const REFRESH_PROJECT_LIST_REQUEST = 'REFRESH_PROJECT_LIST_REQUEST'
export const REFRESH_PROJECT_LIST_SUCCESS = 'REFRESH_PROJECT_LIST_SUCCESS'
export const REFRESH_PROJECT_LIST_FAILURE = 'REFRESH_PROJECT_LIST_FAILURE'
export const REFRESH_PROJECT_LIST_PENDING = 'REFRESH_PROJECT_LIST_PENDING'
export function refreshProjects(config = {}) {
    return {
        promise: client => client.get(projectsUrl, config),
        types: [
            REFRESH_PROJECT_LIST_REQUEST,
            REFRESH_PROJECT_LIST_SUCCESS,
            REFRESH_PROJECT_LIST_FAILURE,
            REFRESH_PROJECT_LIST_PENDING,
        ],
    }
}

/**
 * Retrieve a project from an ID.
 */
export const FETCH_PROJECT_CATEGORY_LIST_REQUEST = 'FETCH_PROJECT_CATEGORY_LIST_REQUEST'
export const FETCH_PROJECT_CATEGORY_LIST_SUCCESS = 'FETCH_PROJECT_CATEGORY_LIST_SUCCESS'
export const FETCH_PROJECT_CATEGORY_LIST_FAILURE = 'FETCH_PROJECT_CATEGORY_LIST_FAILURE'
export const FETCH_PROJECT_CATEGORY_LIST_PENDING = 'FETCH_PROJECT_CATEGORY_LIST_PENDING'
export function fetchProjectCategories(projectId, config = {}) {
    return {
        promise: client => client.get(`${baseUrl}/categories`, config),
        types: [
            FETCH_PROJECT_CATEGORY_LIST_REQUEST,
            FETCH_PROJECT_CATEGORY_LIST_SUCCESS,
            FETCH_PROJECT_CATEGORY_LIST_FAILURE,
            FETCH_PROJECT_CATEGORY_LIST_PENDING,
        ],
    }
}

/**
 * Retrieve a project from an ID.
 */
export const FETCH_PROJECT_DETAILS_REQUEST = 'FETCH_PROJECT_DETAILS_REQUEST'
export const FETCH_PROJECT_DETAILS_SUCCESS = 'FETCH_PROJECT_DETAILS_SUCCESS'
export const FETCH_PROJECT_DETAILS_FAILURE = 'FETCH_PROJECT_DETAILS_FAILURE'
export const FETCH_PROJECT_DETAILS_PENDING = 'FETCH_PROJECT_DETAILS_PENDING'
export function fetchProject(projectId, config = {}) {
    return {
        promise: client => client.get(projectApiUrl(projectId), config),
        types: [
            FETCH_PROJECT_DETAILS_REQUEST,
            FETCH_PROJECT_DETAILS_SUCCESS,
            FETCH_PROJECT_DETAILS_FAILURE,
            FETCH_PROJECT_DETAILS_PENDING,
        ],
    }
}

/**
 * Update a project from an ID and formData.
 */
export const UPDATE_PROJECT_DETAILS_REQUEST = 'UPDATE_PROJECT_DETAILS_REQUEST'
export const UPDATE_PROJECT_DETAILS_SUCCESS = 'UPDATE_PROJECT_DETAILS_SUCCESS'
export const UPDATE_PROJECT_DETAILS_FAILURE = 'UPDATE_PROJECT_DETAILS_FAILURE'
export const UPDATE_PROJECT_DETAILS_PENDING = 'UPDATE_PROJECT_DETAILS_PENDING'
export function updateProject(projectId, formData, config = {}) {
    return {
        promise: client => client.patch(projectApiUrl(projectId), formData, config),
        types: [
            UPDATE_PROJECT_DETAILS_REQUEST,
            UPDATE_PROJECT_DETAILS_SUCCESS,
            UPDATE_PROJECT_DETAILS_FAILURE,
            UPDATE_PROJECT_DETAILS_PENDING,
        ],
    }
}

/**
 * Update a project, from an ID, by adding the currently authenticated
 * user to its users.
 */
export function updateProjectAddSelf(projectId, config = {}) {
    return apiClient.patch(`${projectApiUrl(projectId)}/add-self`, {}, config)
}

/**
 * Update a project, from an ID, by removing the currently authenticated
 * user from its users.
 */
export function updateProjectRemoveSelf(projectId, config = {}) {
    return apiClient.patch(`${projectApiUrl(projectId)}/remove-self`, {}, config)
}

/**
 * Clear cache for user ID project list.
 */
export function clearProjectsCache() {
    return apiClient.clearCache(projectsUrl)
}

/**
 * Clear cache for a specific project.
 */
export function clearProjectCache(projectId) {
    return apiClient.clearCache(projectApiUrl(projectId))
}
