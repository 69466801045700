import { connect } from 'react-redux'
import { getLanguages } from 'react-localize-redux'

import { apiClient } from '../../actions'
import { logout } from '../../actions/userActions'
import { Header } from '../Header'

const mapStateToProps = state => ({
    languages: getLanguages(state.localize),
    pathname: state.router.location.pathname,
    project: state.currentProject.data,
    user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
    handleLogout: () => {
        apiClient.clearCache()
        dispatch(logout())
    },
})

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderContainer
