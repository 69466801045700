import { connectRouter } from 'connected-react-router'
import { localizeReducer } from 'react-localize-redux'
import { combineReducers } from 'redux'

import { requestStates } from './api'
import { auth } from './auth'
import { clients } from './clients'
import { companies } from './companies'
import { currentProject } from './currentProject'
import { currentMilestone } from './currentMilestone'
import { employees } from './employees'
import { projectCategories } from './projectCategories'
import { projects } from './projects'
import { view } from './view'

const appReducer = history =>
    combineReducers({
        router: connectRouter(history),
        localize: localizeReducer,
        auth,
        clients,
        companies,
        currentProject,
        currentMilestone,
        employees,
        projectCategories,
        projects,
        view,
        requestStates,
    })

const rootReducer = history => (state, action) => {
    if (action.type === 'DELETE_USER_LOGIN_SUCCESS') {
        // If you want to keep some data in store on DELETE_USER_LOGIN_SUCCESS,
        // here is where you do it.
        const { localize, router } = state
        state = { localize, router }
    }
    return appReducer(history)(state, action)
}

export default rootReducer
