import PropTypes from 'prop-types'
import React from 'react'

import { Lightbox } from '../Lightbox'

import './_lightboxVideo.scss'

const LightboxVideo = ({ isOpen = false, onClose, platform, videoId }) => (
    <div className="lightboxVideo">
        <Lightbox isOpen={isOpen} onClose={onClose}>
            <div className="lightboxVideo_main">
                {isOpen && platform === 'youtube' && (
                    <iframe
                        title={`${platform} video`}
                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"
                        allowFullScreen
                    ></iframe>
                )}
            </div>
        </Lightbox>
    </div>
)

LightboxVideo.propTypes = {
    ...Lightbox.propTypes,
    platform: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
}

export default LightboxVideo
