import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'

import { prepareTasks, renderClasses } from '../../utils'
import { Button } from '../Button'
import { Card, CardMain } from '../Card'
import { Heading } from '../Heading'
import { Layout, LayoutItem } from '../Layout'
import { Link } from '../Link'
import { Timeline, TimelineTaskItem } from '../Timeline'
import { Translation } from '../Translation'

import './_cardProgress.scss'

const CardProgress = ({ handleClickOpenLightbox, milestone, project, heading, ...classProps }) => {
    const approvalDocuments =
        milestone.approvalDocuments && milestone.approvalDocuments.length > 0
            ? milestone.approvalDocuments.map((file, index) => (
                  <div className="cardProgress_header_item" key={index}>
                      <Link option={['input']} icon="file" href={file.downloadUrl} isBlank>
                          {file.filename}
                      </Link>
                  </div>
              ))
            : null
    return (
        <section className={renderClasses('cardProgress', classProps)}>
            <Card option={['small']}>
                <header className="cardProgress_header">
                    <div className="cardProgress_header_container u-anim -delay4">
                        {milestone.isApproved ? (
                            <>
                                <div className="cardProgress_header_title">
                                    <Heading option={['h1', 'noMargin']}>
                                        <Translation value="common.approved" />
                                    </Heading>
                                    <time className="cardProgress_header_date" dateTime={milestone.approvedAt}>
                                        {moment(milestone.approvedAt).format('MMM DD, YYYY')}
                                    </time>
                                </div>
                                {approvalDocuments}
                                {milestone.approvalSignature && (
                                    <div className="cardProgress_header_signature">
                                        <img src={milestone.approvalSignature} alt="" />
                                    </div>
                                )}
                            </>
                        ) : milestone.isCompleted && milestone.isApprovalRequired ? (
                            <>
                                <div className="cardProgress_header_title">
                                    <Heading option={['h1', 'noMargin']}>
                                        <Translation value="milestoneDetails.timeToReview" />
                                    </Heading>
                                    <time className="cardProgress_header_date">
                                        {moment(milestone.completedAt).format('MMM DD, YYYY')}
                                    </time>
                                </div>
                                {approvalDocuments}
                                <div className="cardProgress_header_item">
                                    <Button option={['greenLight', 'block', 'large']} onClick={handleClickOpenLightbox}>
                                        <Translation value="milestoneDetails.approveNow" />
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Heading option={['h1', 'noMargin']}>
                                <Translation value="common.status" />
                                {` — `}
                                <Translation value={heading} />
                            </Heading>
                        )}
                    </div>
                </header>

                <div className="cardProgress_titles u-anim -delay5">
                    <Layout>
                        <LayoutItem utility={['3/8', 'text-right']}>
                            <Heading option={['h3', 'medium']}>
                                <Translation value="common.agency" />
                            </Heading>
                        </LayoutItem>
                        <LayoutItem utility={['2/8']}></LayoutItem>
                        <LayoutItem utility={['3/8']}>
                            <Heading option={['h3', 'medium']}>
                                <Translation value="common.client" />
                            </Heading>
                        </LayoutItem>
                    </Layout>
                </div>

                <CardMain option={['delay6']} utility={['u-anim']}>
                    <div className="cardProgress_main">
                        <Timeline option={['center']}>
                            {typeof milestone.tasks !== 'undefined' &&
                                _.map(prepareTasks(milestone.tasks), (task, index) => (
                                    <TimelineTaskItem
                                        key={task.id}
                                        task={task}
                                        milestoneIsCompleted={milestone.isCompleted}
                                    />
                                ))}
                        </Timeline>
                    </div>
                </CardMain>
            </Card>
        </section>
    )
}

CardProgress.propTypes = {
    handleClickOpenLightbox: PropTypes.func.isRequired,
    milestone: PropTypes.object.isRequired,
}

export default CardProgress
