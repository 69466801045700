import _ from 'lodash'

import { renderRoute } from './routes'

/**
 * Determine if a nav item is active based on the pathname.
 *
 * @param  {Object}  item
 * @param  {String}  pathname
 * @return {Boolean}
 */
function isNavItemActive(item, pathname) {
    if (_.isArray(item.match) && !_.isEmpty(item.match)) {
        const matched = _.find(item.match, function(match) {
            const regex = new RegExp(`^${renderRoute(match)}$`)
            return regex.test(pathname)
        })
        return typeof matched !== 'undefined'
    } else if (!_.isEmpty(item.url)) {
        return item.url === pathname
    } else {
        return false
    }
}

/**
 * Format a list of nav items based on the current pathname and their children or lack of.
 *
 * @param  {Array}  items
 * @param  {String} pathname
 * @return {array}
 */
function parseNavItemsForActiveState(items, pathname) {
    return items.map(item => {
        item.isActive = isNavItemActive(item, pathname)
        item.hasActiveNavItems = false

        if (_.isArray(item.items)) {
            item.items = parseNavItemsForActiveState(item.items, pathname)
            const hasActiveNavItems = item.items.filter(item => item.isActive || item.hasActiveNavItems).length > 0
            item.hasActiveNavItems = hasActiveNavItems
        }

        return item
    })
}

function parseNavItemsRenderUrl(items, replacements) {
    return items.map(item => {
        /** Don't attempt to render a route if the URL is present */
        if (typeof item.route !== 'undefined') {
            if (_.isObject(item.replacements)) {
                replacements = { ...replacements, ...item.replacements }
            }

            item.url = renderRoute(item.route, replacements)
        }

        if (_.isArray(item.items)) {
            item.items = parseNavItemsRenderUrl(item.items)
        }

        return item
    })
}

/**
 * Retrieve a list of account nav items based on current pathname.
 *
 * @param  {String} pathname
 * @param  {Array}  items
 * @return {array}
 */
export function renderNavItems(pathname, items, replacements) {
    return parseNavItemsForActiveState(parseNavItemsRenderUrl(items, replacements), pathname)
}

/**
 * @param  {Array} plans
 * @param  {Object} project
 * @return {Array}
 */
export function filterSupportItemsForNav(items, project) {
    return items.filter(item => {
        return item.route === 'tutorial-list' && project.tutorials.length === 0 ? false : true
    })
}

/**
 * Retrieve a formatted list of plan nav items.
 *
 * @param  {Array} plans
 * @return {Array}
 */
export function formatPlansForNav(plans) {
    return _.map(plans, plan => {
        return {
            label: plan.type.name,
            route: 'plan-details',
            replacements: {
                planId: plan.id,
            },
        }
    })
}
