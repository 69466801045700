import classNames from 'classnames'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { formatFormNotifications, useReorderItems, useRemoveItem } from '../../utils'
import { clearMilestoneCache, REFRESH_MILESTONE_REQUEST } from '../../actions/milestoneActions'
import { REFRESH_PROJECT_REQUEST } from '../../actions/projectActions'
import { updateTask } from '../../actions/taskActions'

import { Button, ButtonCircle } from '../Button'
import { FormNotifications } from '../Form'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { Translation } from '../Translation'
import { Validator } from '../Validator'

const ModalTaskList = ({ data: taskList, dispatch, handleClickClose }) => {
    const { projectId } = useParams()
    const [tasks, setTasks] = useState(taskList)

    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setTasks(taskList)
        return () => {
            setTasks([])
        }
    }, [taskList])

    const close = event => {
        setTasks([])
        handleClickClose()
    }

    const reorder = useReorderItems(tasks, setTasks)
    const remove = useRemoveItem(tasks, setTasks)

    const handleSubmit = event => {
        event.preventDefault()

        let milestoneId = null

        if (isLoading) {
            return false
        }

        setIsLoading(true)

        Promise.all(
            tasks.map(task => {
                milestoneId = task.milestone.id
                const formData = {
                    position: task.position,
                    active: task.active,
                }
                return updateTask(projectId, task.id, formData)
                    .then(response => response.data)
                    .catch(error => {
                        throw new Error(error.response.data)
                    })
                    .finally(() => {})
            })
        )
            .then(() => {
                if (milestoneId !== null) {
                    clearMilestoneCache(projectId, milestoneId)
                    dispatch({
                        type: REFRESH_MILESTONE_REQUEST,
                    })
                    dispatch({
                        type: REFRESH_PROJECT_REQUEST,
                    })
                }
                setIsLoading(false)
                handleClickClose()
            })
            .catch(error => {
                setErrors(error)
                setIsLoading(false)
            })
        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-tasks">
            <ModalHeading handleClickClose={close}>
                <Translation value="modal.task.list-title" />
            </ModalHeading>
            <div className="modal_container">
                {hasNotifications && <FormNotifications hasErrors={hasErrors} items={notifications} />}
                <ul className="modal-list">
                    {tasks
                        .filter(item => item.active)
                        .map((item, index) => (
                            <li
                                className={classNames('modal-list_item', { '-single': tasks.length <= 1 })}
                                key={`modaletasks-${item.id}`}
                            >
                                {tasks.length > 1 && (
                                    <div className="modal-list_item_controls">
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === 0,
                                            })}
                                        >
                                            <ButtonCircle
                                                option={['small', 'border']}
                                                icon="arrow-up-small"
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'up',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={classNames('modal-list_item_controls_item', {
                                                '-disabled': index === tasks.length - 1,
                                            })}
                                        >
                                            <ButtonCircle
                                                option={['small', 'border']}
                                                icon="arrow-down-small"
                                                onClick={reorder}
                                                htmlAttrs={{
                                                    'data-direction': 'down',
                                                    'data-current-position': `${index}`,
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="modal-list_item_inner">
                                    <div className="modal-list_item_content">
                                        <h3 className="modal-list_item_title">
                                            <Translation value={item.name} />
                                        </h3>
                                        <p className="modal-list_item_text">
                                            <Translation value={item.description} />
                                        </p>
                                    </div>
                                    <div className="modal-list_item_actions">
                                        <div className="modal-list_item_action">
                                            <Validator
                                                label="validator.remove-task"
                                                data={{
                                                    itemId: item.id,
                                                }}
                                            >
                                                <ButtonCircle
                                                    option={['small', 'gray']}
                                                    icon="trash"
                                                    onClick={remove}
                                                />
                                            </Validator>
                                        </div>
                                        <div className="modal-list_item_action">
                                            <ModalContainer
                                                render={({ handleClickOpen }) => (
                                                    <ButtonCircle
                                                        option={['small', 'gray']}
                                                        icon="edit-simple"
                                                        onClick={handleClickOpen}
                                                        htmlAttrs={{
                                                            'data-item-id': 'taskDetails',
                                                            'data-item-data': `${JSON.stringify({
                                                                task: item,
                                                                taskList: tasks,
                                                            })}`,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
                <ModalActions utility={['padding-small']}>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button onClick={handleClickClose}>
                                    <Translation value="modal.action-cancel" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                    <ModalActionsItem>
                        <ModalContainer
                            render={({ handleClickClose }) => (
                                <Button
                                    className={classNames({ 'is-loading': isLoading })}
                                    onClick={handleSubmit}
                                    hasWrapper
                                    option={['blue', 'small', 'block', 'spinner']}
                                    htmlAttrs={{
                                        disabled: tasks === taskList,
                                    }}
                                >
                                    <Translation value="modal.action-save" />
                                </Button>
                            )}
                        />
                    </ModalActionsItem>
                </ModalActions>
            </div>
        </div>
    )
}

export default connect()(ModalTaskList)
