import classNames from 'classnames'
import Immutable from 'immutable'
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { clearMilestoneCache, REFRESH_MILESTONE_REQUEST } from '../../actions/milestoneActions'
import { createTask, updateTask } from '../../actions/taskActions'
import { formatFormNotifications, translate as utilTranslate } from '../../utils'

import { Button, ButtonCircle } from '../Button'
import { FormContainer, FormError, FormInput, FormInputChoice, FormItem, FormLabel, FormNotifications } from '../Form'
import { ModalActions, ModalActionsItem, ModalContainer, ModalHeading } from '../Modal'
import { Translation } from '../Translation'

const ModalTaskDetails = ({ data: { task = null, taskList = [] }, dispatch, handleClickClose, translate }) => {
    const { projectId, milestoneId } = useParams()
    const isEdit = !_.isEmpty(task) ? true : false
    const [initialValues, setInitialValues] = useState(Immutable.Map({}))

    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [participants, setParticipants] = useState([])
    const [isApprovalRequired, setIsApprovalRequired] = useState(true)

    const lastPosition = taskList.length > 0 ? taskList[taskList.length - 1].position : 0

    useEffect(() => {
        if (!_.isEmpty(task)) {
            setInitialValues(Immutable.Map(task))
            setParticipants(task.participants.map(participant => participant.id))
            setIsApprovalRequired(task.isApprovalRequired)
        }
        return () => {
            setInitialValues(Immutable.Map({}))
            setParticipants([])
            setIsApprovalRequired(null)
        }
    }, [task])

    const onParticipantsCheckboxChange = event => {
        const value = event.target.id
        let items = [...participants]
        if (participants.includes(value)) {
            items.splice(items.indexOf(value), 1)
            setParticipants(items)
        } else {
            setParticipants([...items, value])
        }
        return value
    }

    const onApprovalCheckboxChange = event => {
        const value = event.target.checked
        setIsApprovalRequired(value)
        return value
    }

    const handleSubmit = ({ event, form: { setFieldsValue, resetFields, validateFields } }) => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        setFieldsValue({
            'modal.participants': participants,
            'modal.isApprovalRequired': isApprovalRequired,
        })
        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                if (!isEdit) {
                    formValues.modal.milestone = milestoneId
                    formValues.modal.position = lastPosition
                }

                const action = isEdit
                    ? () => {
                          return updateTask(projectId, task.id, formValues.modal)
                      }
                    : () => {
                          return createTask(projectId, formValues.modal)
                      }

                setIsLoading(true)
                action()
                    .then(() => {
                        clearMilestoneCache(projectId, milestoneId)
                        dispatch({
                            type: REFRESH_MILESTONE_REQUEST,
                        })
                        setIsLoading(false)
                        handleClickClose()
                        resetFields()
                    })
                    .catch(error => {
                        setErrors(error.response.data)
                        setIsLoading(false)
                    })
            }
        })
        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-tasks-form">
            {isEdit ? (
                <header className="modal-heading u-padding-small">
                    <ModalContainer
                        render={({ handleClickOpen }) => (
                            <ButtonCircle
                                icon="close"
                                option={['small', 'gray']}
                                onClick={handleClickOpen}
                                htmlAttrs={{
                                    'data-item-id': 'taskList',
                                    'data-item-data': `${JSON.stringify(taskList)}`,
                                }}
                            />
                        )}
                    />
                    <span className="u-margin-small-x">
                        <Translation value="modal.task.form-title-edit" />
                    </span>
                </header>
            ) : (
                <ModalHeading handleClickClose={handleClickClose}>
                    <Translation value="modal.task.form-title-add" />
                </ModalHeading>
            )}
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormLabel inputId="modal.name" isRequired>
                                    <Translation value="modal.task.form-label-name" />
                                </FormLabel>
                                <FormInput
                                    id="modal.name"
                                    name="modal.name"
                                    form={form}
                                    fieldOptions={{
                                        initialValue:
                                            initialValues.get('name') instanceof Object
                                                ? utilTranslate(initialValues.get('name'))
                                                : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.name')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="modal.description" isRequired>
                                    <Translation value="modal.task.form-label-description" />
                                </FormLabel>
                                <FormInput
                                    id="modal.description"
                                    name="modal.description"
                                    form={form}
                                    fieldOptions={{
                                        initialValue:
                                            initialValues.get('description') instanceof Object
                                                ? utilTranslate(initialValues.get('description'))
                                                : '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.description')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="modal.participants" isRequired>
                                    <Translation value="modal.task.form-label-participants" />
                                </FormLabel>
                                <FormInputChoice
                                    id="agency"
                                    name="modal.participants"
                                    form={form}
                                    className="formCheckbox"
                                    type="checkbox"
                                    label={translate('common.agency')}
                                    checked={participants.includes('agency')}
                                    fieldOptions={{
                                        initialValue: participants.includes('agency') ? 'agency' : '',
                                        getValueFromEvent: onParticipantsCheckboxChange,
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormInputChoice
                                    id="client"
                                    name="modal.participants"
                                    form={form}
                                    type="checkbox"
                                    className="formCheckbox"
                                    label={translate('common.client')}
                                    checked={participants.includes('client')}
                                    fieldOptions={{
                                        initialValue: participants.includes('client') ? 'client' : '',
                                        getValueFromEvent: onParticipantsCheckboxChange,
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('modal.participants')}</FormError>
                            </FormItem>
                            {(isEdit === false || (isEdit === true && !task.isApproved && !task.isCompleted)) && (
                                <FormItem>
                                    <FormInputChoice
                                        id="modal.isApprovalRequired"
                                        name="modal.isApprovalRequired"
                                        form={form}
                                        type="checkbox"
                                        className="formCheckbox"
                                        label={translate('modal.task.form-label-approval')}
                                        checked={isApprovalRequired === true}
                                        fieldOptions={{
                                            getValueFromEvent: onApprovalCheckboxChange,
                                        }}
                                    />
                                    <FormError>{form.getFieldError('modal.isApprovalRequired')}</FormError>
                                </FormItem>
                            )}
                            <ModalActions>
                                {isEdit && (
                                    <ModalActionsItem>
                                        <ModalContainer
                                            render={({ handleClickOpen }) => (
                                                <Button
                                                    onClick={handleClickOpen}
                                                    htmlAttrs={{
                                                        'data-item-id': 'taskList',
                                                        'data-item-data': `${JSON.stringify(taskList)}`,
                                                    }}
                                                >
                                                    <Translation value="modal.action-cancel" />
                                                </Button>
                                            )}
                                        />
                                    </ModalActionsItem>
                                )}
                                <ModalActionsItem>
                                    <Button
                                        className={classNames({ 'is-loading': isLoading })}
                                        hasWrapper
                                        option={['blue', 'small', 'block', 'spinner']}
                                        type="submit"
                                    >
                                        <Translation value="modal.action-save" />
                                    </Button>
                                </ModalActionsItem>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </div>
    )
}

export default withLocalize(connect()(ModalTaskDetails))
