import React from 'react'
import PropTypes from 'prop-types'
import { formShape } from 'rc-form'

import { renderClasses } from '../../utils'

import './_formSelect.scss'

const FormSelect = ({
    fieldOptions = {},
    defaultItem = null,
    form,
    id,
    items = [],
    itemsMapper = item => item,
    name,
    onChange,
    ...classProps
}) => (
    <div className={renderClasses('formSelect', classProps, form.getFieldError(name) ? 'has-error' : '')}>
        {form.getFieldDecorator(
            name,
            fieldOptions
        )(
            <select className="formSelect_input" id={id} name={name} onChange={onChange}>
                {defaultItem && <option value={defaultItem.value}>{defaultItem.label}</option>}
                {items.map(itemsMapper).map(({ id, label, value, active }, index) => (
                    <option
                        value={id ?? value}
                        key={index}
                        disabled={typeof active === 'undefined' || active ? false : true}
                    >
                        {label}
                    </option>
                ))}
            </select>
        )}
    </div>
)

FormSelect.propTypes = {
    defaultItem: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        value: PropTypes.string,
    }),
    form: formShape,
    id: PropTypes.string.isRequired,
    items: PropTypes.array,
    name: PropTypes.string.isRequired,
}

export default FormSelect
