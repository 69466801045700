import PropTypes from 'prop-types'
import React from 'react'

import { ButtonIcon } from '../Button'

import { renderClasses } from '../../utils'

import './_button.scss'

const TYPES = ['button', 'submit']

const Wrapper = ({ children, display = false }) =>
    display === true ? <span className="button_inner">{children}</span> : children

const Button = ({
    children,
    hasWrapper = false,
    htmlAttrs = {},
    icon = null,
    isDisabled = false,
    onClick = null,
    type = 'button',
    ...classProps
}) => (
    <button
        onClick={onClick}
        type={type}
        className={renderClasses('button', classProps)}
        disabled={isDisabled}
        {...htmlAttrs}
    >
        <Wrapper display={hasWrapper}>
            <ButtonIcon name={icon} />
            {children}
        </Wrapper>
    </button>
)

Button.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    hasWrapper: PropTypes.bool,
    htmlAttrs: PropTypes.object,
    isDisabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(TYPES),
}

export default Button
