import { setup } from 'axios-cache-adapter'
import _ from 'lodash'
import { getActiveLanguage } from 'react-localize-redux'

class ApiClient {
    constructor() {
        const baseURL = `${process.env.REACT_APP_API_URL}/`
        this.axiosClient = setup({
            baseURL: baseURL,
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/json',
            },
            json: true,
            cache: {
                // debug: true,
                exclude: {
                    paths: [`^${baseURL}users/self`],
                },
            },
        })
        this.axiosClient.interceptors.response.use(
            response => {
                return response
            },
            error => {
                /** Makes sure our actions always receive errors in the same format. */
                if (typeof error.response === 'undefined') {
                    error.response = {
                        data: [{ message: 'A network error occured.' }],
                    }
                }
                return Promise.reject(error)
            }
        )
    }

    setStore(store) {
        this.store = store
    }

    clearCache(endpoint) {
        if (typeof endpoint === 'string') {
            const url = this.axiosClient.defaults.baseURL + endpoint
            this.axiosClient.cache.removeItem(url)
        } else {
            this.axiosClient.cache.clear()
        }
    }

    addHeadersToConfig(config = {}) {
        if (typeof config.headers !== 'object') {
            config.headers = {}
        }
        const state = this.store.getState()
        const token = (state.auth || {}).token
        const activeLanguage = (getActiveLanguage(state.localize) || {}).code
        config.headers['Authorization'] = `Bearer ${_.isEmpty(token) ? '' : token}`
        config.headers['Locale'] = _.isEmpty(activeLanguage) ? 'fr' : activeLanguage
        return config
    }

    addVersionToConfig(config = {}) {
        if (typeof config.headers !== 'object') {
            config.headers = {}
        }
        config.headers['X-App-Version'] = process.env.REACT_APP_API_VERSION
        return config
    }

    prepareConfig(config = {}) {
        return this.addVersionToConfig(this.addHeadersToConfig(config))
    }

    get(url, conf = {}) {
        return this.axiosClient.get(url, this.addHeadersToConfig(conf))
    }

    delete(url, conf = {}) {
        return this.axiosClient.delete(url, this.addHeadersToConfig(conf))
    }

    head(url, conf = {}) {
        return this.axiosClient.head(url, this.addHeadersToConfig(conf))
    }

    options(url, conf = {}) {
        return this.axiosClient.options(url, this.addHeadersToConfig(conf))
    }

    post(url, data = {}, conf = {}) {
        return this.axiosClient.post(url, data, this.addHeadersToConfig(conf))
    }

    put(url, data = {}, conf = {}) {
        return this.axiosClient.put(url, data, this.addHeadersToConfig(conf))
    }

    patch(url, data = {}, conf = {}) {
        return this.axiosClient.patch(url, data, this.addHeadersToConfig(conf))
    }
}

export const apiClient = new ApiClient()
