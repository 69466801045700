import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import {
    ModalClientDetails,
    ModalCompanyDetails,
    ModalMilestoneList,
    ModalMilestoneDetails,
    ModalProjectDatesDetails,
    ModalProjectPlanningDetails,
    ModalProjectTeamDetails,
    ModalResourceGroupList,
    ModalResourceGroupDetails,
    ModalResourceList,
    ModalResourceDetails,
    ModalTaskList,
    ModalTaskDetails,
} from '../Modal'

import './_modal.scss'

const Modal = ({ modalId = null, data, handleClickClose, handleClickOpen, handleModalSubmit, isOpen = false }) => {
    const components = {
        clientDetails: ModalClientDetails,
        companyDetails: ModalCompanyDetails,
        milestoneList: ModalMilestoneList,
        milestoneDetails: ModalMilestoneDetails,
        projectDatesDetails: ModalProjectDatesDetails,
        projectPlanningDetails: ModalProjectPlanningDetails,
        projectTeamDetails: ModalProjectTeamDetails,
        resourceGroupList: ModalResourceGroupList,
        resourceGroupDetails: ModalResourceGroupDetails,
        resourceList: ModalResourceList,
        resourceDetails: ModalResourceDetails,
        taskList: ModalTaskList,
        taskDetails: ModalTaskDetails,
    }

    const ModalComponent = components[modalId]
    if (!ModalComponent) {
        return null
    }

    return (
        <aside className={classNames('modal', { 'is-open': isOpen })}>
            <div className="modal_overlay" onClick={handleClickClose}></div>
            <div className="modal_panel">
                <ModalComponent data={data} handleClickClose={handleClickClose} handleModalSubmit={handleModalSubmit} />
            </div>
        </aside>
    )
}

Modal.propTypes = {
    modalId: PropTypes.string,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    handleClickClose: PropTypes.func.isRequired,
    handleClickOpen: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
}

export default Modal
