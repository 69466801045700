export const baseUrl = 'clients'

/**
 * Retrieve all clients.
 */
export const FETCH_CLIENT_LIST_REQUEST = 'FETCH_CLIENT_LIST_REQUEST'
export const FETCH_CLIENT_LIST_SUCCESS = 'FETCH_CLIENT_LIST_SUCCESS'
export const FETCH_CLIENT_LIST_FAILURE = 'FETCH_CLIENT_LIST_FAILURE'
export const FETCH_CLIENT_LIST_PENDING = 'FETCH_CLIENT_LIST_PENDING'
export function fetchClients(config = {}) {
    return {
        promise: client => client.get(`${baseUrl}`, config),
        types: [
            FETCH_CLIENT_LIST_REQUEST,
            FETCH_CLIENT_LIST_SUCCESS,
            FETCH_CLIENT_LIST_FAILURE,
            FETCH_CLIENT_LIST_PENDING,
        ],
    }
}

/**
 * Create new client.
 */
export const CREATE_CLIENT_REQUEST = 'CREATE_CLIENT_REQUEST'
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS'
export const CREATE_CLIENT_FAILURE = 'CREATE_CLIENT_FAILURE'
export const CREATE_CLIENT_PENDING = 'CREATE_CLIENT_PENDING'
export function createClient(formData, config = {}) {
    return {
        promise: client => client.post(`${baseUrl}`, formData, config),
        types: [CREATE_CLIENT_REQUEST, CREATE_CLIENT_SUCCESS, CREATE_CLIENT_FAILURE, CREATE_CLIENT_PENDING],
    }
}
