import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'

import { updateProject } from '../../actions/projectActions'
import { formatFormNotifications } from '../../utils'
import { Button } from '../Button'
import { FormContainer, FormError, FormInput, FormItem, FormLabel, FormNotifications } from '../Form'
import { ModalActions, ModalHeading } from '../Modal'
import { Translation } from '../Translation'

const ModalProjectDatesDetails = ({ handleClickClose, data: project, dispatch }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = useCallback(
        ({ event, form: { validateFields } }) => {
            event.preventDefault()

            if (isLoading) {
                return false
            }

            validateFields(async (formErrors, formValues) => {
                if (!formErrors) {
                    setIsLoading(true)

                    dispatch(updateProject(project.id, formValues.projectForm))
                        .then(response => {
                            setIsLoading(false)
                            handleClickClose()
                            return response.data
                        })
                        .catch(error => {
                            setErrors(error.response.data)
                            setIsLoading(false)
                        })
                }
            })

            return false
        },
        [dispatch, handleClickClose, isLoading, project]
    )

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-planning-edit">
            <ModalHeading handleClickClose={handleClickClose}>
                <Translation value="modal.projectDates.title" />
            </ModalHeading>
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form, form: { getFieldError } }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormLabel inputId="projectForm.startDate">
                                    <Translation value="projectForm.date-begin" />
                                </FormLabel>
                                <FormInput
                                    id="projectForm.startDate"
                                    name="projectForm.startDate"
                                    form={form}
                                    type="date"
                                    fieldOptions={{
                                        initialValue: project.startDate
                                            ? moment(project.startDate).format('YYYY-MM-DD')
                                            : '',
                                    }}
                                />
                                <FormError>{getFieldError('projectForm.startDate')}</FormError>
                            </FormItem>
                            <FormItem>
                                <FormLabel inputId="projectForm.launchDate">
                                    <Translation value="projectForm.date-launch" />
                                </FormLabel>
                                <FormInput
                                    id="projectForm.launchDate"
                                    name="projectForm.launchDate"
                                    form={form}
                                    type="date"
                                    fieldOptions={{
                                        initialValue: project.launchDate
                                            ? moment(project.launchDate).format('YYYY-MM-DD')
                                            : '',
                                    }}
                                />
                                <FormError>{getFieldError('projectForm.launchDate')}</FormError>
                            </FormItem>
                            <ModalActions>
                                <Button
                                    className={classNames({ 'is-loading': isLoading })}
                                    hasWrapper
                                    option={['blue', 'small', 'block', 'spinner']}
                                    type="submit"
                                >
                                    <Translation value="modal.action-save" />
                                </Button>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </div>
    )
}

export default connect()(ModalProjectDatesDetails)
