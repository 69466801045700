import moment from 'moment'

import { REFRESH_MILESTONE_REQUEST } from '../actions/milestoneActions'

const initialState = {
    refreshTimestamp: moment().format(),
}

export function currentMilestone(state = initialState, action) {
    switch (action.type) {
        case REFRESH_MILESTONE_REQUEST:
            return {
                refreshTimestamp: moment().format(),
            }

        default:
            return state
    }
}
