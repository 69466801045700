import PropTypes from 'prop-types'
// import React, { useRef } from 'react'
// import SignatureCanvas from 'react-signature-canvas'

// import { Translation } from '../Translation'

import './_signature.scss'

const Signature = ({ onChange }) => {
    // const signatureCanvasRef = useRef(null)
    // const handleSignatureCanvasOnEnd = someting => {
    //     onChange(signatureCanvasRef.current.toDataURL('image/png'))
    // }

    return null
    // return (
    //     <div className="signature">
    //         <div className="signature_label">
    //             <Translation value="milestoneDetails.signatureLabel" />
    //         </div>
    //         <SignatureCanvas
    //             penColor="#000"
    //             canvasProps={{ className: 'signature_canvas' }}
    //             ref={signatureCanvasRef}
    //             onEnd={handleSignatureCanvasOnEnd}
    //         />
    //     </div>
    // )
}

Signature.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default Signature
