import React from 'react'
import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import './_modalActions.scss'

const ModalActionsItem = ({ children, ...classProps }) => {
    return <div className={renderClasses('modalActions_item', classProps)}>{children}</div>
}

ModalActionsItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

export default ModalActionsItem
