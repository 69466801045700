import {
    FETCH_EMPLOYEE_LIST_REQUEST,
    FETCH_EMPLOYEE_LIST_SUCCESS,
    FETCH_EMPLOYEE_LIST_FAILURE,
} from '../actions/employeeActions'

const initialState = {
    entities: [],
    errors: null,
    isLoading: true,
    wasQueried: false,
}

export function employees(state = initialState, action) {
    switch (action.type) {
        case FETCH_EMPLOYEE_LIST_REQUEST:
            return {
                ...state,
                errors: null,
            }
        case FETCH_EMPLOYEE_LIST_SUCCESS:
            return {
                ...state,
                entities: action.result.data,
                errors: null,
                isLoading: false,
                wasQueried: true,
            }
        case FETCH_EMPLOYEE_LIST_FAILURE:
            return {
                ...initialState,
                errors: action.error.response.data,
                isLoading: false,
                wasQueried: true,
            }
        default:
            return state
    }
}
