import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { renderClasses, renderRoute } from '../../utils'
import { Icon } from '../Icon'

import './_button.scss'

const ButtonCircle = ({
    children,
    htmlAttrs = {},
    href = null,
    icon = null,
    isBlank = false,
    route = null,
    onClick = null,
    hash,
    ...classProps
}) => {
    const classes = renderClasses('button-circle', classProps)
    const blankAtts = isBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {}

    if (route !== null) {
        return (
            <RouterLink
                className={classes}
                to={{
                    pathname: renderRoute(route),
                    hash: hash,
                }}
                {...htmlAttrs}
            >
                <Icon name={icon} />
            </RouterLink>
        )
    } else if (href !== null) {
        return (
            <a href={href} className={classes} {...blankAtts} {...htmlAttrs}>
                <Icon name={icon} />
            </a>
        )
    } else if (onClick !== null) {
        return (
            <button className={classes} onClick={onClick} type="button" {...htmlAttrs}>
                <Icon name={icon} />
            </button>
        )
    } else {
        return (
            <span className={classes} {...htmlAttrs}>
                <Icon name={icon} />
            </span>
        )
    }
}

ButtonCircle.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.string,
    isBlank: PropTypes.bool,
    route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default ButtonCircle
