import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import React from 'react'

import { renderClasses } from '../../utils'

import './_formTextarea.scss'

const FormTextarea = ({ fieldOptions = {}, form, id = null, name, placeholder, ...classProps }) => {
    return form.getFieldDecorator(
        name,
        fieldOptions
    )(<textarea id={id} name={name} className={renderClasses('formTextarea', classProps)} placeholder={placeholder} />)
}

FormTextarea.propTypes = {
    form: formShape,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.object,
    placeholder: PropTypes.string,
}

export default FormTextarea
