import {
    FETCH_CLIENT_LIST_REQUEST,
    FETCH_CLIENT_LIST_SUCCESS,
    FETCH_CLIENT_LIST_FAILURE,
    CREATE_CLIENT_REQUEST,
    CREATE_CLIENT_SUCCESS,
    CREATE_CLIENT_FAILURE,
} from '../actions/clientActions'

const initialState = {
    entities: [],
    errors: null,
    isLoading: true,
    wasQueried: false,
    newClient: null,
}

export function clients(state = initialState, action) {
    switch (action.type) {
        /**
         * Fetch client list.
         */
        case FETCH_CLIENT_LIST_REQUEST:
            return {
                ...state,
                errors: null,
            }
        case FETCH_CLIENT_LIST_SUCCESS:
            return {
                ...state,
                entities: action.result.data,
                errors: null,
                isLoading: false,
                wasQueried: true,
            }
        case FETCH_CLIENT_LIST_FAILURE:
            return {
                ...initialState,
                errors: action.error.response.data,
                isLoading: false,
                wasQueried: true,
            }

        /**
         * Add new client to list entites.
         */
        case CREATE_CLIENT_REQUEST:
            return {
                ...state,
            }
        case CREATE_CLIENT_SUCCESS:
            return {
                ...state,
                newClient: action.result.data,
                entities: [...state.entities, action.result.data],
            }
        case CREATE_CLIENT_FAILURE:
            return {
                ...state,
            }

        default:
            return state
    }
}
