import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import './_quickNav.scss'

const QuickNav = ({ items = [] }) => {
    useEffect(() => {
        if (window.location.hash !== '') {
            requestAnimationFrame(() => {
                document.querySelector(window.location.hash).scrollIntoView({
                    behavior: 'smooth',
                })
            })
        }
    })

    const scrollTo = (e, id) => {
        const $section = document.querySelector(id)

        if ($section) {
            window.scrollTo({
                top: $section.offsetTop - 100,
                behavior: 'smooth',
            })
        }
    }

    return (
        <nav className="quickNav u-anim -delay-2">
            <ul className="quickNav_list">
                {items
                    .filter(item => item.displayed)
                    .map(item => (
                        <li className="quickNav_list_item" key={`quicknav-${item.scrollTo}`}>
                            <button onClick={e => scrollTo(e, item.scrollTo)} type="button">
                                {item.label}
                            </button>
                        </li>
                    ))}
            </ul>
        </nav>
    )
}

QuickNav.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
            scrollTo: PropTypes.string.isRequired,
            displayed: PropTypes.bool,
        })
    ).isRequired,
}

export default QuickNav
