import React from 'react'

import { renderClasses } from '../../utils'

import './_cardFooter.scss'

const CardFooter = ({ children, ...classProps }) => (
    <div className={renderClasses('cardFooter', classProps)}>{children}</div>
)

export default CardFooter
