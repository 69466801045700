import React from 'react'

import { renderClasses } from '../../utils'

import './_section.scss'

const Section = ({ children, ...classProps }) => (
    <section className={renderClasses('section', classProps)}>{children}</section>
)

export default Section
