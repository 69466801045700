import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { renderRoute, userCanDo } from '../../utils'
import { Translation } from '../Translation'

import defaultAvatar from '../../assets/images/person.svg'
import './_userMenu.scss'

const UserMenu = ({ handleLogout, handleToggleIsOpen, isOpen, project = null, user, languageRoutes }) => {
    const onClickOutsideHandler = useCallback(() => {
        if (isOpen) {
            handleToggleIsOpen()
        }
    }, [handleToggleIsOpen, isOpen])

    useEffect(() => {
        window.addEventListener('click', onClickOutsideHandler)

        return () => {
            window.removeEventListener('click', onClickOutsideHandler)
        }
    })

    const hasAvatar = !_.isEmpty(user.avatarUrl.url)
    const avatar = hasAvatar ? user.avatarUrl.url : defaultAvatar

    const apiUrl = new URL(`${process.env.REACT_APP_API_URL}/`)
    const cmsUrl = `${apiUrl.origin}/admin`
    const projectUrl =
        typeof project.id !== 'undefined'
            ? `${cmsUrl}/object/edit?obj_type=station/models/projects/workspace/project&obj_id=${project.id}`
            : cmsUrl
    return (
        <div className={classNames('userMenu', { 'is-open': isOpen })}>
            <button className="userMenu_toggle" onClick={handleToggleIsOpen}>
                <img
                    className={classNames('userMenu_toggle_avatar', { '-default': !hasAvatar })}
                    src={avatar}
                    alt={user.fullName}
                />
                <span className="userMenu_toggle_label">
                    <Translation value="header.myMenu" />
                </span>
            </button>
            <div className="userMenu_nav">
                <p className="userMenu_name">{user.fullName}</p>
                <ul className="userMenu_list">
                    {userCanDo(user, 'charcoal') && (
                        <li className="userMenu_list_item">
                            <a className="userMenu_list_link" href={projectUrl} rel="noreferrer" target="_blank">
                                <Translation value="header.charcoalAccess" />
                            </a>
                        </li>
                    )}
                    {user.projectIds.length > 0 && (
                        <li className="userMenu_list_item">
                            <RouterLink className="userMenu_list_link" to={renderRoute('project-list')}>
                                {user.projectIds.length > 1 || user.isEmployee ? (
                                    <Translation value="header.myProjects" />
                                ) : (
                                    <Translation value="header.myProject" />
                                )}
                            </RouterLink>
                        </li>
                    )}
                    {user.projectIds.length === 1 && userCanDo(user, 'project.create') && (
                        <li className="userMenu_list_item">
                            <RouterLink className="userMenu_list_link" to={renderRoute('project-create')}>
                                <Translation value="header.createProject" />
                            </RouterLink>
                        </li>
                    )}
                    <li className="userMenu_list_item">
                        <RouterLink className="userMenu_list_link" to={renderRoute('user-profile')}>
                            <Translation value="header.myProfile" />
                        </RouterLink>
                    </li>
                    {_.map(languageRoutes, (languageRoute, index) => (
                        <li className="userMenu_list_item" key={index}>
                            <a className="userMenu_list_link" href={languageRoute.route}>
                                <Translation value={`header.lang.${languageRoute.lang}`} />
                            </a>
                        </li>
                    ))}
                    <li className="userMenu_list_item">
                        <button type="button" className="userMenu_list_link -red" onClick={handleLogout}>
                            <Translation value="header.logout" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    )
}

UserMenu.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    handleToggleIsOpen: PropTypes.func.isRequired,
    languages: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
    project: PropTypes.object,
    user: PropTypes.object.isRequired,
}

export default UserMenu
