export const OPEN_HELP = 'OPEN_HELP'
export const CLOSE_HELP = 'CLOSE_HELP'
export const TOGGLE_HELP = 'TOGGLE_HELP'

export const SET_DISMISSED_POINTERS = 'SET_DISMISSED_POINTERS'

export const NEXT_TUTORIAL_STEP = 'NEXT_TUTORIAL_STEP'
export const ACTIVATE_TUTORIAL = 'ACTIVATE_TUTORIAL'
export const DISABLE_TUTORIAL = 'DISABLE_TUTORIAL'

export const OPEN_MODAL = 'OPEN_MODAL'
export const SUBMIT_MODAL = 'SUBMIT_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const PUSH_NOTIFICATION = 'PUSH_NOTIFICATION'
