import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import './_formNotifications.scss'

const FormNotifications = ({ hasErrors = false, hasSuccess = false, items = [] }) => {
    const listClass = classNames('formNotifications', { 'has-errors': hasErrors }, { 'has-success': hasSuccess })
    if (_.isArray(items) && items.length !== 0) {
        return (
            <ul className={listClass}>
                {items.map((item, index) => (
                    <li className="formNotifications_item" key={index}>
                        {item.message}
                    </li>
                ))}
            </ul>
        )
    } else {
        return null
    }
}

FormNotifications.propTypes = {
    hasErrors: PropTypes.bool,
    hasSuccess: PropTypes.bool,
    items: PropTypes.array,
}

export default FormNotifications
