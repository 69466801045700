import { apiClient } from './apiClient'
import { projectApiUrl } from './projectActions'

export const baseUrl = 'milestones'

export const REFRESH_MILESTONE_REQUEST = 'REFRESH_MILESTONE_REQUEST'

function milestonesApiUrl(projectId) {
    return `${projectApiUrl(projectId)}/${baseUrl}`
}

function milestoneApiUrl(projectId, milestoneId) {
    return `${milestonesApiUrl(projectId)}/${milestoneId}`
}

/**
 * Retrieve all milestones from a project ID.
 */
export function fetchMilestones(projectId, config = {}) {
    return apiClient.get(milestonesApiUrl(projectId), config)
}

/**
 * Create a Milestone from an project ID.
 */
export function createMilestone(projectId, formData, config = {}) {
    return apiClient.post(milestonesApiUrl(projectId), formData, config)
}

/**
 * Retrieve a milestone from an ID.
 */
export function fetchMilestone(projectId, milestoneId, config = {}) {
    return apiClient.get(milestoneApiUrl(projectId, milestoneId), config)
}

/**
 * Update a milestone from an ID.
 */
export function updateMilestone(projectId, milestoneId, formData, config = {}) {
    return apiClient.patch(milestoneApiUrl(projectId, milestoneId), formData, config)
}

/**
 * Clear cache for milestone list
 */
export function clearMilestonesCache(projectId) {
    return apiClient.clearCache(milestonesApiUrl(projectId))
}

/**
 * Clear cache for a specific milestone
 */
export function clearMilestoneCache(projectId, milestoneId) {
    return apiClient.clearCache(milestoneApiUrl(projectId, milestoneId))
}
