import React, { useState, useEffect, useRef, useCallback } from 'react'
import axios from 'axios'
import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { updateSelf } from '../../actions/userActions'
import { SET_DISMISSED_POINTERS, ACTIVATE_TUTORIAL } from '../../actions/viewActions'
import { LisaStep } from '../Lisa'
import { translate } from '../../utils'

import './_lisa.scss'

const Lisa = ({ steps, dismissedPointers, dispatch }) => {
    const signal = axios.CancelToken.source()
    const audio = new Audio()
    const timeoutRef = useRef(null)
    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [isActive, setIsActive] = useState(false)

    const goToNextStep = useCallback(
        (isFirstStep = false) => {
            if (isFirstStep === false) {
                setCurrentStepIndex(currentStepIndex + 1)
            }

            if (typeof steps[currentStepIndex + 1] !== 'undefined') {
                timeoutRef.current = setTimeout(() => {
                    goToNextStep()
                }, 4000)
            }
        },
        [currentStepIndex, steps]
    )

    useEffect(() => {
        setTimeout(() => {
            setIsActive(true)
            if (timeoutRef.current === null) {
                timeoutRef.current = setTimeout(() => goToNextStep(), 4500)
            }
        }, 10)
        return () => {
            clearTimeout(timeoutRef.current)
            timeoutRef.current = null
        }
    }, [goToNextStep, timeoutRef])

    const handleClickSelectChoice = event => {
        switch (event.currentTarget.getAttribute('data-action')) {
            case 'skipTutorial':
                launch(false)
                break

            case 'startTutorial':
                launch(true)
                break
            case 'start':
                goToNextStep()
                break
            default:
                break
        }
    }

    const launch = startTutorial => {
        setIsActive(false)

        setTimeout(() => {
            if (!startTutorial) {
                const pointers = _.uniq(_.compact([...dismissedPointers, 'introduction']))

                dispatch(updateSelf({ dismissedPointers: pointers }, { cancelToken: signal.token }))
                    .then(data => {
                        dispatch({
                            type: SET_DISMISSED_POINTERS,
                            pointers,
                        })
                    })
                    .catch(error => {
                        console.log(`User's dismissed pointers couldn't be updated.`)
                    })
            } else {
                dispatch({
                    type: ACTIVATE_TUTORIAL,
                })
            }
        }, 900)
    }

    if (steps[currentStepIndex].audio !== undefined && isActive) {
        audio.src = translate(steps[currentStepIndex].audio)
        audio.play()
    }

    return (
        <div className={classNames('lisa', { 'is-active': isActive })}>
            <div className="lisa_content">
                <LisaStep
                    currentStepIndex={currentStepIndex}
                    onSelectChoice={handleClickSelectChoice}
                    step={steps[currentStepIndex]}
                    hasLisaActive={isActive}
                />
            </div>
        </div>
    )
}

Lisa.propTypes = {
    steps: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
    dismissedPointers: state.view.pointers.dismissed,
})

export default connect(mapStateToProps)(Lisa)
