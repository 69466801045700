export const baseUrl = 'employees'

/**
 * Retrieve all employees.
 */
export const FETCH_EMPLOYEE_LIST_REQUEST = 'FETCH_EMPLOYEE_LIST_REQUEST'
export const FETCH_EMPLOYEE_LIST_SUCCESS = 'FETCH_EMPLOYEE_LIST_SUCCESS'
export const FETCH_EMPLOYEE_LIST_FAILURE = 'FETCH_EMPLOYEE_LIST_FAILURE'
export const FETCH_EMPLOYEE_LIST_PENDING = 'FETCH_EMPLOYEE_LIST_PENDING'
export function fetchEmployees(config = {}) {
    return {
        promise: client => client.get(`${baseUrl}`, config),
        types: [
            FETCH_EMPLOYEE_LIST_REQUEST,
            FETCH_EMPLOYEE_LIST_SUCCESS,
            FETCH_EMPLOYEE_LIST_FAILURE,
            FETCH_EMPLOYEE_LIST_PENDING,
        ],
    }
}
