import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import './_formItem.scss'

const FormItem = ({ children, ...classProps }) => (
    <div className={renderClasses('formItem', classProps)}>{children}</div>
)

FormItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

export default FormItem
