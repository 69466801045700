import { apiClient } from './apiClient'

export const baseUrl = 'users'

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'

/**
 * Create a new login session, authenticating a specific user.
 */
export const CREATE_USER_LOGIN_REQUEST = 'CREATE_USER_LOGIN_REQUEST'
export const CREATE_USER_LOGIN_SUCCESS = 'CREATE_USER_LOGIN_SUCCESS'
export const CREATE_USER_LOGIN_FAILURE = 'CREATE_USER_LOGIN_FAILURE'
export const login = (formData, config = {}) => {
    return {
        promise: client => client.post(`${baseUrl}/login`, formData, config),
        types: [CREATE_USER_LOGIN_REQUEST, CREATE_USER_LOGIN_SUCCESS, CREATE_USER_LOGIN_FAILURE],
    }
}

/**
 * Delete a login session, unauthenticating the authenticated user.
 */
export const DELETE_USER_LOGIN_REQUEST = 'DELETE_USER_LOGIN_REQUEST'
export const DELETE_USER_LOGIN_SUCCESS = 'DELETE_USER_LOGIN_SUCCESS'
export const DELETE_USER_LOGIN_FAILURE = 'DELETE_USER_LOGIN_FAILURE'
export const logout = (config = {}) => {
    return {
        promise: client =>
            new Promise(resolve => {
                resolve()
            }),
        types: [DELETE_USER_LOGIN_REQUEST, DELETE_USER_LOGIN_SUCCESS, DELETE_USER_LOGIN_FAILURE],
    }
}

/**
 * Retrieve the authenticated user's profile.
 */
export const FETCH_USER_SELF_REQUEST = 'FETCH_USER_SELF_REQUEST'
export const FETCH_USER_SELF_SUCCESS = 'FETCH_USER_SELF_SUCCESS'
export const FETCH_USER_SELF_FAILURE = 'FETCH_USER_SELF_FAILURE'
export function fetchSelf(config = {}) {
    return {
        promise: client => client.get(`${baseUrl}/self`, config),
        types: [FETCH_USER_SELF_REQUEST, FETCH_USER_SELF_SUCCESS, FETCH_USER_SELF_FAILURE],
    }
}

/**
 * Create a new reset-password token and sends a reset password link to user's email.
 */
export const createLostPasswordToken = (formData, config = {}) => {
    return apiClient.post(`${baseUrl}/password/email`, formData, config)
}

/**
 * Consume a reset-password token and change a user's password.
 */
export const resetPassword = (formData, config = {}) => {
    return apiClient.post(`${baseUrl}/password/reset`, formData, config)
}

/**
 * Initiate the account activation via an email token.
 */
export const activateAccount = (formData, config = {}) => {
    return apiClient.post(`${baseUrl}/account/confirm`, formData, config)
}

/**
 * Update the authenticated user's profile.
 */
export const UPDATE_USER_SELF_REQUEST = 'UPDATE_USER_SELF_REQUEST'
export const UPDATE_USER_SELF_SUCCESS = 'UPDATE_USER_SELF_SUCCESS'
export const UPDATE_USER_SELF_FAILURE = 'UPDATE_USER_SELF_FAILURE'
export function updateSelf(formData, config = {}) {
    return {
        promise: client => client.patch(`${baseUrl}/self`, formData, config),
        types: [UPDATE_USER_SELF_REQUEST, UPDATE_USER_SELF_SUCCESS, UPDATE_USER_SELF_FAILURE],
    }
}

/**
 * Update the authenticated user's profile by adding a project to their favorites.
 */
export function createFavoriteProject(formData, config = {}) {
    return apiClient.post(`${baseUrl}/self/favorite-projects`, formData, config)
}

/**
 * Update the authenticated user's profile by removing a project from their favorites.
 */
export function removeFavoriteProject(projectId, formData, config = {}) {
    return apiClient.delete(`${baseUrl}/self/favorite-projects/${projectId}`, formData, config)
}

/**
 * Update the authenticated user's email.
 */
export function updateSelfEmail(formData, config = {}) {
    return apiClient.patch(`${baseUrl}/self/email`, formData, config)
}

/**
 * Update the authenticated user's password.
 */
export function updateSelfPassword(formData, config = {}) {
    return apiClient.patch(`${baseUrl}/self/password`, formData, config)
}

/**
 * Clear cache for authenticated user.
 */
export function clearSelfCache() {
    return apiClient.clearCache(`${baseUrl}/self`)
}
