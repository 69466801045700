import PropTypes from 'prop-types'
import { createForm } from 'rc-form'
import React, { useCallback } from 'react'

import { Form } from '../Form'

const FormContainer = ({ form, formId, fieldsValue, handleSubmit, render, resetOnSubmit = false, ...restProps }) => {
    const submitCallback = useCallback(
        event => {
            handleSubmit({ event, form, formId, resetOnSubmit })
        },
        [form, formId, handleSubmit, resetOnSubmit]
    )

    return (
        <Form onSubmit={submitCallback} {...restProps}>
            {render({ form })}
        </Form>
    )
}

FormContainer.propTypes = {
    render: PropTypes.func.isRequired,
}

export default createForm()(FormContainer)
