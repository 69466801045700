import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'
import { Card } from '../Card'
import { Link } from '../Link'
import { Translation } from '../Translation'

import defaultAvatar from '../../assets/images/person.svg'
import './_cardContact.scss'

const CardContact = ({ contact, ...classProps }) => (
    <Card option={['sides', 'no-margin']} className={renderClasses('cardContact', classProps)}>
        <div className="cardContact_wrap">
            <div className="cardContact_image">
                <img src={contact.avatarUrl.url ? contact.avatarUrl.url : defaultAvatar} alt={contact.fullName} />
            </div>
            <div className="cardContact_text">
                <p>{contact.fullName}</p>
                <p>
                    <Translation value={contact.title} />
                </p>
                {contact.emailAddress && (
                    <Link option={['blue']} href={`mailto:${contact.emailAddress}`}>
                        {contact.emailAddress}
                    </Link>
                )}
                <br />
                {contact.telephoneNumber && (
                    <Link option={['blue']} href={`tel:${contact.telephoneNumber}`}>
                        {contact.telephoneNumber}
                    </Link>
                )}
            </div>
        </div>
    </Card>
)

CardContact.propTypes = {
    contact: PropTypes.object.isRequired,
}

export default CardContact
