import PropTypes from 'prop-types'
import React from 'react'

const radius = 175
const diameter = Math.round(Math.PI * radius * 2)
const getOffset = (val = 0) => Math.round(((100 - Math.min(val, 100)) / 100) * diameter)

const Circle = ({
    animate = true,
    animationDuration = '1s',
    bgColor = '#ecedf0',
    lineWidth = '25',
    onAnimationEnd = () => {},
    percentSpacing = 10,
    progress = 0,
    progressColor = 'rgb(76, 154, 255)',
    showPercentage = true,
    showPercentageSymbol = true,
    size = '100',
    responsive = false,
    roundedStroke = false,
    textColor = '#6b778c',
    textStyle = { font: 'bold 4rem Helvetica, Arial, sans-serif' },
}) => {
    const strokeDashoffset = getOffset(progress)
    const transition = animate ? `stroke-dashoffset ${animationDuration} ease-out` : undefined
    const strokeLinecap = roundedStroke ? 'round' : 'butt'
    const svgSize = responsive ? '100%' : size

    const text = () => {
        if (!showPercentage) return

        return (
            <text
                style={textStyle}
                fill={textColor}
                x={radius}
                y={radius}
                textAnchor="middle"
                dominantBaseline="central"
            >
                {progress}
                {showPercentageSymbol && <tspan dx={percentSpacing}>%</tspan>}
            </text>
        )
    }

    return (
        <svg width={svgSize} height={svgSize} viewBox="-25 -25 400 400">
            <circle stroke={bgColor} cx="175" cy="175" r="175" strokeWidth={lineWidth} fill="none" />
            <circle
                stroke={progressColor}
                transform="rotate(-90 175 175)"
                cx="175"
                cy="175"
                r="175"
                strokeDasharray="1100"
                strokeWidth={lineWidth}
                strokeDashoffset="1100"
                strokeLinecap={strokeLinecap}
                fill="none"
                style={{ strokeDashoffset, transition }}
                onTransitionEnd={onAnimationEnd}
            />
            {text()}
        </svg>
    )
}

Circle.propTypes = {
    animate: PropTypes.bool,
    animationDuration: PropTypes.string,
    bgColor: PropTypes.string,
    lineWidth: PropTypes.string,
    onAnimationEnd: PropTypes.func,
    percentSpacing: PropTypes.number,
    progress: PropTypes.number,
    progressColor: PropTypes.string,
    showPercentage: PropTypes.bool,
    showPercentageSymbol: PropTypes.bool,
    size: PropTypes.string,
    responsive: PropTypes.bool,
    roundedStroke: PropTypes.bool,
    textColor: PropTypes.string,
    textStyle: PropTypes.object,
}

export default Circle
