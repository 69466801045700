import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { userCanDo } from '../../utils'

import { Card } from '../Card'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'
import { LinkButton } from '../Link'
import { ModalContainer } from '../Modal'
import { Translation } from '../Translation'

import '../CardList/_cardList.scss'
import './_cardListAccordion.scss'

const AVAILABLE_ICONS = [
    'url',
    'file',
    'harvest',
    'teamwork-project',
    'teamwork-desk',
    'bitbucket',
    'github',
    'gitlab',
    'dropbox',
    'google-drive',
    'slickplan',
    'invision',
    'sketch',
    'figma',
]

const CardListAccordion = ({
    showButtons = false,
    footerCta = null,
    heading = null,
    group = null,
    items = [],
    user,
}) => {
    const isSingle = items.length === 1

    // State
    const [show, setShow] = useState(false)
    const [contentHeight, setContentHeight] = useState(0)

    // Ref
    const $content = useRef(null)
    useEffect(() => {
        const $target = $content.current
        if ($target) {
            setContentHeight($target.offsetHeight + 1)
        }
    }, [$content, show, items])

    // Get Content Height
    const contentHeightStyle = { '--content-height': `${contentHeight}px` }

    // Toggle Accordion
    const clickHandler = e => {
        e.preventDefault()
        setShow(!show)
    }

    // Check local storage on mounted
    useEffect(() => {
        const storageCardListAccordions =
            localStorage.getItem('station-card-list-accordion') &&
            JSON.parse(localStorage.getItem('station-card-list-accordion'))
        if (storageCardListAccordions) {
            storageCardListAccordions[group.id] && setShow(storageCardListAccordions[group.id].show)
        }
    }, [group.id])

    // Update local storage when show property change
    useEffect(() => {
        const storageCardListAccordions = JSON.parse(localStorage.getItem('station-card-list-accordion')) || {}
        if (!storageCardListAccordions[group.id]) {
            storageCardListAccordions[group.id] = {}
        }
        storageCardListAccordions[group.id].show = show
        localStorage.setItem('station-card-list-accordion', JSON.stringify(storageCardListAccordions))
    }, [group.id, show])

    return (
        <div style={contentHeightStyle}>
            <Card
                className={classNames('cardList -accordion', { '-single': isSingle }, { 'is-deploy': show })}
                option={['small']}
            >
                {heading && (
                    <Layout className="cardList_heading -flex -middle">
                        <LayoutItem utility={['1/2']}>
                            <Layout className="-flex -middle">
                                <div className="cardList_accordion-icon">
                                    <Icon name="arrow-down-small" />
                                </div>
                                <Heading className="cardList_title" el="h3" option={['h6']}>
                                    <Translation value={heading} />
                                </Heading>
                                <button className="cardList_toggler" title="Toggle content" onClick={clickHandler}>
                                    <span className="u-screen-reader-text">
                                        <Translation value="common.open" />
                                    </span>
                                </button>
                            </Layout>
                        </LayoutItem>
                        {userCanDo(user, 'project.update') && showButtons && (
                            <LayoutItem className="cardList_actions" utility={['1/2', 'text-right']}>
                                <ModalContainer
                                    render={({ handleClickOpen }) => (
                                        <React.Fragment>
                                            <button
                                                className="cardList_edit"
                                                onClick={handleClickOpen}
                                                data-item-id="resourceDetails"
                                                data-item-data={`${JSON.stringify({
                                                    group: group,
                                                })}`}
                                                type="button"
                                            >
                                                <Icon name="plus" onClick={handleClickOpen} />
                                            </button>

                                            {items.length > 0 && (
                                                <span className="u-margin-tiny-x">
                                                    <button
                                                        className="cardList_edit"
                                                        onClick={handleClickOpen}
                                                        data-item-id="resourceList"
                                                        data-item-data={`${JSON.stringify({
                                                            group: group,
                                                            items: items,
                                                        })}`}
                                                        type="button"
                                                    >
                                                        <Icon name="edit-simple" onClick={handleClickOpen} />
                                                    </button>
                                                </span>
                                            )}
                                        </React.Fragment>
                                    )}
                                />
                            </LayoutItem>
                        )}
                    </Layout>
                )}
                <div className="cardList_container">
                    <ul className="cardList_list" ref={$content}>
                        {items.map(item => {
                            const iconName = AVAILABLE_ICONS.includes(item.type.icon)
                                ? `app-${item.type.icon}`
                                : 'close'
                            return (
                                <li key={item.id}>
                                    <a
                                        className={classNames('cardList_link')}
                                        href={item.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <span className="cardList_main">
                                            <span className="cardList_icon">
                                                <Icon name={iconName} />
                                            </span>

                                            <Heading el="span" option={['h3']}>
                                                {item.name ? <Translation value={item.name} /> : item.url}
                                            </Heading>
                                        </span>
                                        <span className="cardList_category">
                                            {item.milestone && (
                                                <React.Fragment>
                                                    <Translation value={item.milestone.name} /> <br />
                                                </React.Fragment>
                                            )}
                                            <span className="cardList_date">
                                                <Translation value="resources.added" />{' '}
                                                {moment(item.createdAt).format('MMM DD, YYYY')}
                                            </span>
                                        </span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {footerCta !== null && (
                    <div className="cardLink_help">
                        <LinkButton
                            htmlAttrs={{
                                'data-item-id': footerCta.id,
                            }}
                            onClick={footerCta.onClick}
                            option={['blue']}
                        >
                            <Translation value={footerCta.label} />
                        </LinkButton>
                    </div>
                )}
            </Card>
        </div>
    )
}
CardListAccordion.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    items: PropTypes.array,
    footerCta: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }),
    showButtons: PropTypes.bool,
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(CardListAccordion)
