import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchProjects } from '../../actions/projectActions'
import { LoadingError } from '../LoadingError'

const ProjectListContainer = ({ dispatch, entities, errors, isLoading, render, wasQueried }) => {
    useEffect(() => {
        if (!wasQueried) {
            dispatch(fetchProjects())
        }
    }, [dispatch, wasQueried])

    if (isLoading) {
        return null
    } else if (errors !== null) {
        console.warn(errors)
        return <LoadingError />
    } else {
        return render({ projects: entities })
    }
}

const mapStateToProps = ({ projects }) => ({
    ...projects,
})

export default connect(mapStateToProps)(ProjectListContainer)
