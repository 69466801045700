import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'

import { updateProject } from '../../actions/projectActions'
import { formatFormNotifications } from '../../utils'
import { Button } from '../Button'
import { FormContainer, FormError, FormItem, FormNotifications } from '../Form'
import { ModalActions, ModalActionsItem, ModalHeading } from '../Modal'
import { TeamMemberContainer, TeamMemberSelector } from '../TeamMember'
import { Translation } from '../Translation'

const ModalProjectTeamDetails = ({ data: project, dispatch, handleClickClose }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = useCallback(
        ({ event, form: { resetFields, validateFields } }) => {
            event.preventDefault()

            if (isLoading) {
                return false
            }

            validateFields(async (formErrors, formValues) => {
                if (!formErrors) {
                    setIsLoading(true)

                    dispatch(updateProject(project.id, formValues.projectForm))
                        .then(response => {
                            setIsLoading(false)
                            handleClickClose()
                            resetFields()
                        })
                        .catch(error => {
                            setErrors(error.response.data)
                            setIsLoading(false)
                        })
                }
            })
            return false
        },
        [dispatch, handleClickClose, isLoading, project]
    )

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <div className="modal-team">
            <ModalHeading handleClickClose={handleClickClose}>
                <Translation value="modal.employees.title" />
            </ModalHeading>
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormError>{form.getFieldError('projectForm.employees')}</FormError>
                                <TeamMemberContainer
                                    render={({ employees }) => (
                                        <TeamMemberSelector
                                            employees={employees}
                                            initialEmployeesValue={project.employees.map(item => item.id)}
                                            initialProducersValue={project.producers.map(item => item.id)}
                                            initialContactsValue={project.contacts.map(item => item.id)}
                                            form={form}
                                        />
                                    )}
                                />
                            </FormItem>
                            <ModalActions>
                                <ModalActionsItem>
                                    <Button
                                        className={classNames({ 'is-loading': isLoading })}
                                        hasWrapper
                                        option={['blue', 'small', 'block', 'spinner']}
                                        type="submit"
                                    >
                                        <Translation value="modal.action-save" />
                                    </Button>
                                </ModalActionsItem>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </div>
    )
}

ModalProjectTeamDetails.propTypes = {
    handleClickClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
}

export default connect()(ModalProjectTeamDetails)
