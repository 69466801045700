import React from 'react'

import { renderClasses } from '../../utils'

import './_timeline.scss'

const Timeline = ({ children, ...classProps }) => (
    <div className={renderClasses('timeline', classProps)}>
        <div className="timeline_main"></div>
        {children}
    </div>
)

export default Timeline
