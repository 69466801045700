import React from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { userCanDo } from '../utils'
import { Button } from '../components/Button'
import { Card, CardHeading } from '../components/Card'
import { CardContact } from '../components/CardContact'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { InfoText } from '../components/InfoText'
import { Layout, LayoutItem } from '../components/Layout'
import { ModalContainer } from '../components/Modal'
import { Panel, PanelContainer, PanelFooter } from '../components/Panel'
import { ProjectContainer } from '../components/Project'
import { Section, SectionHeader } from '../components/Section'
import { SupportNav } from '../components/SupportNav'
import { Translation } from '../components/Translation'
import { View } from '../components/View'

const ContactListView = ({ user }) => {
    const { pathname } = useLocation()
    const { projectId } = useParams()
    return (
        <div>
            <ProjectContainer
                projectId={projectId}
                render={({ project }) => (
                    <View>
                        <Layout>
                            <LayoutItem option={['panelSmall']}>
                                <Panel option={['small']}>
                                    <PanelContainer>
                                        <Section>
                                            <div className="panel_header">
                                                <Heading el="h1" option={['h1', 'delay1']} utility={['anim']}>
                                                    <Translation value="support.header" />
                                                </Heading>
                                            </div>
                                            <SupportNav pathname={pathname} project={project} />
                                        </Section>
                                    </PanelContainer>
                                    <PanelFooter project={project} option={['delay3']} utility={['anim']} />
                                </Panel>
                            </LayoutItem>
                            <LayoutItem option={['contentSmall']}>
                                <Container>
                                    <Section>
                                        <SectionHeader>
                                            <Layout>
                                                <LayoutItem utility={['5/8@from-small']}>
                                                    <Heading el="h2" option={['h1', 'delay1']} utility={['anim']}>
                                                        <Translation value="contactList.pageHeader" />
                                                    </Heading>
                                                </LayoutItem>
                                                <LayoutItem utility={['3/8@from-small']}>
                                                    <InfoText option={['delay1']} utility={['anim']}>
                                                        <Translation isHtml value="contactList.infoText" />
                                                    </InfoText>
                                                </LayoutItem>
                                            </Layout>
                                        </SectionHeader>
                                        <Card option={['large', 'delay3']} utility={['anim']}>
                                            <CardHeading>
                                                <Layout>
                                                    <LayoutItem utility={['5/8@from-small']}>
                                                        {project.contacts.length}{' '}
                                                        <Translation value="contactList.cardHeader" />
                                                    </LayoutItem>
                                                    {userCanDo(user, 'project.update') && (
                                                        <LayoutItem utility={['3/8@from-small', 'text-right']}>
                                                            <ModalContainer
                                                                render={({ handleClickOpen }) => (
                                                                    <Button
                                                                        icon="edit-simple"
                                                                        option={['blue', 'xsmall', 'icon']}
                                                                        htmlAttrs={{
                                                                            'data-item-id': 'projectTeamDetails',
                                                                            'data-item-data': `${JSON.stringify(
                                                                                project
                                                                            )}`,
                                                                        }}
                                                                        onClick={handleClickOpen}
                                                                    >
                                                                        <Translation value="modal.employees.edit-button" />
                                                                    </Button>
                                                                )}
                                                            />
                                                        </LayoutItem>
                                                    )}
                                                </Layout>
                                            </CardHeading>
                                            <Layout utility={['gutter-small']}>
                                                {project.contacts.map(contact => (
                                                    <LayoutItem utility={['1/2@from-medium']} key={contact.id}>
                                                        <CardContact contact={contact} option={['large', 'border']} />
                                                    </LayoutItem>
                                                ))}
                                            </Layout>
                                        </Card>
                                    </Section>
                                </Container>
                            </LayoutItem>
                        </Layout>
                    </View>
                )}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ContactListView)
