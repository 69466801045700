import PropTypes from 'prop-types'
import React from 'react'
import { Translate, withLocalize } from 'react-localize-redux'

const onMissingTranslation = ({ translationId, languageCode }) => {
    return translationId
}

const Translation = ({ activeLanguage, htmlEl = 'div', isHtml = false, value }) => {
    if (typeof activeLanguage === 'undefined') {
        return null
    }

    if (value === null) {
        return value
    }

    if (typeof value === 'object') {
        if (isHtml) {
            const El = htmlEl
            return <El dangerouslySetInnerHTML={{ __html: value[activeLanguage.code] }}></El>
        } else {
            return value[activeLanguage.code]
        }
    } else {
        return <Translate id={value} options={{ onMissingTranslation, renderInnerHtml: isHtml }} />
    }
}

Translation.propTypes = {
    htmlEl: PropTypes.oneOf(['div', 'span', 'p']),
    isHtml: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default withLocalize(Translation)
