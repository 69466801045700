import React from 'react'

import { renderClasses } from '../../utils'
import { Icon } from '../Icon'

const TimelineSub = ({ children, ...classProps }) => (
    <div className={renderClasses('timeline_sub', classProps)}>
        <div className="timeline_item_dot">
            <Icon name="check" />
        </div>
        <div className="timeline_sub_line"></div>
        <div className="timeline_sub_content">{children}</div>
    </div>
)

export default TimelineSub
