// import classNames from 'classnames'
import React from 'react'

import { ProjectCardsItem } from '../../components/ProjectCards'

import { getUserFavoriteProjects } from '../../utils'

import '../../components/ProjectCards/_projectCards.scss'

const ProjectCards = ({
    user = {},
    projects = [],
    showAdd = false,
    showEdit = false,
    showFavoriteProjects = false,
    showRemove = false,
}) => {
    const favoriteProjects = getUserFavoriteProjects(user)
    if (showFavoriteProjects) {
        projects.sort((a, b) => {
            const indexA = favoriteProjects.indexOf(a.id)
            const indexB = favoriteProjects.indexOf(b.id)
            return (indexA > -1 ? indexA : Infinity) - (indexB > -1 ? indexB : Infinity)
        })
    }

    return (
        <ul className="projectCards">
            {projects.map(project => (
                <ProjectCardsItem
                    key={project.id}
                    project={project}
                    showAdd={showAdd}
                    showFavoriteProjects={showFavoriteProjects}
                    showEdit={showEdit}
                    showRemove={showRemove}
                />
            ))}
        </ul>
    )
}

export default ProjectCards
