import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Container } from '../Container'
import { Icon } from '../Icon'

import './_lightbox.scss'

const Lightbox = ({ children, isOpen = false, onClose }) => (
    <div className={classNames('lightbox', { 'is-open': isOpen })}>
        <div className="lightbox_bg" onClick={onClose} />
        <Container>
            <div className="lightbox_main">{children}</div>
            <button type="button" className="lightbox_close" onClick={onClose}>
                <Icon name="close" />
            </button>
        </Container>
    </div>
)

Lightbox.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
}

export default Lightbox
