import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { Introduction } from '../Introduction'

const IntroductionContainer = ({ dismissedPointers, isDeactivated = false, tutorialIsActive }) => {
    if (dismissedPointers.includes('introduction') || isDeactivated || tutorialIsActive) {
        return null
    } else {
        return <Introduction />
    }
}

IntroductionContainer.propTypes = {
    isDeactivated: PropTypes.bool,
}

const mapStateToProps = state => ({
    dismissedPointers: state.view.pointers.dismissed,
    tutorialIsActive: state.view.tutorial.isActive,
})

export default connect(mapStateToProps)(IntroductionContainer)
