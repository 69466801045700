import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { renderClasses, renderRoute } from '../../utils'
import { ButtonIcon } from '../Button'

import './_button.scss'

const ButtonLabel = ({ children, href = null, icon = null, isBlank = false, route = null, ...classProps }) => {
    const classes = renderClasses('button-label', classProps)
    const blankAtts = isBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {}

    return route !== null ? (
        <RouterLink className={classes} to={renderRoute(route)}>
            <ButtonIcon name={icon} />
            <p className="button_label">{children}</p>
        </RouterLink>
    ) : (
        <a href={href} className={classes} {...blankAtts}>
            <ButtonIcon name={icon} />
            <p className="button_label">{children}</p>
        </a>
    )
}

ButtonLabel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    href: PropTypes.string,
    icon: PropTypes.string,
    isBlank: PropTypes.bool,
    route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default ButtonLabel
