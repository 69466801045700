import React from 'react'

import { View } from '../components/View'

const NotFoundView = () => (
    <View is404>
        <h1>Page not found</h1>
    </View>
)

export default NotFoundView
