import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { renderClasses, renderRoute } from '../../utils'
import { ButtonIcon } from '../Button'

import './_button.scss'

const Wrapper = ({ children, display = false }) =>
    display === true ? <span className="button_inner">{children}</span> : children

const ButtonLink = ({
    children,
    href = null,
    hash,
    hasWrapper = false,
    onClick,
    icon = null,
    isBlank = false,
    route = null,
    htmlAttrs,
    ...classProps
}) => {
    const classes = renderClasses('button', classProps)
    const blankAtts = isBlank ? { target: '_blank', rel: 'noopener noreferrer' } : {}

    return route !== null ? (
        <RouterLink
            className={classes}
            to={{
                pathname: renderRoute(route),
                hash: hash,
            }}
            {...htmlAttrs}
        >
            <Wrapper display={hasWrapper}>
                <ButtonIcon name={icon} />
                {children}
            </Wrapper>
        </RouterLink>
    ) : href !== null ? (
        <a href={href} className={classes} {...blankAtts} {...htmlAttrs}>
            <Wrapper display={hasWrapper}>
                <ButtonIcon name={icon} />
                {children}
            </Wrapper>
        </a>
    ) : onClick !== null ? (
        <button onClick={onClick} className={classes} {...blankAtts} type="button" {...htmlAttrs}>
            <Wrapper display={hasWrapper}>
                <ButtonIcon name={icon} />
                {children}
            </Wrapper>
        </button>
    ) : null
}

ButtonLink.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    href: PropTypes.string,
    icon: PropTypes.string,
    isBlank: PropTypes.bool,
    route: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default ButtonLink
