import { ConnectedRouter } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { LocalizeProvider } from 'react-localize-redux'
import { Provider } from 'react-redux'

import '../../styles/index.scss'

import { ErrorBoundary } from '../ErrorBoundary'
import { RouteResolver } from '../RouteResolver'

const App = ({ history, store }) => (
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <ConnectedRouter history={history}>
                <ErrorBoundary>
                    <RouteResolver />
                </ErrorBoundary>
            </ConnectedRouter>
        </LocalizeProvider>
    </Provider>
)

App.propTypes = {
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
}

export default App
