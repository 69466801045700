import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import './_formFancyCheckbox.scss'

const TYPES = ['checkbox', 'radio']

const FormFancyCheckbox = ({
    id,
    isChecked = false,
    label,
    name,
    onChange = () => {},
    onChangeValue = '',
    type = 'checkbox',
    ...classProps
}) => {
    if (TYPES.indexOf(type) === -1) {
        throw new Error('Invalid input type for FormFancyCheckbox.')
    }

    const handleOnChange = event => {
        return onChange(onChangeValue)
    }

    return (
        <React.Fragment>
            <input
                id={id}
                type={type}
                name={name}
                checked={isChecked}
                onChange={handleOnChange}
                className={renderClasses('formFancyCheckbox', classProps)}
            />
            <label htmlFor={id} className="formFancyCheckbox_label u-screen-reader-text">
                {label}
            </label>
        </React.Fragment>
    )
}

FormFancyCheckbox.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
}

export default FormFancyCheckbox
