import { apiClient } from './apiClient'
import { baseUrl as projectsBaseUrl } from './projectActions'

export const baseUrl = 'resources'

/**
 * Create a Resource from an project ID.
 */
export function createResource(projectId, formData, config = {}) {
    return apiClient.post(`${projectsBaseUrl}/${projectId}/${baseUrl}`, formData, config)
}

/**
 * Update a Resource from an project ID.
 */
export function updateResource(projectId, resourceId, formData, config = {}) {
    return apiClient.patch(`${projectsBaseUrl}/${projectId}/${baseUrl}/${resourceId}`, formData, config)
}

/**
 * Fetch resource types.
 */
export function fetchResourceTypes(projectId, config = {}) {
    return apiClient.get(`${projectsBaseUrl}/${projectId}/${baseUrl}/types`, {}, config)
}
