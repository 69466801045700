import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Fab } from '../Fab'
import { HeaderContainer } from '../Header'
import { Help, HelpContainer } from '../Help'
import { IntroductionContainer } from '../Introduction'
import { Modal, ModalContainer } from '../Modal'
import { ScrollToTop } from '../ScrollToTop'
import { Spinner } from '../Spinner'

import './_view.scss'

const View = ({ children, is404 = false, isIntroductionDeactivated = false, dismissedPointers, hasTutorialActive }) => (
    <div>
        <IntroductionContainer isDeactivated={isIntroductionDeactivated || is404} />
        <div
            className={classNames('view', {
                'has-introduction': !hasTutorialActive && dismissedPointers.length === 0 && !is404,
                'has-tutorial': hasTutorialActive,
            })}
        >
            {!is404 && <HeaderContainer />}
            <main>{children}</main>
            {!is404 && (
                <React.Fragment>
                    <ToastContainer position="bottom-right" hideProgressBar draggable={false} theme="colored" />
                    <HelpContainer
                        render={({
                            articleId,
                            handleClickClose,
                            handleClickFab,
                            handleClickOpenContent,
                            isOpen,
                            project,
                        }) => (
                            <React.Fragment>
                                <Help
                                    articleId={articleId}
                                    handleClickClose={handleClickClose}
                                    handleClickOpenContent={handleClickOpenContent}
                                    isOpen={isOpen}
                                    project={project}
                                />
                                <Fab handleClick={handleClickFab} isOpen={isOpen} />
                            </React.Fragment>
                        )}
                    />
                    <ModalContainer
                        render={({ modalId, handleClickClose, handleClickOpen, handleModalSubmit, isOpen, data }) => (
                            <React.Fragment>
                                <Modal
                                    modalId={modalId}
                                    handleClickClose={handleClickClose}
                                    handleClickOpen={handleClickOpen}
                                    handleModalSubmit={handleModalSubmit}
                                    isOpen={isOpen}
                                    data={data}
                                />
                            </React.Fragment>
                        )}
                    />
                </React.Fragment>
            )}
            <ScrollToTop />
            <Spinner />
        </div>
    </div>
)

View.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    is404: PropTypes.bool,
    isIntroductionDeactivated: PropTypes.bool,
}

const mapStateToProps = state => ({
    hasTutorialActive: state.view.tutorial.isActive,
    dismissedPointers: state.view.pointers.dismissed,
})

export default connect(mapStateToProps)(View)
