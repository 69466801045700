import React from 'react'
import { connect } from 'react-redux'

import { userCanDo } from '../utils'
// import { ButtonCircle } from '../components/Button'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
// import { Layout, LayoutItem } from '../components/Layout'
import { Page } from '../components/Page'
import { ProjectListContainer } from '../components/Project'
import { ProjectCards } from '../components/ProjectCards'
import { TabPanel, TabSelector, useTabs } from '../components/Tabs'
import { Translation } from '../components/Translation'

import '../components/Project/_projectList.scss'

const ProjectListView = ({ user }) => {
    const userCanUpdateProjects = userCanDo(user, 'project.update')
    const userCanAddSelfToProject = userCanDo(user, 'project.addSelf')
    const userCanFavoriteProjects = userCanDo(user, 'project.favoriteProjects')

    const [selectedTab, setSelectedTab] = useTabs(['incomplete', 'completed', 'remaining'], 'incomplete')

    const incompleteProjects = list => {
        if (user.isEmployee) {
            return list.filter(item => !item.isCompleted && item.employees.includes(user.id))
        } else {
            return list.filter(item => !item.isCompleted && item.clients.includes(user.id))
        }
    }

    const completedProjects = list => {
        if (user.isEmployee) {
            return list.filter(item => item.isCompleted && item.employees.includes(user.id))
        } else {
            return list.filter(item => item.isCompleted && item.clients.includes(user.id))
        }
    }

    const remainingProjects = list => {
        if (user.isEmployee) {
            return list.filter(item => !item.isCompleted && !item.employees.includes(user.id))
        } else {
            return list.filter(item => !item.isCompleted && !item.clients.includes(user.id))
        }
    }

    return (
        <Page>
            <Container option={['refresh']}>
                <ProjectListContainer
                    render={({ projects }) => (
                        <div className="projectList">
                            <Heading className="projectList_heading" el="h1">
                                <Translation value="header.myProjects" />
                            </Heading>
                            <div className="projectList_filters">
                                <TabSelector
                                    className="projectList_filters_button"
                                    isActive={selectedTab === 'incomplete'}
                                    onClick={() => setSelectedTab('incomplete')}
                                >
                                    <span className="projectList_filters_label">
                                        <Translation value="projectList.filters.incomplete" />
                                    </span>
                                    <span className="projectList_filters_number">
                                        {incompleteProjects(projects).length}
                                    </span>
                                </TabSelector>
                                <TabSelector
                                    className="projectList_filters_button"
                                    isActive={selectedTab === 'completed'}
                                    onClick={() => setSelectedTab('completed')}
                                >
                                    <span className="projectList_filters_label">
                                        <Translation value="projectList.filters.completed" />
                                    </span>
                                    <span className="projectList_filters_number">
                                        {completedProjects(projects).length}
                                    </span>
                                </TabSelector>
                                {userCanAddSelfToProject && (
                                    <TabSelector
                                        className="projectList_filters_button"
                                        isActive={selectedTab === 'remaining'}
                                        onClick={() => setSelectedTab('remaining')}
                                    >
                                        <span className="projectList_filters_label">
                                            <Translation value="projectList.filters.remaining" />
                                        </span>
                                        <span className="projectList_filters_number">
                                            {remainingProjects(projects).length}
                                        </span>
                                    </TabSelector>
                                )}
                            </div>
                            {projects.length > 1 ? (
                                <>
                                    <TabPanel isHidden={selectedTab !== 'incomplete'}>
                                        <ProjectCards
                                            projects={incompleteProjects(projects)}
                                            showEdit={userCanUpdateProjects}
                                            showFavoriteProjects={userCanFavoriteProjects}
                                            showRemove={userCanAddSelfToProject}
                                            user={user}
                                        />
                                    </TabPanel>
                                    <TabPanel isHidden={selectedTab !== 'completed'}>
                                        <ProjectCards
                                            projects={completedProjects(projects)}
                                            showEdit={userCanUpdateProjects}
                                            showRemove={userCanAddSelfToProject}
                                        />
                                    </TabPanel>
                                    {userCanAddSelfToProject && (
                                        <TabPanel isHidden={selectedTab !== 'remaining'}>
                                            <ProjectCards projects={remainingProjects(projects)} showAdd />
                                        </TabPanel>
                                    )}
                                </>
                            ) : (
                                <div className="projectList_list">
                                    <div className="projectList_item">
                                        <Translation value="projectList.noProjects" />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                />
            </Container>
        </Page>
    )
}

// {userCanDo(user, 'project.create') && (
//     <ButtonCircle
//         className="projectList_create"
//         route="project-create"
//         option={['medium', 'delay2']}
//         utility={['anim']}
//         icon="plus"
//     />
// )}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ProjectListView)
