import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import React, { useState, useEffect } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { withLocalize } from 'react-localize-redux'

import { createPublicUpload, deletePublicUpload, loadPublicUpload } from '../../actions/uploadActions'
import { renderClasses } from '../../utils'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

import './_formInputFile.scss'

registerPlugin(FilePondPluginImagePreview)

const FormInputFile = ({
    id,
    name,
    allowMultiple = false,
    multiple = 1,
    currentFileUrl,
    translate,
    selectedFileResource = () => {},
    ...classProps
}) => {
    const [files, setFiles] = useState([])

    useEffect(() => {
        if (currentFileUrl) {
            setFiles([
                {
                    source: currentFileUrl,
                    options: {
                        type: 'local',
                    },
                },
            ])
        }
    }, [setFiles, currentFileUrl])

    const handleProcess = (fieldName, file, metadata, load, error, progress, abort) => {
        const formData = new FormData()
        formData.append(fieldName, {})
        formData.append(fieldName, file, file.name)
        createPublicUpload(formData)
            .then(response => {
                selectedFileResource(response.data)
                load(response.data)
            })
            .catch(error => {
                console.error(error)
            })

        return {
            abort: () => {
                abort()
            },
        }
    }

    const handleRevert = (uniqueFileId, load) => {
        deletePublicUpload({ data: uniqueFileId })
            .catch(error => {
                console.error(error)
            })
            .finally(() => {
                selectedFileResource(null)
                load()
            })
    }

    const handleLoad = (source, load, error, progress, abort, headers) => {
        const url = new URL(source)
        loadPublicUpload(url.pathname)
            .then(response => {
                let resHeaders = ''
                for (const [key, value] of Object.entries(response.headers)) {
                    resHeaders += `${key}: ${value}; `
                }
                headers(JSON.stringify(resHeaders))
                return response.data
            })
            .then(file => {
                selectedFileResource(null)
                load(file)
            })
            .catch(error => {
                error(error)
            })

        return {
            abort: () => {
                abort()
            },
        }
    }

    const handleRemove = (source, load, error) => {
        selectedFileResource('')
        load()
    }

    return (
        <div className={renderClasses('formInputFile', classProps)}>
            <div>
                <FilePond
                    id={id}
                    files={files}
                    className="formInputFile_input"
                    onupdatefiles={setFiles}
                    allowMultiple={allowMultiple}
                    maxFiles={allowMultiple ? multiple : 1}
                    server={{
                        process: (fieldName, file, metadata, load, error, progress, abort) =>
                            handleProcess(fieldName, file, metadata, load, error, progress, abort),
                        revert: (uniqueFileId, load) => handleRevert(uniqueFileId, load),
                        load: (source, load, error, progress, abort, headers) =>
                            handleLoad(source, load, error, progress, abort, headers),
                        remove: (source, load, error) => handleRemove(source, load, error),
                    }}
                    credits={false}
                    name={name}
                    labelIdle={`<span class="filepond--label-action">${translate('projectForm.drop-file')}</span>`}
                />
            </div>
        </div>
    )
}

export default withLocalize(FormInputFile)
