import React from 'react'

import PropTypes from 'prop-types'

import { Icon } from '../Icon'

import './_link.scss'

const LinkIcon = ({ name = null }) =>
    name !== null ? (
        <span className="link_icon">
            <Icon name={name} />
        </span>
    ) : null

LinkIcon.propTypes = {
    name: PropTypes.string,
}

export default LinkIcon
