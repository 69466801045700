import { apiClient } from './apiClient'
export const baseUrl = 'blueprints'

/**
 * Retrieve all blueprints milestones.
 */
export function fetchBlueprintProjects(config = {}) {
    return apiClient.get(`${baseUrl}/projects`, config)
}

/**
 * Retrieve all blueprints milestones.
 */
export function fetchBlueprintMilestones(config = {}) {
    return apiClient.get(`${baseUrl}/milestones`, config)
}

/**
 * Retrieve all blueprints milestones.
 */
export function fetchBlueprintResourceGroups(config = {}) {
    return apiClient.get(`${baseUrl}/resource-groups`, config)
}
