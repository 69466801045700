import { combineReducers } from 'redux'

import { FETCH_USER_SELF_SUCCESS } from '../actions/userActions'
import {
    PUSH_NOTIFICATION,
    OPEN_HELP,
    CLOSE_HELP,
    SET_DISMISSED_POINTERS,
    TOGGLE_HELP,
    NEXT_TUTORIAL_STEP,
    ACTIVATE_TUTORIAL,
    DISABLE_TUTORIAL,
    OPEN_MODAL,
    CLOSE_MODAL,
    SUBMIT_MODAL,
} from '../actions/viewActions'

const notificationInitialState = {
    items: [
        {
            message: "Hmm, you can't do that!",
            type: 'warning',
        },
        {
            message: 'Welcome to Station chief',
            type: 'info',
        },
        {
            message: 'Well done, a great victory',
            type: 'success',
        },
        {
            message: 'Oh shit, an error occured',
            type: 'danger',
        },
    ],
}

function notification(state = notificationInitialState, action) {
    switch (action.type) {
        case PUSH_NOTIFICATION:
            return {
                ...state,
                items: [action.item, ...state.items],
                isOpen: true,
            }

        default:
            return state
    }
}

const helpInitialState = {
    articleId: null,
    isOpen: false,
}

function help(state = helpInitialState, action) {
    switch (action.type) {
        case OPEN_HELP:
            return {
                ...state,
                articleId: action.articleId,
                isOpen: true,
            }

        case CLOSE_HELP:
            return {
                ...state,
                articleId: null,
                isOpen: false,
            }

        case TOGGLE_HELP:
            return {
                ...state,
                isOpen: !state.isOpen,
                articleId: null,
            }

        default:
            return state
    }
}

const modalInitialState = {
    isOpen: false,
    modalId: null,
    data: [],
}

function modal(state = modalInitialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                modalId: action.modalId,
                data: action.data !== undefined ? action.data : [],
                isOpen: true,
            }

        case SUBMIT_MODAL:
            return {
                ...state,
                isOpen: true,
            }

        case CLOSE_MODAL:
            return {
                ...state,
                modalId: null,
                data: null,
                isOpen: false,
            }

        default:
            return state
    }
}

const pointersInitialState = {
    dismissed: [],
}

function pointers(state = pointersInitialState, action) {
    switch (action.type) {
        case SET_DISMISSED_POINTERS:
            return {
                ...state,
                dismissed: action.pointers,
            }

        case FETCH_USER_SELF_SUCCESS:
            return {
                dismissed: action.result.data.dismissedPointers,
            }

        default:
            return state
    }
}

const tutorialInitialState = {
    isActive: false,
    currentStep: 0,
    steps: [
        {
            id: 0,
            title: {
                en: 'Menu',
                fr: 'Menu',
            },
            content: {
                en: 'This menu allows you to navigate the different tabs related to your project.',
                fr: 'Ce menu vous permet de naviguer dans les différents onglets en rapport au projet.',
            },
        },
        {
            id: 1,
            title: {
                en: 'Resources',
                fr: 'Ressources',
            },
            content: {
                en:
                    'You will found below all the links related to the project. Stay tuned because some links will be added throughout the project!',
                fr:
                    "Vous trouverez ci-bas l'ensemble des liens relatif au projet. Restez à l'affût car certains liens seront ajoutés tout au long du projet!",
            },
        },
        {
            id: 2,
            title: {
                en: 'Task overview',
                fr: 'Tâches',
            },
            content: {
                en: 'This gauge allows you to have a quick glance on the progress.',
                fr: "Cette jauge vous permet d'avoir un coup d'oeil rapide sur l'avancement du projet. ",
            },
        },
        {
            id: 3,
            title: {
                en: 'Frequently asked questions',
                fr: 'Foire aux questions',
            },
            content: {
                en: "Any questions? Maybe you'll finds the answer you need in this list of questions!",
                fr: 'Vous avez des questions? Peut-être que la réponse se trouve ici!',
            },
        },
    ],
}

function tutorial(state = tutorialInitialState, action) {
    switch (action.type) {
        case NEXT_TUTORIAL_STEP:
            return {
                ...state,
                currentStep: state.currentStep + 1,
            }
        case ACTIVATE_TUTORIAL:
            return {
                ...state,
                isActive: true,
            }
        case DISABLE_TUTORIAL:
            return {
                ...state,
                isActive: false,
            }
        default:
            return state
    }
}

const view = combineReducers({ notification, help, modal, pointers, tutorial })

export { view }
