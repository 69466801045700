import classNames from 'classnames'
import _ from 'lodash'
import React, { useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { createCompany } from '../../actions/companyActions'
import { formatFormNotifications } from '../../utils'
import { Button } from '../Button'
import { ModalActions, ModalActionsItem, ModalHeading } from '../Modal'
import { FormContainer, FormError, FormInput, FormItem, FormLabel, FormNotifications } from '../Form'
import { Translation } from '../Translation'

const ModalCompanyDetails = ({ dispatch, handleClickClose, translate }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = ({ event, form: { resetFields, validateFields } }) => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                setIsLoading(true)

                dispatch(createCompany(formValues.companyForm))
                    .then(response => {
                        setIsLoading(false)
                        handleClickClose()
                        resetFields()
                    })
                    .catch(error => {
                        setErrors(error.response.data)
                        setIsLoading(false)
                    })
            }
        })
        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <>
            <ModalHeading handleClickClose={handleClickClose}>
                <Translation value="modal.company.form-title-add" />
            </ModalHeading>
            <div className="modal_container u-padding-x u-padding-small-y">
                <FormContainer
                    option={['delay2']}
                    utility={['anim']}
                    handleSubmit={handleSubmit}
                    render={({ form }) => (
                        <React.Fragment>
                            {hasNotifications && (
                                <FormItem>
                                    <FormNotifications hasErrors={hasErrors} items={notifications} />
                                </FormItem>
                            )}
                            <FormItem>
                                <FormLabel inputId="companyForm.name" isRequired>
                                    <Translation value="modal.company.form-label-name" />
                                </FormLabel>
                                <FormInput
                                    id="companyForm.name"
                                    name="companyForm.name"
                                    form={form}
                                    fieldOptions={{
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.requiredField'),
                                            },
                                        ],
                                    }}
                                />
                                <FormError>{form.getFieldError('companyForm.name')}</FormError>
                            </FormItem>
                            <ModalActions>
                                <ModalActionsItem>
                                    <Button
                                        className={classNames({ 'is-loading': isLoading })}
                                        hasWrapper
                                        option={['blue', 'small', 'block', 'spinner']}
                                        type="submit"
                                    >
                                        <Translation value="modal.action-save" />
                                    </Button>
                                </ModalActionsItem>
                            </ModalActions>
                        </React.Fragment>
                    )}
                />
            </div>
        </>
    )
}

export default withLocalize(connect()(ModalCompanyDetails))
