import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import { translate, useFetchItemList } from '../../utils'
import { fetchResourceTypes } from '../../actions/resourceActions'

import { LoadingError } from '../LoadingError'

const ResourceTypeContainer = ({ render, projectId }) => {
    const action = useCallback(() => {
        return fetchResourceTypes(projectId)
    }, [projectId])
    const { error, isLoading, items } = useFetchItemList(action)

    const resourceTypes = items.map(item => ({
        id: item.id,
        label: translate(item.name),
    }))

    if (isLoading) {
        return null
    } else if (error !== null) {
        console.warn(error)
        return <LoadingError />
    } else {
        return render({ resourceTypes })
    }
}
const mapStateToProps = ({ currentProject }) => ({
    projectId: currentProject.id,
})

export default connect(mapStateToProps)(ResourceTypeContainer)
