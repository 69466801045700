import PropTypes from 'prop-types'
import React from 'react'

import { translate } from '../../utils'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { Translation } from '../Translation'

import './_cardVideo.scss'

const CardVideo = ({ onClick, video }) => (
    <button
        type="button"
        className="cardVideo"
        onClick={onClick}
        data-video-id={translate(video.embed.id)}
        data-platform={translate(video.embed.provider)}
    >
        <div className="cardVideo_preview">
            <img src={translate(video.embed.image)} alt="" />
            <div className="cardVideo_preview_icon">
                <Icon name="play" />
            </div>
        </div>
        <div className="cardVideo_title">
            <Heading option={['h4']}>
                <Translation value={video.name} />
            </Heading>
        </div>
        {/*<span className="cardVideo_duration">{video.duration}</span>*/}
    </button>
)

CardVideo.propTypes = {
    onClick: PropTypes.func.isRequired,
    video: PropTypes.object.isRequired,
}

export default CardVideo
