import _ from 'lodash'
import { useCallback } from 'react'
import { connect } from 'react-redux'

import { CLOSE_MODAL, OPEN_MODAL, SUBMIT_MODAL } from '../../actions/viewActions'

const ModalContainer = ({ modalId, data, dispatch, isOpen, onSubmit = () => {}, render, ...props }) => {
    const handleClickClose = useCallback(() => {
        dispatch({
            type: CLOSE_MODAL,
        })
    }, [dispatch])

    const handleModalSubmit = useCallback(() => {
        onSubmit()
        dispatch({
            type: SUBMIT_MODAL,
        })
    }, [dispatch, onSubmit])

    const handleClickOpen = useCallback(
        event => {
            const id = event.currentTarget.getAttribute('data-item-id')
            const data = JSON.parse(event.currentTarget.getAttribute('data-item-data')) || {}

            dispatch({
                type: OPEN_MODAL,
                modalId: _.isEmpty(id) ? null : id,
                data: data,
            })
        },
        [dispatch]
    )

    return render({ modalId, data, isOpen, handleClickClose, handleClickOpen, handleModalSubmit })
}

const mapStateToProps = state => ({
    isOpen: state.view.modal.isOpen,
    modalId: state.view.modal.modalId,
    data: state.view.modal.data,
})

export default connect(mapStateToProps)(ModalContainer)
