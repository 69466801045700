import classNames from 'classnames'
import Immutable from 'immutable'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'

import { updateSelf, updateSelfEmail, updateSelfPassword } from '../actions/userActions'
import { formatFormNotifications } from '../utils/forms'
import { Button, ButtonLink } from '../components/Button'
import { CardHeading } from '../components/Card'
import { Container } from '../components/Container'
import {
    FormContainer,
    FormError,
    FormInput,
    FormInputChoice,
    FormItem,
    FormLabel,
    FormNotifications,
} from '../components/Form'
import { Heading } from '../components/Heading'
import { InfoText } from '../components/InfoText'
import { Layout, LayoutItem } from '../components/Layout'
import { Page } from '../components/Page'
import { Section, SectionHeader } from '../components/Section'
import { Translation } from '../components/Translation'

const LOCALES = [
    {
        id: 'en',
        label: 'English',
    },
    {
        id: 'fr',
        label: 'Français',
    },
]

const UserProfileView = ({ dispatch, formMethods, translate, user }) => {
    const [errors, setErrors] = useState([])
    const [initialValues, setInitialValues] = useState(Immutable.Map({}))
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [isLoading, setIsLoading] = useState(null)
    const [userLocale, setUserLocale] = useState(null)

    useEffect(() => {
        setInitialValues(Immutable.Map(user))
        setUserLocale(user.preferences.locale)
    }, [user])

    const handleOnChangeLocaleCheckbox = event => {
        const value = event.target.id.replace('locale_', '')
        setUserLocale(value)
        return value
    }

    const handleSubmit = ({ event, form: { resetFields, setFieldsValue, validateFields }, formId, resetOnSubmit }) => {
        event.preventDefault()

        if (isLoading !== null) {
            return false
        }

        const saveMethod = formMethods[formId]

        setFieldsValue({ 'preferences.locale': userLocale })
        validateFields(async (formErrors, formValues) => {
            if (!formErrors) {
                setErrors([])
                setIsLoading(formId)
                setIsFormSubmitted(false)
                saveMethod(formValues)
                    .then(() => {
                        setIsFormSubmitted(true)
                        if (resetOnSubmit) {
                            resetFields()
                        }
                    })
                    .catch(error => {
                        setErrors(error.response.data)
                    })
                    .finally(() => {
                        window.scrollTo(0, 0)
                        setIsLoading(null)
                    })
            }
        })
        return false
    }

    const hasErrors = errors.length > 0
    const notifications = hasErrors
        ? formatFormNotifications(errors)
        : isFormSubmitted
        ? [
              {
                  message: translate('userProfile.successfulUpdate'),
              },
          ]
        : []
    const hasNotifications = notifications.length > 0

    const hasMultipleProjects = user.projectIds.length > 1

    return (
        <Page>
            <Layout option={['center']}>
                <LayoutItem option={['contentSmall']}>
                    <Container>
                        <Section>
                            <div className="u-margin-bottom-y u-anim -delay1">
                                <ButtonLink route="project-list" option={['blue']}>
                                    {hasMultipleProjects ? (
                                        <Translation value="userProfile.returnProjects" />
                                    ) : (
                                        <Translation value="userProfile.returnProject" />
                                    )}
                                </ButtonLink>
                            </div>
                            <SectionHeader>
                                <Layout>
                                    <LayoutItem utility={['5/8@from-small']}>
                                        <Heading option={['h1', 'delay2']} utility={['anim']}>
                                            <Translation value="userProfile.pageHeader" />
                                        </Heading>
                                    </LayoutItem>
                                    <LayoutItem utility={['3/8@from-small']}>
                                        <InfoText option={['delay3']} utility={['anim']}>
                                            <Translation value="userProfile.infoText" />
                                        </InfoText>
                                    </LayoutItem>
                                </Layout>
                            </SectionHeader>
                            {hasNotifications && (
                                <LayoutItem>
                                    <FormItem>
                                        <FormNotifications
                                            hasErrors={hasErrors}
                                            hasSuccess={isFormSubmitted}
                                            items={notifications}
                                        />
                                    </FormItem>
                                </LayoutItem>
                            )}
                            <FormContainer
                                formId="info"
                                handleSubmit={handleSubmit}
                                option={['delay2']}
                                utility={['anim']}
                                render={({ form }) => (
                                    <React.Fragment>
                                        <CardHeading>
                                            <Translation value="userProfile.personalInfo" />
                                        </CardHeading>
                                        <Layout option={['gutter-small']}>
                                            <LayoutItem utility={['1/2@from-small']}>
                                                <FormItem>
                                                    <FormLabel inputId="fullName">
                                                        <Translation value="form.fullName" />
                                                    </FormLabel>
                                                    <FormInput
                                                        id="fullName"
                                                        name="fullName"
                                                        form={form}
                                                        fieldOptions={{
                                                            initialValue: initialValues.get('fullName') || '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: translate('form.requiredField'),
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <FormError>{form.getFieldError('fullName')}</FormError>
                                                </FormItem>
                                            </LayoutItem>
                                            <LayoutItem utility={['1/2@from-small']}>
                                                <FormItem>
                                                    <FormLabel inputId="phone">
                                                        <Translation value="form.phone" />
                                                    </FormLabel>
                                                    <FormInput
                                                        id="phone"
                                                        name="phone"
                                                        type="phone"
                                                        form={form}
                                                        fieldOptions={{
                                                            initialValue: initialValues.get('telephoneNumber') || '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: translate('form.requiredField'),
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <FormError>{form.getFieldError('phone')}</FormError>
                                                </FormItem>
                                            </LayoutItem>
                                            <LayoutItem utility={['1/2@from-small']}>
                                                <FormItem>
                                                    <FormLabel isDecorative>
                                                        <Translation value="form.locale" />
                                                    </FormLabel>
                                                    {LOCALES.map((locale, index) => (
                                                        <FormInputChoice
                                                            key={index}
                                                            id={`locale_${locale.id}`}
                                                            name="preferences.locale"
                                                            form={form}
                                                            label={locale.label}
                                                            checked={userLocale === locale.id}
                                                            fieldOptions={{
                                                                getValueFromEvent: handleOnChangeLocaleCheckbox,
                                                            }}
                                                        />
                                                    ))}
                                                </FormItem>
                                            </LayoutItem>
                                        </Layout>
                                        <Layout>
                                            <Button
                                                className={classNames({ 'is-loading': isLoading === 'info' })}
                                                option={['greenLight', 'spinner']}
                                                type="submit"
                                                hasWrapper
                                            >
                                                <Translation value="userProfile.save" />
                                            </Button>
                                        </Layout>
                                    </React.Fragment>
                                )}
                            />
                            <Layout option={['gutter-small']} utility={['margin-top-large']}>
                                <LayoutItem utility={['1/2@from-small', 'margin-bottom-large']}>
                                    <FormContainer
                                        formId="email"
                                        handleSubmit={handleSubmit}
                                        resetOnSubmit
                                        option={['delay2']}
                                        utility={['anim']}
                                        render={({ form }) => (
                                            <React.Fragment>
                                                <CardHeading>
                                                    <Translation value="userProfile.changeEmail" />
                                                </CardHeading>
                                                <FormItem>
                                                    <FormLabel inputId="emailNew">
                                                        <Translation value="form.emailNew" />
                                                    </FormLabel>
                                                    <FormInput
                                                        type="email"
                                                        id="emailNew"
                                                        name="emailNew"
                                                        form={form}
                                                        fieldOptions={{
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: translate('form.requiredField'),
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <FormError>{form.getFieldError('emailNew')}</FormError>
                                                </FormItem>
                                                <FormItem>
                                                    <FormLabel inputId="emailNewConfirmation">
                                                        <Translation value="form.emailNewConfirmation" />
                                                    </FormLabel>
                                                    <FormInput
                                                        type="email"
                                                        id="emailNewConfirmation"
                                                        name="emailNewConfirmation"
                                                        form={form}
                                                        fieldOptions={{
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: translate('form.requiredField'),
                                                                },
                                                                {
                                                                    validator: (rule, value, callback) => {
                                                                        if (
                                                                            value &&
                                                                            value !== form.getFieldValue('emailNew')
                                                                        ) {
                                                                            callback(translate('form.emailMatch'))
                                                                        } else {
                                                                            callback()
                                                                        }
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <FormError>{form.getFieldError('emailNewConfirmation')}</FormError>
                                                </FormItem>
                                                <Button
                                                    className={classNames({ 'is-loading': isLoading === 'email' })}
                                                    option={['greenLight', 'spinner']}
                                                    type="submit"
                                                    hasWrapper
                                                >
                                                    <Translation value="userProfile.save" />
                                                </Button>
                                            </React.Fragment>
                                        )}
                                    />
                                </LayoutItem>
                                <LayoutItem utility={['1/2@from-small']}>
                                    <FormContainer
                                        formId="password"
                                        handleSubmit={handleSubmit}
                                        resetOnSubmit
                                        option={['delay2']}
                                        utility={['anim']}
                                        render={({ form }) => (
                                            <React.Fragment>
                                                <CardHeading>
                                                    <Translation value="userProfile.changePassword" />
                                                </CardHeading>
                                                <FormItem>
                                                    <FormLabel inputId="passwordCurrent">
                                                        <Translation value="form.passwordCurrent" />
                                                    </FormLabel>
                                                    <FormInput
                                                        type="password"
                                                        id="passwordCurrent"
                                                        name="passwordCurrent"
                                                        form={form}
                                                        fieldOptions={{
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: translate('form.requiredField'),
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <FormError>{form.getFieldError('passwordCurrent')}</FormError>
                                                </FormItem>
                                                <FormItem>
                                                    <FormLabel inputId="passwordNew">
                                                        <Translation value="form.passwordNew" />
                                                    </FormLabel>
                                                    <FormInput
                                                        type="password"
                                                        id="passwordNew"
                                                        name="passwordNew"
                                                        form={form}
                                                        fieldOptions={{
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: translate('form.requiredField'),
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <FormError>{form.getFieldError('passwordNew')}</FormError>
                                                </FormItem>
                                                <FormItem>
                                                    <FormLabel inputId="passwordNewConfirmation">
                                                        <Translation value="form.passwordNewConfirmation" />
                                                    </FormLabel>
                                                    <FormInput
                                                        type="password"
                                                        id="passwordNewConfirmation"
                                                        name="passwordNewConfirmation"
                                                        form={form}
                                                        fieldOptions={{
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: translate('form.requiredField'),
                                                                },
                                                                {
                                                                    validator: (rule, value, callback) => {
                                                                        if (
                                                                            value &&
                                                                            value !== form.getFieldValue('passwordNew')
                                                                        ) {
                                                                            callback(translate('form.passwordMatch'))
                                                                        } else {
                                                                            callback()
                                                                        }
                                                                    },
                                                                },
                                                            ],
                                                        }}
                                                    />
                                                    <FormError>
                                                        {form.getFieldError('passwordNewConfirmation')}
                                                    </FormError>
                                                </FormItem>
                                                <Button
                                                    className={classNames({ 'is-loading': isLoading === 'password' })}
                                                    option={['greenLight', 'spinner']}
                                                    type="submit"
                                                    hasWrapper
                                                >
                                                    <Translation value="userProfile.save" />
                                                </Button>
                                            </React.Fragment>
                                        )}
                                    />
                                </LayoutItem>
                            </Layout>
                        </Section>
                    </Container>
                </LayoutItem>
            </Layout>
        </Page>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
    formMethods: {
        info: formValues => {
            return dispatch(updateSelf(formValues))
        },
        email: updateSelfEmail,
        password: updateSelfPassword,
    },
})

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(UserProfileView))
