import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { userCanDo } from '../utils'
import { NotFoundView } from '../views'
import { Heading } from '../components/Heading'
import { Layout, LayoutItem } from '../components/Layout'
import { NavActions } from '../components/NavActions'
import { Panel, PanelContainer, PanelFooter } from '../components/Panel'
import { ProjectContainer } from '../components/Project'
import { Section } from '../components/Section'
import { Translation } from '../components/Translation'
import { View } from '../components/View'

const MilestoneEmptyView = ({ user }) => {
    const { projectId } = useParams()

    return (
        <div>
            <ProjectContainer
                projectId={projectId}
                render={({ project }) =>
                    !_.isEmpty(project.milestones) ? (
                        <NotFoundView />
                    ) : (
                        <View>
                            <Layout>
                                <LayoutItem option={['panelSmall']}>
                                    <Panel option={['small']}>
                                        <PanelContainer>
                                            <Section>
                                                <div className="panel_header">
                                                    <Heading el="h1" option={['h1', 'delay1']} utility={['anim']}>
                                                        {userCanDo(user, 'project.update') && (
                                                            <NavActions
                                                                items={[
                                                                    {
                                                                        label: 'modal.milestone.add-button',
                                                                        modalId: 'milestoneDetails',
                                                                    },
                                                                ]}
                                                            />
                                                        )}
                                                        <span className="u-margin-tiny-x">
                                                            <Translation value="common.tasks" />
                                                        </span>
                                                    </Heading>
                                                </div>
                                            </Section>
                                        </PanelContainer>
                                        <PanelFooter project={project} option={['delay3']} utility={['anim']} />
                                    </Panel>
                                </LayoutItem>
                            </Layout>
                        </View>
                    )
                }
            />
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(MilestoneEmptyView)
