import _ from 'lodash'

/**
 * Determine if a user can perform a certain action.
 *
 * @param {object} user
 * @param {string} action
 * @return {boolean}
 */
export const userCanDo = (user, action) => {
    const canDo = _.includes(user.rules, `allow.${action}`)

    /** Check for denied rules */
    // if (canDo && user.role.isSuperuser) {
    //     if (_.includes(user.role.rules, `deny.${action}`)) {
    //         return false
    //     }
    // }

    return canDo
}

/**
 * @param {object} user
 * @returns {array}
 */
export const getUserFavoriteProjects = user => {
    if (
        user.hasOwnProperty('preferences') &&
        user.preferences.hasOwnProperty('favoriteProjects') &&
        _.isArray(user.preferences.favoriteProjects)
    ) {
        return user.preferences.favoriteProjects
    }
    return []
}
