import { useEffect, useState } from 'react'

const useTabs = (tabs = [], defaultTab = null) => {
    const state = useState()
    const [selectedTab, setSelectedTab] = state

    useEffect(() => {
        if (tabs.length === 0) {
            setSelectedTab(undefined)
            return
        }

        if (selectedTab === null || (selectedTab && tabs.includes(selectedTab))) {
            return
        }

        if (defaultTab === null) {
            return
        }

        setSelectedTab(defaultTab && tabs.includes(defaultTab) ? defaultTab : tabs[0])
    }, [selectedTab, setSelectedTab, defaultTab, tabs])

    return state
}

export default useTabs
