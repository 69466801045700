import _ from 'lodash'
import React, { useEffect, useRef } from 'react'
import { withLocalize } from 'react-localize-redux'

import { translate as utilTranslate } from '../../utils'
import { ButtonCircle } from '../Button'
import { FormLabel, FormSelect, FormError } from '../Form'
import { Layout, LayoutItem } from '../Layout'
import { ModalContainer } from '../Modal'
import { Translation } from '../Translation'

import './_companySelector.scss'

const CompanySelector = ({
    companies,
    newCompany,
    initialValue = '',
    selectedCompany = () => {},
    form,
    translate,
    ...classProps
}) => {
    const ref = useRef(false)

    // Set company ID when newCompany changes
    useEffect(() => {
        if (!_.isEmpty(newCompany)) {
            form.setFieldsValue({ 'projectForm.company': newCompany.id })
        }
    }, [newCompany]) // eslint-disable-line react-hooks/exhaustive-deps

    const companiesMapper = company => {
        return {
            ...company,
            value: company.id,
            label: utilTranslate(company.name),
        }
    }

    useEffect(() => {
        // Trigger update quick nav if initialValue set
        if (!ref.current && initialValue.length) {
            selectedCompany()
            ref.current = true
        }
    }, [selectedCompany, initialValue])

    return (
        <div className="companySelector">
            <div className="u-margin-small-y">
                <Layout option={['bottom', 'gutter-small']}>
                    <LayoutItem utility={['1/2@from-small']}>
                        <FormLabel inputId="projectForm.company" isRequired>
                            <Translation value="projectForm.company" />
                        </FormLabel>
                        <FormSelect
                            id="projectForm.company"
                            name="projectForm.company"
                            form={form}
                            items={companies}
                            itemsMapper={companiesMapper}
                            defaultItem={{
                                label: translate('projectForm.company-list'),
                                value: '',
                            }}
                            fieldOptions={{
                                initialValue: initialValue,
                                rules: [
                                    {
                                        required: true,
                                        message: translate('form.requiredField'),
                                    },
                                ],
                            }}
                            onChange={selectedCompany}
                        />
                        <FormError>{form.getFieldError('projectForm.company')}</FormError>
                    </LayoutItem>
                    <LayoutItem utility={['1/2@from-small', 'margin-top-tiny']}>
                        <ModalContainer
                            render={({ handleClickOpen }) => (
                                <ButtonCircle
                                    icon="plus"
                                    option={['small']}
                                    htmlAttrs={{
                                        'data-item-id': 'companyDetails',
                                    }}
                                    onClick={handleClickOpen}
                                />
                            )}
                        />
                    </LayoutItem>
                </Layout>
            </div>
        </div>
    )
}

export default withLocalize(CompanySelector)
