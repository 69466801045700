import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { renderClasses } from '../../utils'
import { Icon } from '../Icon'
import { Translation } from '../Translation'

import './_panelNav.scss'

const PanelNav = ({ items = [], ...classProps }) => (
    <nav className={renderClasses('panelNav', classProps)}>
        <ul className="panelNav_list">
            {items.map((item, index) => (
                <li className="panelNav_item" key={index}>
                    <RouterLink
                        className={classNames('panelNav_link', { 'is-active': item.isActive }, item.classes)}
                        to={item.url}
                    >
                        {item.isCompleted ? (
                            <span className="panelNav_icon">
                                <Icon name="check-circle" />
                            </span>
                        ) : (
                            item.isCurrent && (
                                <span className="panelNav_icon">
                                    <Icon name="progress" />
                                </span>
                            )
                        )}
                        <span className="panelNav_label">
                            <Translation value={item.label} />
                        </span>
                    </RouterLink>
                </li>
            ))}
        </ul>
    </nav>
)

PanelNav.propTypes = {
    items: PropTypes.array,
}

export default PanelNav
