import classNames from 'classnames'
import _ from 'lodash'
import React, { useState } from 'react'
import { withLocalize } from 'react-localize-redux'

import { FormInputChoice } from '../Form'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'
import { Translation } from '../Translation'

import './_projectBlueprintSelector.scss'

const ProjectBlueprintSelector = ({ isEdit = false, items, initialValue, form, translate }) => {
    const [value, setValue] = useState(initialValue)

    const onCheckboxChange = event => {
        setValue(event.target.id)
        return event.target.id
    }
    const resolveIcon = item => {
        if (!_.isEmpty(item.icon)) {
            return item.icon
        }
        if (!_.isEmpty(item.category) && !_.isEmpty(item.category.icon)) {
            return item.category.icon
        }
        return 'project-type-none'
    }

    return (
        <div className={classNames('projectBlueprintSelector', { '-disabled': isEdit })}>
            <p className="u-margin-tiny-y">
                <Translation value="projectForm.form1Label" />
            </p>
            <Layout option={['gutter']}>
                {items.map(item => (
                    <LayoutItem key={item.id} utility={['1/2', '1/4@from-small']}>
                        <div className="projectBlueprintSelector_radio">
                            <FormInputChoice
                                id={item.id}
                                name="projectForm.blueprint"
                                form={form}
                                isDisabled={isEdit}
                                checked={item.id === value ? 'checked' : ''}
                                fieldOptions={{
                                    initialValue: initialValue,
                                    getValueFromEvent: onCheckboxChange,
                                }}
                            />
                            <div
                                className={classNames('projectBlueprintSelector_radio_icon', {
                                    '-full': !_.isEmpty(item.icon),
                                })}
                            >
                                <Icon name={resolveIcon(item)} />
                            </div>
                            <p className="projectBlueprintSelector_radio_info">
                                <Translation value={item.name} />
                            </p>
                            <label className="projectBlueprintSelector_radio_label" htmlFor={item.id}></label>
                        </div>
                    </LayoutItem>
                ))}
            </Layout>
        </div>
    )
}

export default withLocalize(ProjectBlueprintSelector)
