import React from 'react'

import { Card, CardHeading } from '../Card'
import { Content } from '../Content'
import { Layout, LayoutItem } from '../Layout'
import { Translation } from '../Translation'

import './_cardContent.scss'

const CardContent = ({ content = {} }) => (
    <Card className="cardContent" option={['large', 'delay3']} utility={['anim']}>
        <CardHeading>
            <Translation value={content.name} />
        </CardHeading>
        {content.summary && (
            <section className="cardContent_section">
                <Content className="cardContent_block" utility={['margin-bot-small-y']}>
                    <Translation isHtml value={content.summary} />
                </Content>
            </section>
        )}
        {(content.content || content.image || content.details) && (
            <section className="cardContent_section">
                <Layout option={['gutter-small']}>
                    <LayoutItem utility={['1/2@from-small']}>
                        <CardHeading option={['noMargin']}>
                            <Translation value="planDetails.characteristics" />
                        </CardHeading>
                    </LayoutItem>
                    <LayoutItem utility={['1/2@from-small']}></LayoutItem>
                    <LayoutItem utility={['1/2@from-small']}>
                        {content.content && (
                            <Content className="cardContent_block" option={['marginTop']}>
                                <Translation isHtml value={content.content} />
                            </Content>
                        )}
                    </LayoutItem>
                    <LayoutItem utility={['1/2@from-small']}>
                        {(content.image || content.details) && (
                            <Content className="cardContent_block">
                                {content.image && <img src={content.image} alt="" />}
                                {content.details && <Translation isHtml value={content.details} />}
                            </Content>
                        )}
                    </LayoutItem>
                </Layout>
            </section>
        )}
    </Card>
)

export default CardContent
