import React from 'react'

import PropTypes from 'prop-types'

import { Icon } from '../Icon'

import './_button.scss'

const ButtonIcon = ({ name = null }) => {
    if (name !== null) {
        return (
            <span className="button_icon">
                <Icon name={name} />
            </span>
        )
    } else {
        return null
    }
}

ButtonIcon.propTypes = {
    name: PropTypes.string,
}

export default ButtonIcon
