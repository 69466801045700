import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import './_infoText.scss'

const InfoText = ({ children, ...classProps }) => (
    <div className={renderClasses('infoText', classProps)}>{children}</div>
)

InfoText.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

export default InfoText
