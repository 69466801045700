import React, { useEffect, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { FormFancyCheckbox, FormInput, FormLabel, FormSelect } from '../Form'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'
import { Translation } from '../Translation'

import defaultAvatar from '../../assets/images/person.svg'
import './_teamMemberSelector.scss'

const addItemToList = (setter, list, paramItem) => {
    setter([...list, paramItem])
}

const removeItemFromList = (setter, list, paramItem) => {
    setter(list.filter(currentItem => currentItem !== paramItem))
}

const selectableEmployeesMapper = employee => {
    return {
        ...employee,
        value: employee.id,
        label: employee.fullName,
    }
}

const TeamMemberSelector = ({
    employees = [],
    form,
    formId,
    initialEmployeesValue = [],
    initialProducersValue = [],
    initialContactsValue = [],
    translate,
    user,
    ...classProps
}) => {
    const [employeeIds, setEmployeeIds] = useState(initialEmployeesValue)
    const [producerIds, setProducerIds] = useState(initialProducersValue)
    const [contactIds, setContactIds] = useState(initialContactsValue)

    const isCreateProject = formId === 'create'
    const userId = user.id

    const addEmployee = event => {
        const selectedEmployeeId = event.currentTarget.value
        addItemToList(setEmployeeIds, employeeIds, selectedEmployeeId)
    }

    const removeEmployee = event => {
        const selectedEmployeeId = event.currentTarget.getAttribute('data-employee-id')
        removeItemFromList(setEmployeeIds, employeeIds, selectedEmployeeId)
        removeItemFromList(setContactIds, contactIds, selectedEmployeeId)
        removeItemFromList(setProducerIds, producerIds, selectedEmployeeId)
    }

    const updateProducers = selectedEmployeeId => {
        if (producerIds.includes(selectedEmployeeId)) {
            removeItemFromList(setProducerIds, producerIds, selectedEmployeeId)
        } else {
            setProducerIds([...producerIds, selectedEmployeeId])
        }
    }

    const updateContacts = selectedEmployeeId => {
        if (contactIds.includes(selectedEmployeeId)) {
            removeItemFromList(setContactIds, contactIds, selectedEmployeeId)
        } else {
            addItemToList(setContactIds, contactIds, selectedEmployeeId)
        }
    }

    const validateEmployees = (rule, value, callback) => {
        if (producerIds.length === 0) {
            callback(translate('projectForm.noProducersError'))
        } else {
            callback()
        }
    }

    // Update the <select /> options to enable/disable the associated employeeIds
    const selectableEmployees = employees
        .filter(employee => {
            return !employeeIds.includes(employee.id)
        })
        .map(employee => {
            return {
                ...employee,
                active: employee.active,
            }
        })

    // This effect manages default behaviour for this component when it's integrated in a creation form
    useEffect(() => {
        if (isCreateProject) {
            // Add the connected producer to all employee fields if we're creating a project
            const newEmployees = [userId]
            const newProducers = [userId]
            const newContacts = [userId]

            // Add owner employees as contacts
            employees.forEach(employee => {
                if (employee.isPartner) {
                    newEmployees.push(employee.id)
                    newContacts.push(employee.id)
                }
            })

            setEmployeeIds(newEmployees)
            setProducerIds(newProducers)
            setContactIds(newContacts)
        }
    }, [employees, isCreateProject, userId])

    return (
        <div className="teamMemberSelector">
            <div className="u-margin-small-y">
                <Layout option={['bottom', 'gutter-small']}>
                    <LayoutItem utility={['1/2@from-small']}>
                        <FormLabel inputId="projectForm.members" isRequired>
                            <Translation value="projectForm.members" />
                        </FormLabel>
                        <FormInput
                            id="projectForm.employees"
                            name="projectForm.employees"
                            form={form}
                            type="hidden"
                            fieldOptions={{
                                initialValue: employeeIds,
                                rules: [
                                    {
                                        required: true,
                                        message: translate('form.requiredField'),
                                    },
                                    {
                                        validator: validateEmployees,
                                    },
                                ],
                            }}
                        />
                        <FormInput
                            id="projectForm.producers"
                            name="projectForm.producers"
                            form={form}
                            type="hidden"
                            fieldOptions={{
                                initialValue: producerIds,
                            }}
                        />
                        <FormInput
                            id="projectForm.contacts"
                            name="projectForm.contacts"
                            form={form}
                            type="hidden"
                            fieldOptions={{
                                initialValue: contactIds,
                            }}
                        />
                        <FormSelect
                            id="projectForm.members"
                            name="projectForm.members"
                            form={form}
                            items={selectableEmployees}
                            itemsMapper={selectableEmployeesMapper}
                            fieldOptions={{
                                initialValue: '',
                            }}
                            defaultItem={{
                                label: translate('projectForm.member-list'),
                                value: '',
                            }}
                            onChange={addEmployee}
                        />
                    </LayoutItem>
                </Layout>
            </div>
            <div className="u-margin-y">
                <Layout option={['gutter-small']}>
                    <LayoutItem className="teamMemberSelector_column -large">
                        <Translation value="projectForm.members-table-heading-active" />
                    </LayoutItem>
                    <LayoutItem className="teamMemberSelector_column">
                        <Translation value="projectForm.members-table-heading-producer" />
                    </LayoutItem>
                    <LayoutItem className="teamMemberSelector_column">
                        <Translation value="projectForm.members-table-heading-support" />
                    </LayoutItem>
                </Layout>
                <ul className="teamMemberSelector_list">
                    {employees
                        .filter(employee => employeeIds.includes(employee.id))
                        .map((employee, index) => (
                            <li className="teamMemberSelector_list_item" key={`employee-${index}`}>
                                <Layout option={['middle', 'gutter-small']}>
                                    <LayoutItem className="teamMemberSelector_column -large">
                                        <p className="teamMemberSelector_list_item_name">
                                            <span className="teamMemberSelector_list_item_picture">
                                                <img
                                                    src={
                                                        employee.avatarUrl.url ? employee.avatarUrl.url : defaultAvatar
                                                    }
                                                    alt={employee.fullName}
                                                />
                                            </span>
                                            {employee.fullName}
                                        </p>
                                    </LayoutItem>
                                    <LayoutItem className="teamMemberSelector_column">
                                        <FormFancyCheckbox
                                            id={`${employee.id}-producer`}
                                            name={`${employee.id}-producer`}
                                            form={form}
                                            isChecked={producerIds.includes(employee.id)}
                                            label={employee.fullName}
                                            onChange={updateProducers}
                                            onChangeValue={employee.id}
                                        />
                                    </LayoutItem>
                                    <LayoutItem className="teamMemberSelector_column">
                                        <FormFancyCheckbox
                                            id={`${employee.id}-contact`}
                                            name={`${employee.id}-contact`}
                                            form={form}
                                            isChecked={contactIds.includes(employee.id)}
                                            label={employee.fullName}
                                            onChange={updateContacts}
                                            onChangeValue={employee.id}
                                        />
                                    </LayoutItem>
                                </Layout>
                                <button
                                    className="teamMemberSelector_list_item_button"
                                    onClick={removeEmployee}
                                    data-employee-id={employee.id}
                                    type="button"
                                >
                                    <Icon name="close" />
                                </button>
                            </li>
                        ))}
                </ul>
                {employeeIds.length === 0 && (
                    <div className="teamMemberSelector_list_item u-text-center">
                        <Translation value="projectForm.members-table-no-members" />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default withLocalize(connect(mapStateToProps)(TeamMemberSelector))
