import _ from 'lodash'
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import appRoutes from '../../config/routes.json'
import { localizePath, renderRoute, userCanDo } from '../../utils'

/**
 * Holds all the application's view components
 * Used by the resolver for magic-matching static route definitions.
 *
 * @type {Object}
 */
import * as appViews from '../../views'

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedRedirection = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => state.auth.user !== null,
    redirectPath: (state, ownProps) => {
        if (state.auth.hasLoggedOut) {
            return renderRoute('login')
        } else {
            return `${renderRoute('login')}?redirect=${ownProps.location.pathname}`
        }
    },
    wrapperDisplayName: 'UserIsAuthenticated',
})

const userIsNotAuthenticatedRedirection = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => state.auth.user === null,
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || renderRoute('project-list'),
    wrapperDisplayName: 'UserIsNotAuthenticated',
})

const userIsEmployeeAuthenticatedRedirection = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => state.auth.user !== null && state.auth.user.isEmployee,
    redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || renderRoute('project-list'),
    wrapperDisplayName: 'userIsEmployeeAuthenticated',
})

const projectListRedirect = connectedRouterRedirect({
    allowRedirectBack: false,
    authenticatedSelector: state => {
        // Remember :
        // true  = no redirect
        // false = redirect

        // Won't work without authentication!
        if (state.auth.user === null) {
            return false
        }
        // If user is a client...
        if (state.auth.user.isEmployee === false) {
            // ...redirect if they have a single project.
            if (state.auth.user.projectIds.length === 1) {
                return false
            }
        }
        return true
    },
    redirectPath: state => {
        return renderRoute({
            id: 'project-details',
            replacements: {
                projectId: state.auth.user.projectIds[0],
            },
        })
    },
    wrapperDisplayName: 'ProjectListSkip',
})

const userCanViewRoute = action => {
    return connectedAuthWrapper({
        authenticatedSelector: state => userCanDo(state.auth.user, action),
        FailureComponent: appViews['NotFoundView'],
    })
}

const wrapRouteComponent = (route, component) => {
    if (route.isAuthenticated) {
        if (route.id === 'project-list') {
            component = projectListRedirect(component)
        }
        if (typeof route.authRule === 'string') {
            component = userIsAuthenticatedRedirection(userCanViewRoute(route.authRule)(component))
        } else {
            component = userIsAuthenticatedRedirection(component)
        }

        if (route.isEmployee) {
            component = userIsEmployeeAuthenticatedRedirection(component)
        }
    } else if (route.isNotAuthenticated) {
        component = userIsNotAuthenticatedRedirection(component)
    }

    return component
}

/**
 * Create a collection of rendered 'react-router-dom' components that define the app's routing.
 *
 * @param  {Array} routes An array of all the app's route definitions.
 * @return {JSX}
 */
const createRoutes = routes => {
    // _.compact will remove falsy or null values fron our mapped array
    return _.compact(
        _.map(routes, (route, index) => {
            // Rudimentary support for redirection. Only supported in static route definitions
            // ...for now.
            if (route.isRedirect === true) {
                const redirectFrom = localizePath(route.path)
                const redirectTo = renderRoute(route.redirectTo)
                if (redirectTo) {
                    return <Redirect key={index} exact from={redirectFrom} to={redirectTo} />
                } else {
                    console.error(`Redirect could not interpret "${redirectFrom}".`)
                    return false
                }
            }

            /**
             * A component identifier.
             *
             * Determines the component to render for the currently interpreted route.
             * In its most basic usage, componentIdent will be a string identifying which
             * component, imported by `views/index.js`, we want to render.
             *
             * However, we also support function references, which basically amounts to doing:
             *
             * ```
             * import NewsListView from 'views'
             *
             * const routes = [
             *     {
             *         component: NewsListView
             *     }
             * ]
             * ```
             *
             * @type {String|Function}
             */
            const componentIdent = _.has(route, 'component')
                ? // When using the 'component' key, either a string identifier or an imported
                  // reference must be used.
                  // (ex.: "NewsListView" or function NewsListView )
                  route.component
                : // Magicly convert a route ID into a view component
                  // (ex.: "news-list" becomes "NewsListView")
                  _.upperFirst(_.camelCase(`${route.id}-view`))

            /**
             * The component to be rendered.
             *
             * A string componentIdent indicates that an identifier was given, elsewise we've
             * been given a function reference
             *
             * @type {Function}
             */
            const Component = wrapRouteComponent(
                route,
                _.isString(componentIdent) ? appViews[componentIdent] : componentIdent
            )

            // In any case, validate the component
            if (typeof Component === 'undefined') {
                console.error(`View ${componentIdent} could not be found.`)
                return false
            } else {
                // <Route /> render prop is ready to inject interesting props into the desired.
                // Any and all data can be given. You are encouraged to let your wildest dreams go free.
                const routeProps = _.has(route, 'props') ? route.props : {}
                return (
                    <Route
                        key={index}
                        exact
                        path={localizePath(route.path)}
                        render={routerProps => <Component {...routerProps} {...routeProps} />}
                    />
                )
            }
        })
    )
}

/**
 * Component that defines all the application's routes.
 *
 * @param  {Object} props
 * @return {JSX}
 */
const RouteResolver = () => (
    <Route
        render={({ location }) => (
            <TransitionGroup component={null}>
                <CSSTransition classNames="has-transition" timeout={1000} appear key={location.key}>
                    <Switch location={location}>
                        {createRoutes(appRoutes)}
                        <Route component={appViews['NotFoundView']} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        )}
    />
)

export default RouteResolver
