import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/fr'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { render } from 'react-dom'
import { renderToStaticMarkup } from 'react-dom/server'
import { addTranslationForLanguage, initialize } from 'react-localize-redux'
import { matchPath } from 'react-router-dom'
import svg4everybody from 'svg4everybody'

import { AUTH_SET_TOKEN, fetchSelf } from './actions/userActions'
import { fetchProject } from './actions/projectActions'
import routes from './config/routes.json'
import * as serviceWorker from './serviceWorker'
import { getHistory, getStore } from './store'
import enTranslations from './translations/en.json'
import frTranslations from './translations/fr.json'
import { translate } from './utils/l10n'
import { App } from './components/App'

const store = getStore()
const history = getHistory()

// Setting up localization.
const languages = process.env.REACT_APP_LOCALE_LIST.split(',')
const localeSlug = (function() {
    const slug = history.location.pathname.split('/')[1]
    return _.isEmpty(slug) || !languages.includes(slug) ? null : slug
})()
const defaultLanguage = localeSlug || process.env.REACT_APP_LOCALE_DEFAULT
store.dispatch(
    initialize({
        languages,
        options: {
            defaultLanguage,
            renderToStaticMarkup,
        },
    })
)
store.dispatch(addTranslationForLanguage(enTranslations, 'en'))
store.dispatch(addTranslationForLanguage(frTranslations, 'fr'))
moment.locale(defaultLanguage)

/**
 * Function that attempts to load the access token stored in state and save it to local storage.
 *
 * @param {Object} state
 */
const LOCALSTORAGE_TOKEN = 'token'
function setAuthState(state) {
    try {
        const token = JSON.stringify((state.auth || {}).token)
        localStorage.setItem(LOCALSTORAGE_TOKEN, token)
    } catch (error) {
        return undefined
    }
}

/** Subscribe to auth state changes to update local storage */
store.subscribe(() => {
    setAuthState(store.getState())
})

/** Save tokens in state before rendering the app. */
store.dispatch({
    type: AUTH_SET_TOKEN,
    token: null,
    ...(function() {
        try {
            return {
                token: JSON.parse(localStorage.getItem(LOCALSTORAGE_TOKEN)) || null,
            }
        } catch (error) {
            return {}
        }
    })(),
})

const pathname = store.getState().router.location.pathname
const projectDetailsRoute = _.find(routes, { id: 'project-details' })
const projectCreateRoute = _.find(routes, { id: 'project-create' })
const projectDetailsMatch = matchPath(pathname, translate(projectDetailsRoute.path))
const projectCreateMatch = matchPath(pathname, translate(projectCreateRoute.path))
const projectId =
    projectCreateMatch === null && projectDetailsMatch !== null ? projectDetailsMatch.params.projectId : null

// Requests to be made before rendering the app.
Promise.all([
    // Avoid querying user/self if no auth token is present.
    ...(store.getState().auth.token !== null ? [store.dispatch(fetchSelf())] : []),
    // Avoid querying project/:projectId if no project identifier is present in URL.
    ...(projectId ? [store.dispatch(fetchProject(projectId))] : []),
])
    .catch(error => {
        // Do nothing.
    })
    .finally(() => {
        svg4everybody()
        render(<App history={history} store={store} />, document.getElementById('root'))
    })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()
