import _ from 'lodash'

export const milestoneStatus = ({ isApproved = false, isCompleted = false, progress = 0 }) => ({
    isApproved: isApproved,
    isCompleted: isCompleted,
    isCurrent: progress < 100 && progress > 0,
    isUpcoming: progress === 0,
})

export const prepareTasks = tasks => {
    let previousTaskIsCompleted = true
    let previousTaskIsCurrent = false
    return tasks.map(task => {
        const output = {
            ...task,
            isCurrent:
                task.isCompleted === false && previousTaskIsCurrent === false && previousTaskIsCompleted === true,
        }
        previousTaskIsCompleted = task.isCompleted
        previousTaskIsCurrent = output.isCurrent
        return output
    })
}

export const isFirstTaskCurrent = (milestones, currentMilestoneId) => {
    const currentMilestoneIndex = _.findIndex(milestones, { id: currentMilestoneId })
    if (currentMilestoneIndex !== 0) {
        const previousMilestone = milestones[currentMilestoneIndex - 1]
        if (!previousMilestone.isCompleted) {
            return false
        }
    }
    return true
}
