import _ from 'lodash'
import { useCallback } from 'react'
import { connect } from 'react-redux'

import { CLOSE_HELP, OPEN_HELP, TOGGLE_HELP } from '../../actions/viewActions'

const HelpContainer = ({ articleId, dispatch, isOpen, project, render }) => {
    const handleClickFab = useCallback(() => {
        dispatch({
            type: TOGGLE_HELP,
        })
    }, [dispatch])

    const handleClickClose = useCallback(() => {
        dispatch({
            type: CLOSE_HELP,
        })
    }, [dispatch])

    const handleClickOpenContent = useCallback(
        event => {
            const id = event.currentTarget.getAttribute('data-item-id')
            dispatch({
                type: OPEN_HELP,
                articleId: _.isEmpty(id) ? null : id,
            })
        },
        [dispatch]
    )

    return render({ articleId, isOpen, project, handleClickFab, handleClickClose, handleClickOpenContent })
}

const mapStateToProps = state => ({
    articleId: state.view.help.articleId,
    isOpen: state.view.help.isOpen,
    project: state.currentProject.data,
})

export default connect(mapStateToProps)(HelpContainer)
