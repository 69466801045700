import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Container } from '../Container'
import { Heading } from '../Heading'
import { Section } from '../Section'
import { Button } from '../Button'
import { Content } from '../Content'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'
import { Translation } from '../Translation'

import './_help.scss'

const Help = ({ articleId = null, handleClickClose, handleClickOpenContent, isOpen = false, project }) => {
    const getDetails = articleId => {
        if (!articleId) {
            return null
        }

        const currentArticle = project.articles.find(article => article.id === articleId)

        if (!currentArticle) {
            return null
        }

        return (
            <CSSTransition classNames="is-transition" timeout={300}>
                <div className="help_container">
                    <Container>
                        <div className="help_content">
                            <Heading option={['h1']}>
                                <Translation value={currentArticle.name} />
                            </Heading>
                            {currentArticle.icon && (
                                <div className="help_image">
                                    <div className="help_icon">
                                        <Icon name={currentArticle.icon} />
                                    </div>
                                </div>
                            )}
                            <Content>
                                <Translation isHtml value={currentArticle.content} />
                            </Content>
                            <div className="help_button">
                                <Button
                                    option={['black', 'block', 'medium']}
                                    htmlAttrs={{
                                        'data-item-id': '',
                                    }}
                                    icon="prev"
                                    onClick={handleClickOpenContent}
                                >
                                    <Translation value="help.allQuestions" />
                                </Button>
                            </div>
                        </div>
                    </Container>
                </div>
            </CSSTransition>
        )
    }

    const items = project.articles.map(article => (
        <li className="help_item" key={article.id}>
            <button type="button" className="help_link" data-item-id={article.id} onClick={handleClickOpenContent}>
                <Heading el="span" option={['h1']}>
                    <Translation value={article.name} />
                </Heading>
            </button>
        </li>
    ))

    return (
        <aside className={classNames('help', { 'is-open': isOpen })}>
            <div className="help_overlay" onClick={handleClickClose}></div>
            <Layout option={['full']} utility={['text-right']}>
                <LayoutItem option={['contentLarge']}>
                    <div className="help_panel">
                        <Section>
                            <TransitionGroup>
                                <CSSTransition key={articleId} classNames="is-transition" timeout={300} appear>
                                    {articleId === null ? (
                                        <div className="help_container">
                                            <Container>
                                                <div className="help_content">
                                                    <Heading option={['h1']}>
                                                        <Translation value="help.heading" />
                                                    </Heading>
                                                    <ul className="help_list">{items}</ul>
                                                </div>
                                            </Container>
                                        </div>
                                    ) : (
                                        getDetails(articleId)
                                    )}
                                </CSSTransition>
                            </TransitionGroup>
                        </Section>
                    </div>
                </LayoutItem>
            </Layout>
        </aside>
    )
}

Help.propTypes = {
    articleId: PropTypes.string,
    handleClickClose: PropTypes.func.isRequired,
    handleClickOpenContent: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    project: PropTypes.object.isRequired,
}

export default Help
