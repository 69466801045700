import React from 'react'

import { renderClasses } from '../../utils'

import './_container.scss'

const Container = ({ children, el = 'div', ...classProps }) => {
    const El = el
    return <El className={renderClasses('container', classProps)}>{children}</El>
}

export default Container
