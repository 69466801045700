import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { ModalContainer } from '../Modal'
import { Translation } from '../Translation'

import { renderRoute } from '../../utils'

const NavActionsItem = ({ item }) => (
    <li className="navActions_nav_item">
        {item.route && (
            <RouterLink
                to={{
                    pathname: renderRoute(item.route),
                    hash: item.hash,
                }}
            >
                <Translation value={item.label} />
            </RouterLink>
        )}

        {item.href && (
            <a href={item.href}>
                <Translation value={item.label} />
            </a>
        )}

        {item.modalId && (
            <ModalContainer
                render={({ handleClickOpen }) => {
                    return (
                        <React.Fragment>
                            <button
                                onClick={handleClickOpen}
                                data-item-id={item.modalId}
                                data-item-data={JSON.stringify(item.data)}
                            >
                                <Translation value={item.label} />
                            </button>
                        </React.Fragment>
                    )
                }}
            />
        )}

        {item.onClick && (
            <button onClick={item.onClick}>
                <Translation value={item.label} />
            </button>
        )}
    </li>
)

NavActionsItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default NavActionsItem
