import React from 'react'

import PropTypes from 'prop-types'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { Translation } from '../Translation'

import './_lisa.scss'

const LisaStep = ({ currentStepIndex, onSelectChoice, step, hasLisaActive }) => (
    <TransitionGroup>
        <CSSTransition appear classNames="is-transition" timeout={900} key={currentStepIndex}>
            <div className="lisaStep">
                <div className="lisaStep_title">
                    <Translation value={step.title} />
                </div>
                {step.choices && (
                    <div className="lisaStep_choices">
                        {step.choices.map((choice, index) => (
                            <button
                                key={index}
                                type="button"
                                className="lisa_button"
                                data-action={choice.action}
                                onClick={onSelectChoice}
                            >
                                <span className="lisa_button_label -base">
                                    <Translation value={choice.label} />
                                </span>
                                <span className="lisa_button_label -hover">
                                    <Translation value={choice.label} />
                                </span>
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </CSSTransition>
    </TransitionGroup>
)

LisaStep.propTypes = {
    currentStepIndex: PropTypes.number.isRequired,
    onSelectChoice: PropTypes.func.isRequired,
    step: PropTypes.object.isRequired,
}

export default LisaStep
