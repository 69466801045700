import { getActiveLanguage } from 'react-localize-redux'

import { getStore } from '../store'

export const translate = data => {
    if (typeof data !== 'object' && typeof data !== 'string') {
        throw new Error('Invalid translation data format.')
    }

    if (typeof data === 'object') {
        const { code: lang } = getActiveLanguage(getStore().getState().localize)
        return data[lang]
    }

    return data
}
