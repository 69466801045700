import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchEmployees } from '../../actions/employeeActions'

import { LoadingError } from '../LoadingError'

const TeamMemberContainer = ({ dispatch, render, isLoading, entities, wasQueried, errors, data }) => {
    useEffect(() => {
        if (!wasQueried) {
            dispatch(fetchEmployees())
        }
    }, [dispatch, wasQueried])

    if (isLoading) {
        return null
    } else if (errors !== null) {
        console.warn(errors)
        return <LoadingError />
    } else {
        return render({ employees: entities })
    }
}

const mapStateToProps = ({ employees }) => ({
    ...employees,
})

export default connect(mapStateToProps)(TeamMemberContainer)
