import React from 'react'

import { fetchBlueprintProjects } from '../../actions/blueprintActions'
import { useFetchItemList } from '../../utils'

import { LoadingError } from '../LoadingError'

const ProjectBlueprintContainer = ({ render }) => {
    const { isLoading, items, error } = useFetchItemList(fetchBlueprintProjects)

    if (isLoading) {
        return null
    } else if (error !== null) {
        console.warn(error)
        return <LoadingError />
    } else {
        return render({ items })
    }
}

export default ProjectBlueprintContainer
