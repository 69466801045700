import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import { LinkIcon, LinkLabel } from '../Link'

import './_link.scss'

const LinkButton = ({ children, htmlAttrs = {}, icon = null, onClick, ...classProps }) => (
    <button onClick={onClick} type="button" className={renderClasses('link', classProps)} {...htmlAttrs}>
        <LinkIcon name={icon} />
        <LinkLabel>{children}</LinkLabel>
    </button>
)

LinkButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    htmlAttrs: PropTypes.object,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}

export default LinkButton
