import _ from 'lodash'
import React, { useEffect, useState, useRef } from 'react'
import { withLocalize } from 'react-localize-redux'

import { ButtonCircle } from '../Button'
import { FormError, FormInput, FormLabel, FormSelectMultiple } from '../Form'
import { Layout, LayoutItem } from '../Layout'
import { ModalContainer } from '../Modal'
import { Translation } from '../Translation'

const ClientSelector = ({
    clients = [],
    newClient,
    company,
    initialClientsValue = [],
    form,
    form: { setFieldsValue },
    formId,
    translate,
    ...classProps
}) => {
    const [clientIds, setClientIds] = useState(initialClientsValue)
    const allClients = _.filter(clients, client => {
        // return company === client.company.id
        return typeof _.find(client.companies, { id: company }) !== 'undefined'
    })

    // Save previously chosen company to detect when we need to empty clientIds
    const previousCompanyRef = useRef()
    useEffect(() => {
        previousCompanyRef.current = company
    })
    const previousCompany = previousCompanyRef.current

    // Empty client IDs when company changes
    useEffect(() => {
        if (company !== previousCompany && !_.isEmpty(previousCompany)) {
            setClientIds([])
        }
    }, [company, previousCompany])

    // Set client IDs when newClient changes
    useEffect(() => {
        if (!_.isEmpty(newClient)) {
            const values = [...clientIds, newClient.id]
            setClientIds(values)
            setFieldsValue({ 'projectForm.clients': values })
        }
    }, [newClient, setFieldsValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectOnChange = values => {
        setClientIds(values)
        form.setFieldsValue({ 'projectForm.clients': values })
    }

    return (
        <div className="clientSelector">
            <div className="u-margin-small-y">
                <Layout option={['bottom', 'gutter-small']}>
                    <LayoutItem utility={['1/2@from-small']}>
                        <FormLabel inputId="projectForm.clients" isRequired>
                            <Translation value="projectForm.clients" />
                        </FormLabel>
                        {/* Hidden input that saves data to form. */}
                        <FormInput
                            id="projectForm.clients.hidden"
                            name="projectForm.clients"
                            form={form}
                            type="hidden"
                            fieldOptions={{
                                initialValue: clientIds,
                                rules: [
                                    {
                                        required: true,
                                        message: translate('form.requiredField'),
                                    },
                                ],
                            }}
                        />
                        {/* Fake visible input. */}
                        <FormSelectMultiple
                            id="projectForm.clients"
                            itemsAsObjects={allClients}
                            valueIds={clientIds}
                            mapItemLabel={({ fullName }) => fullName}
                            handleOnChange={handleSelectOnChange}
                        />
                        <FormError>{form.getFieldError('projectForm.clients')}</FormError>
                    </LayoutItem>
                    <LayoutItem utility={['1/2@from-small']}>
                        <ModalContainer
                            render={({ handleClickOpen }) => (
                                <ButtonCircle
                                    icon="plus"
                                    option={['small']}
                                    htmlAttrs={{
                                        'data-item-id': 'clientDetails',
                                        'data-item-data': JSON.stringify({
                                            companyId: company,
                                        }),
                                    }}
                                    onClick={handleClickOpen}
                                />
                            )}
                        />
                    </LayoutItem>
                </Layout>
            </div>
        </div>
    )
}

export default withLocalize(ClientSelector)
