import React from 'react'

import { renderClasses } from '../../utils'

import './_card.scss'

const Card = ({ children, el = 'div', ...classProps }) => {
    const El = el
    return <El className={renderClasses('card', classProps)}>{children}</El>
}

export default Card
