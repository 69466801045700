import { apiClient } from './apiClient'
import { projectApiUrl } from './projectActions'

export const baseUrl = 'tasks'

function tasksApiUrl(projectId) {
    return `${projectApiUrl(projectId)}/${baseUrl}`
}

function taskApiUrl(projectId, taskId) {
    return `${tasksApiUrl(projectId)}/${taskId}`
}

/**
 * Create a Task from an project ID.
 */
export function createTask(projectId, formData, config = {}) {
    return apiClient.post(tasksApiUrl(projectId), formData, config)
}

/**
 * Update a task from an ID.
 */
export function updateTask(projectId, taskId, formData, config = {}) {
    return apiClient.patch(taskApiUrl(projectId, taskId), formData, config)
}

/**
 * Request client approval for a task.
 */
export function requestTaskApproval(projectId, taskId, config = {}) {
    return apiClient.post(`${taskApiUrl(projectId, taskId)}/request-approval`, config)
}
