import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getLanguages } from 'react-localize-redux'
import { useRouteMatch, Link as RouterLink } from 'react-router-dom'

import './_sidebar.scss'
import defaultAvatar from '../../assets/images/person.svg'
import { apiClient } from '../../actions'
import { logout } from '../../actions/userActions'
import { getCurrentRoute, renderRoute, userCanDo } from '../../utils'
import { Icon } from '../Icon'
import { Translation } from '../Translation'

const Sidebar = ({ isAuthenticated, handleLogout, languages = [], pathname, project = null, user }) => {
    const match = useRouteMatch()
    const currentRoute = getCurrentRoute(match.path)
    const languageRoutes = languages
        .filter(item => !item.active)
        .map(item => {
            return {
                lang: item.code,
                route: renderRoute(currentRoute.id, match.params, item.code),
                label: item.code === 'en' ? 'English version' : 'Version française',
            }
        })

    const apiUrl = new URL(`${process.env.REACT_APP_API_URL}/`)
    const cmsUrl = `${apiUrl.origin}/admin`
    const projectUrl =
        typeof project.id !== 'undefined'
            ? `${cmsUrl}/object/edit?obj_type=station/models/projects/workspace/project&obj_id=${project.id}`
            : cmsUrl

    const hasAvatar = !_.isEmpty(user) && !_.isEmpty(user.avatarUrl.url)
    const avatar = hasAvatar ? user.avatarUrl.url : defaultAvatar

    return (
        <aside className="sidebar">
            <div className="sidebar_section">
                <div className="sidebar_logo">
                    <Icon name="logo-station" title="Station logo" />
                </div>
                {isAuthenticated && (
                    <nav className="sidebar_nav -top">
                        <ul className="sidebar_nav_list">
                            {user.projectIds.length > 0 && (
                                <li className="sidebar_nav_item">
                                    <RouterLink
                                        className={classNames('sidebar_link', {
                                            '-active': pathname === renderRoute('project-list'),
                                        })}
                                        to={renderRoute('project-list')}
                                    >
                                        <div className="sidebar_link_icon">
                                            <Icon name="nav-projects" />
                                        </div>
                                        {user.projectIds.length > 1 || user.isEmployee ? (
                                            <>
                                                <span className="sidebar_link_label">
                                                    <Translation value="header.myProjects" />
                                                </span>
                                                <span className="sidebar_link_number">{user.projectIds.length}</span>
                                            </>
                                        ) : (
                                            <span className="sidebar_link_label">
                                                <Translation value="header.myProject" />
                                            </span>
                                        )}
                                    </RouterLink>
                                </li>
                            )}

                            {userCanDo(user, 'project.create') && (
                                <li className="sidebar_nav_item">
                                    <RouterLink
                                        className={classNames('sidebar_link', {
                                            '-active': pathname === renderRoute('project-create'),
                                        })}
                                        to={renderRoute('project-create')}
                                    >
                                        <div className="sidebar_link_icon">
                                            <Icon name="nav-create" />
                                        </div>
                                        <span className="sidebar_link_label">
                                            <Translation value="header.createProject" />
                                        </span>
                                    </RouterLink>
                                </li>
                            )}

                            <li className="sidebar_nav_item">
                                <RouterLink
                                    className={classNames('sidebar_link', {
                                        '-active': pathname === renderRoute('user-profile'),
                                    })}
                                    to={renderRoute('user-profile')}
                                >
                                    <div className="sidebar_link_icon">
                                        <Icon name="nav-profile" />
                                    </div>
                                    <span className="sidebar_link_label">
                                        <Translation value="header.myProfile" />
                                    </span>
                                </RouterLink>
                            </li>

                            {userCanDo(user, 'charcoal') && (
                                <li className="sidebar_nav_item">
                                    <a className="sidebar_link" href={projectUrl} rel="noreferrer" target="_blank">
                                        <div className="sidebar_link_icon">
                                            <Icon name="nav-charcoal" />
                                        </div>
                                        <span className="sidebar_link_label">
                                            <Translation value="header.charcoalAccess" />
                                        </span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </nav>
                )}
            </div>
            <div className="sidebar_section">
                <div className="sidebar_nav -bottom">
                    <ul className="sidebar_nav_list">
                        <li className="sidebar_nav_item">
                            {_.map(languageRoutes, (languageRoute, index) => (
                                <a className="sidebar_link" href={languageRoute.route} key={index}>
                                    <div className="sidebar_link_icon">
                                        <Icon name="nav-language" />
                                    </div>
                                    <span className="sidebar_link_label">{languageRoute.label}</span>
                                </a>
                            ))}
                        </li>
                        {isAuthenticated && (
                            <li className="sidebar_nav_item">
                                <button type="button" className="sidebar_link -red" onClick={handleLogout}>
                                    <div className="sidebar_link_icon">
                                        <Icon name="nav-disconnect" />
                                    </div>
                                    <span className="sidebar_link_label">
                                        <Translation value="header.logout" />
                                    </span>
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
                {isAuthenticated ? (
                    <div className="sidebar_user">
                        <img
                            className={classNames('sidebar_user_avatar', { '-default': !hasAvatar })}
                            src={avatar}
                            alt={user.fullName}
                        />
                        <div className="sidebar_user_text">
                            <h2 className="sidebar_user_name">{user.fullName}</h2>
                            <p className="sidebar_user_email">{user.emailAddress}</p>
                        </div>
                    </div>
                ) : (
                    <div className="sidebar_signature">
                        <p>Made by Locomotive.ca</p>
                    </div>
                )}
            </div>
        </aside>
    )
}

Sidebar.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    languages: PropTypes.array.isRequired,
    user: PropTypes.object,
}

const mapStateToProps = state => ({
    languages: getLanguages(state.localize),
    isAuthenticated: state.auth.isAuthenticated,
    pathname: state.router.location.pathname,
    project: state.currentProject.data,
    user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
    handleLogout: () => {
        apiClient.clearCache()
        dispatch(logout())
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
