import React from 'react'
import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import classNames from 'classnames'

import { renderClasses } from '../../utils'

import './_formInput.scss'
import './_formCheckbox.scss'

const TYPES = ['radio', 'checkbox']

const FormInputChoice = ({
    fieldOptions = { valuePropName: 'checked' },
    checked,
    form,
    id,
    isDisabled = false,
    label,
    name,
    type = 'radio',
    onChange = () => {},
    ...classProps
}) => {
    if (TYPES.indexOf(type) === -1) {
        throw new Error('Invalid input type for FormInputChoice.')
    }

    return (
        <>
            {form.getFieldDecorator(
                name,
                fieldOptions
            )(
                <input
                    id={id}
                    type={type}
                    className={renderClasses('formCheckbox', classProps)}
                    name={name}
                    checked={checked}
                    disabled={isDisabled}
                    onChange={onChange}
                />
            )}
            {label && (
                <label htmlFor={id} className={classNames('formCheckbox_label', `-${type}`)}>
                    {label}
                </label>
            )}
        </>
    )
}

FormInputChoice.propTypes = {
    fieldOptions: PropTypes.object,
    form: formShape,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
}

export default FormInputChoice
