import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import './_formLabel.scss'

const FormLabel = ({ children, inputId = null, isRequired = false, isDecorative = false, ...classProps }) => {
    const requiredMarkup = isRequired && <span className="formLabel_required">*</span>
    if (isDecorative) {
        return (
            <p className={renderClasses('formLabel', classProps)}>
                {children}
                {requiredMarkup}
            </p>
        )
    } else {
        return (
            <label className={renderClasses('formLabel', classProps)} htmlFor={inputId}>
                {children}
                {requiredMarkup}
            </label>
        )
    }
}

FormLabel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    inputId: PropTypes.string,
    isRequired: PropTypes.bool,
    isDecorative: PropTypes.bool,
}

export default FormLabel
