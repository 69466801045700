import _ from 'lodash'
import React from 'react'
import { default as ReactSelect } from 'react-select'
import PropTypes from 'prop-types'

import { renderClasses } from '../../utils'

import { Translation } from '../Translation'

import './_formSelect.scss'

const FormSelectMultiple = ({
    handleOnChange = () => {},
    id,
    valueIds = [],
    itemsAsObjects = [],
    mapItemLabel = ({ name }) => name,
    mapItemValue = ({ id }) => id,
    ...classProps
}) => {
    const onChange = valueArray => {
        valueArray = valueArray ? _.map(valueArray, mapItemValue) : []
        handleOnChange(valueArray)
    }

    const valueObjects = _.filter(itemsAsObjects, itemObject => {
        return _.includes(valueIds, itemObject.id)
    })

    return (
        <div className={renderClasses('formSelectMultiple', classProps)}>
            <ReactSelect
                id={id}
                placeholder={<Translation value="common.select" />}
                isMulti
                value={valueObjects}
                onChange={onChange}
                options={itemsAsObjects}
                closeMenuOnSelect={false}
                getOptionLabel={mapItemLabel}
                getOptionValue={mapItemValue}
            />
        </div>
    )
}

FormSelectMultiple.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array,
    mapItemLabel: PropTypes.func,
    mapItemValue: PropTypes.func,
}

export default FormSelectMultiple
