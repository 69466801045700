import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

import { userCanDo } from '../../utils'

import { Card } from '../Card'
import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { Layout, LayoutItem } from '../Layout'
import { LinkButton } from '../Link'
import { ModalContainer } from '../Modal'
import { Translation } from '../Translation'

import './_cardList.scss'

const AVAILABLE_ICONS = [
    'url',
    'file',
    'harvest',
    'teamwork-project',
    'teamwork-desk',
    'bitbucket',
    'github',
    'gitlab',
    'dropbox',
    'google-drive',
    'slickplan',
    'invision',
    'sketch',
    'figma',
]

const CardList = ({ showButtons = false, footerCta = null, heading = null, group = null, items = [], user }) => {
    const isSingle = items.length === 1

    return (
        <Card className={classNames('cardList', { '-single': isSingle })} option={['small']}>
            {heading && (
                <Layout>
                    <LayoutItem utility={['1/2']}>
                        <Heading el="h3" option={['h6']}>
                            <Translation value={heading} />
                        </Heading>
                    </LayoutItem>
                    {userCanDo(user, 'project.update') && showButtons && (
                        <LayoutItem utility={['1/2', 'text-right']}>
                            <ModalContainer
                                render={({ handleClickOpen }) => (
                                    <React.Fragment>
                                        <button
                                            className="cardList_edit"
                                            onClick={handleClickOpen}
                                            data-item-id="resourceDetails"
                                            data-item-data={`${JSON.stringify({
                                                group: group,
                                            })}`}
                                            type="button"
                                        >
                                            <Icon name="plus" onClick={handleClickOpen} />
                                        </button>

                                        {items.length > 0 && (
                                            <span className="u-margin-tiny-x">
                                                <button
                                                    className="cardList_edit"
                                                    onClick={handleClickOpen}
                                                    data-item-id="resourceList"
                                                    data-item-data={`${JSON.stringify({
                                                        group: group,
                                                        items: items,
                                                    })}`}
                                                    type="button"
                                                >
                                                    <Icon name="edit-simple" onClick={handleClickOpen} />
                                                </button>
                                            </span>
                                        )}
                                    </React.Fragment>
                                )}
                            />
                        </LayoutItem>
                    )}
                </Layout>
            )}
            <ul className="cardList_list">
                {items.map(item => {
                    // const isUrl = item.type.icon === 'url'
                    const iconName = AVAILABLE_ICONS.includes(item.type.icon) ? `app-${item.type.icon}` : 'close'
                    return (
                        <li key={item.id}>
                            <a
                                className={classNames('cardList_link')}
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <span className="cardList_main">
                                    <span className="cardList_icon">
                                        <Icon name={iconName} />
                                    </span>

                                    <Heading el="span" option={['h3']}>
                                        {item.name ? <Translation value={item.name} /> : item.url}
                                    </Heading>
                                </span>
                                <span className="cardList_category">
                                    {item.milestone && (
                                        <React.Fragment>
                                            <Translation value={item.milestone.name} /> <br />
                                        </React.Fragment>
                                    )}
                                    <span className="cardList_date">
                                        <Translation value="resources.added" />{' '}
                                        {moment(item.createdAt).format('MMM DD, YYYY')}
                                    </span>
                                </span>
                            </a>
                        </li>
                    )
                })}
            </ul>
            {footerCta !== null && (
                <div className="cardLink_help">
                    <LinkButton
                        htmlAttrs={{
                            'data-item-id': footerCta.id,
                        }}
                        onClick={footerCta.onClick}
                        option={['blue']}
                    >
                        <Translation value={footerCta.label} />
                    </LinkButton>
                </div>
            )}
        </Card>
    )
}
CardList.propTypes = {
    heading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    items: PropTypes.array,
    footerCta: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        id: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
    }),
    showButtons: PropTypes.bool,
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(CardList)
