import PropTypes from 'prop-types'
import React from 'react'

import { renderClasses } from '../../utils'

import './_heading.scss'

const Heading = ({ children, el = 'p', ...classProps }) => {
    const El = el
    return <El className={renderClasses('heading', classProps)}>{children}</El>
}

Heading.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
    el: PropTypes.string,
}

export default Heading
