import { useCallback } from 'react'

export const useReorderItems = (items, setItems) => {
    const reorderItems = useCallback(
        event => {
            const direction = event.currentTarget.getAttribute('data-direction')
            const currentPosition = parseInt(event.currentTarget.getAttribute('data-current-position'))
            const newItems = [...items]
            const newPosition = direction === 'up' ? currentPosition - 1 : currentPosition + 1
            const currentItem = items[currentPosition]
            newItems.forEach((newItem, index) => {
                if (index === currentPosition) {
                    newItems.splice(currentPosition, 1)
                }
            })
            newItems.splice(newPosition, 0, currentItem)
            newItems.forEach((newItem, index) => {
                newItems[index].position = index
            })
            setItems(newItems)
        },
        [items, setItems]
    )
    return reorderItems
}

export const useRemoveItem = (items, setItems) => {
    const removeItem = useCallback(
        (event, data) => {
            const newItems = items.map(item => {
                if (item.id === data.itemId) {
                    return {
                        ...item,
                        active: false,
                    }
                }
                return item
            })
            setItems(newItems)
        },
        [items, setItems]
    )
    return removeItem
}
