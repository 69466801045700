import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { renderClasses, renderRoute } from '../../utils'
import { Card, CardMain } from '../Card'
import { Heading } from '../Heading'
import { ButtonLink } from '../Button'
import { Timeline, TimelineItem } from '../Timeline'
import { Translation } from '../Translation'
import { Pointer } from '../Pointer'
import { ButtonLabel } from '../Button'

import './_cardPreview.scss'

const prepareMilestones = milestones => {
    let previousMilestoneIsCompleted = true
    let previousMilestoneIsCurrent = false
    return milestones.map(milestone => {
        const output = {
            ...milestone,
            isCurrent:
                milestone.isCompleted === false &&
                previousMilestoneIsCurrent === false &&
                previousMilestoneIsCompleted === true,
        }
        previousMilestoneIsCompleted = milestone.isCompleted
        previousMilestoneIsCurrent = output.isCurrent
        return output
    })
}

const CardPreview = ({ project, ...classProps }) => (
    <section className={renderClasses('cardPreview', classProps)}>
        <Card option={['header']}>
            {(project.launchDate !== null || project.timeline !== null) && (
                <header className="cardPreview_header">
                    {project.launchDate !== null && (
                        <div>
                            <span className="cardPreview_header_title">
                                <Translation value="projectDetails.launch" />
                            </span>
                            <br />
                            {moment(project.launchDate).format('YYYY-MM-DD')}
                        </div>
                    )}
                    {project.timeline !== null && (
                        <div className="u-text-right">
                            <ButtonLabel href={project.timeline.downloadUrl} isBlank icon="gant">
                                <Translation value="common.planning" />
                            </ButtonLabel>
                            {project.timeline.revisionDate !== null && (
                                <p className="cardPreview_header_date">
                                    <Translation value="projectDetails.updated" />
                                    {` ${moment(project.timeline.revisionDate).format('MMM DD, YYYY')}`}
                                </p>
                            )}
                        </div>
                    )}
                </header>
            )}
            <CardMain>
                <div className="cardPreview_main">
                    <Timeline>
                        {prepareMilestones(project.milestones).map(milestone => (
                            <TimelineItem
                                completion={Math.round((milestone.completedTaskCount / milestone.taskCount) * 100)}
                                completionLabel={`${milestone.completedTaskCount}/${milestone.taskCount}`}
                                isCompleted={milestone.isCompleted}
                                isCurrent={milestone.isCurrent}
                                key={milestone.id}
                            >
                                <RouterLink
                                    className="cardPreview_link"
                                    to={renderRoute('milestone-details', {
                                        projectId: project.id,
                                        milestoneId: milestone.id,
                                    })}
                                >
                                    <Heading option={['h3', 'medium']}>
                                        <Translation value={milestone.name} />
                                    </Heading>
                                </RouterLink>
                            </TimelineItem>
                        ))}
                    </Timeline>
                    <Pointer
                        ident={2}
                        position="right"
                        align="centerY"
                        mobilePosition="top"
                        mobileAlign="left"
                        scrollTo={true}
                    />
                </div>
                {project.currentMilestone.id !== null && (
                    <ButtonLink
                        option={['black', 'min']}
                        route={{
                            id: 'milestone-details',
                            replacements: {
                                projectId: project.id,
                                milestoneId: project.currentMilestone.id,
                            },
                        }}
                    >
                        <Translation value="projectDetails.viewTasks" />
                    </ButtonLink>
                )}
            </CardMain>
        </Card>
    </section>
)

CardPreview.propTypes = {
    project: PropTypes.object.isRequired,
}

export default CardPreview
