import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'

import { renderRoute } from '../utils'
import { AuthView } from '../components/AuthView'
import { ButtonLink } from '../components/Button'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { Layout, LayoutItem } from '../components/Layout'
import { ProjectContainer, ProjectForm } from '../components/Project'
import { QuickNav } from '../components/QuickNav'
import { Section, SectionHeader } from '../components/Section'
import { Translation } from '../components/Translation'

const ProjectEditView = ({ user }) => {
    const { projectId } = useParams()
    const [redirectToProject, setRedirectToProject] = useState(null)
    const [quickNavItems, setQuickNavItems] = useState([
        {
            label: <Translation value="projectForm.form1Title" />,
            scrollTo: '#project-form-1',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form2Title" />,
            scrollTo: '#project-form-2',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form3Title" />,
            scrollTo: '#project-form-3',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form4Title" />,
            scrollTo: '#project-form-4',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form5Title" />,
            scrollTo: '#project-form-5',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form6Title" />,
            scrollTo: '#project-form-6',
            displayed: false,
        },
    ])

    const updateQuickNav = useCallback(() => {
        const newQuickNavItems = quickNavItems.map(navItem => {
            return {
                ...navItem,
                displayed: true,
            }
        })
        setQuickNavItems(newQuickNavItems)
    }, [quickNavItems])

    if (redirectToProject) {
        return <Redirect to={renderRoute({ id: 'project-details', replacements: { projectId: redirectToProject } })} />
    }

    return (
        <AuthView option={['landing', 'projectForm']}>
            <ProjectContainer
                projectId={projectId}
                render={({ project }) => (
                    <Layout option={['center']}>
                        <QuickNav items={quickNavItems} />
                        <LayoutItem option={['contentSmall']}>
                            <Container>
                                <Section>
                                    <div className="u-margin-bottom-y u-anim -delay1">
                                        <ButtonLink
                                            route={{
                                                id: 'project-details',
                                                replacements: { projectId: projectId },
                                            }}
                                            option={['blue']}
                                        >
                                            <Translation value="userProfile.returnProject" />
                                        </ButtonLink>
                                    </div>
                                    <SectionHeader>
                                        <Layout>
                                            <LayoutItem utility={['5/8@from-small']}>
                                                <Heading option={['h1', 'delay2']} utility={['anim']}>
                                                    <Translation value="projectForm.pageHeader-edit" />
                                                </Heading>
                                            </LayoutItem>
                                        </Layout>
                                    </SectionHeader>
                                    <ProjectForm
                                        formId="update"
                                        project={project}
                                        projectSubmitted={setRedirectToProject}
                                        updateQuickNav={updateQuickNav}
                                    />
                                </Section>
                            </Container>
                        </LayoutItem>
                    </Layout>
                )}
            />
        </AuthView>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ProjectEditView)
