import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { renderRoute } from '../utils'
import { AuthView } from '../components/AuthView'
import { ButtonLink } from '../components/Button'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { Layout, LayoutItem } from '../components/Layout'
import { ProjectForm } from '../components/Project'
import { QuickNav } from '../components/QuickNav'
import { Section, SectionHeader } from '../components/Section'
import { Translation } from '../components/Translation'

const ProjectCreateView = ({ user }) => {
    const [redirectToProject, setRedirectToProject] = useState(null)
    const [quickNavItems, setQuickNavItems] = useState([
        {
            label: <Translation value="projectForm.form1Title" />,
            scrollTo: '#project-form-1',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form2Title" />,
            scrollTo: '#project-form-2',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form3Title" />,
            scrollTo: '#project-form-3',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form4Title" />,
            scrollTo: '#project-form-4',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form5Title" />,
            scrollTo: '#project-form-5',
            displayed: true,
        },
        {
            label: <Translation value="projectForm.form6Title" />,
            scrollTo: '#project-form-6',
            displayed: false,
        },
    ])

    const updateQuickNav = useCallback(() => {
        const newQuickNavItems = quickNavItems.map(navItem => {
            return {
                ...navItem,
                displayed: true,
            }
        })
        setQuickNavItems(newQuickNavItems)
    }, [quickNavItems])

    const hasMultipleProjects = user.projectIds.length > 1

    return !_.isEmpty(redirectToProject) ? (
        <Redirect to={renderRoute({ id: 'project-details', replacements: { projectId: redirectToProject } })} />
    ) : (
        <AuthView option={['landing', 'projectForm']}>
            <Layout option={['center']}>
                <QuickNav items={quickNavItems} />

                <LayoutItem option={['contentSmall']}>
                    <Container>
                        <Section>
                            <div className="u-margin-bottom-y u-anim -delay1">
                                <ButtonLink route="project-list" option={['blue']}>
                                    {hasMultipleProjects ? (
                                        <Translation value="userProfile.returnProjects" />
                                    ) : (
                                        <Translation value="userProfile.returnProject" />
                                    )}
                                </ButtonLink>
                            </div>
                            <SectionHeader>
                                <Layout>
                                    <LayoutItem utility={['5/8@from-small']}>
                                        <Heading option={['h1', 'delay2']} utility={['anim']}>
                                            <Translation value="projectForm.pageHeader-create" />
                                        </Heading>
                                    </LayoutItem>
                                </Layout>
                            </SectionHeader>
                            <ProjectForm projectSubmitted={setRedirectToProject} updateQuickNav={updateQuickNav} />
                        </Section>
                    </Container>
                </LayoutItem>
            </Layout>
        </AuthView>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(ProjectCreateView)
