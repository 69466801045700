import React from 'react'

import { renderClasses } from '../../utils'

import './_layout.scss'

const Layout = ({ children, el = 'div', ...classProps }) => {
    const El = el
    return <El className={renderClasses('layout', classProps)}>{children}</El>
}

export default Layout
