import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Pointer } from '../Pointer'
import { Button } from '../Button'
import { Icon } from '../Icon'

import './_fab.scss'

const Fab = ({ handleClick, isOpen = false }) => (
    <div className={classNames('fab', { 'is-open': isOpen })}>
        <div className="u-relative">
            <Button option={['circle', 'green', 'shadow']} onClick={handleClick}>
                <div className="fab_icon">
                    <Icon name="question" />
                </div>
                <div className="fab_icon -hidden">
                    <Icon name="close" />
                </div>
            </Button>

            <Pointer ident={3} position="left" mobilePosition="top" align="bottom" mobileAlign="right" />
        </div>
    </div>
)

Fab.propTypes = {
    handleClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
}

export default Fab
