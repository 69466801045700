import React, { useCallback, useReducer } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { Card, CardHeading } from '../components/Card'
import { CardVideo } from '../components/CardVideo'
import { Container } from '../components/Container'
import { Heading } from '../components/Heading'
import { InfoText } from '../components/InfoText'
import { Layout, LayoutItem } from '../components/Layout'
import { LightboxVideo } from '../components/LightboxVideo'
import { Panel, PanelContainer, PanelFooter } from '../components/Panel'
import { ProjectContainer } from '../components/Project'
import { Section, SectionHeader } from '../components/Section'
import { SupportNav } from '../components/SupportNav'
import { Translation } from '../components/Translation'
import { View } from '../components/View'

const initialState = {
    currentVideoId: null,
    currentVideoPlatform: null,
    isLightboxOpen: false,
}

const OPEN_LIGHTBOX = 'OPEN_LIGHTBOX'
const CLOSE_LIGHTBOX = 'CLOSE_LIGHTBOX'

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LIGHTBOX:
            return {
                ...state,
                currentVideoId: action.payload.videoId,
                currentVideoPlatform: action.payload.platform,
                isLightboxOpen: true,
            }
        case CLOSE_LIGHTBOX:
            return {
                ...initialState,
            }
        default:
            throw new Error('Invalid action type')
    }
}

const TutorialListView = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const { pathname } = useLocation()
    const { projectId } = useParams()

    const handleLightboxVideoClickClose = useCallback(
        event => {
            dispatch({ type: CLOSE_LIGHTBOX })
        },
        [dispatch]
    )

    const handleCardVideoClick = useCallback(
        event => {
            const currentTarget = event.currentTarget
            dispatch({
                type: OPEN_LIGHTBOX,
                payload: {
                    videoId: currentTarget.getAttribute('data-video-id'),
                    platform: currentTarget.getAttribute('data-platform'),
                },
            })
        },
        [dispatch]
    )

    return (
        <div>
            <ProjectContainer
                projectId={projectId}
                render={({ project }) => (
                    <View>
                        <Layout>
                            <LayoutItem option={['panelSmall']}>
                                <Panel option={['small']}>
                                    <PanelContainer>
                                        <Section>
                                            <div className="panel_header">
                                                <Heading el="h1" option={['h1', 'delay1']} utility={['anim']}>
                                                    <Translation value="support.header" />
                                                </Heading>
                                            </div>
                                            <SupportNav pathname={pathname} project={project} />
                                        </Section>
                                    </PanelContainer>
                                    <PanelFooter project={project} option={['delay3']} utility={['anim']} />
                                </Panel>
                            </LayoutItem>
                            <LayoutItem option={['contentSmall']}>
                                <Container>
                                    <Section>
                                        <SectionHeader>
                                            <Layout>
                                                <LayoutItem utility={['5/8@from-small']}>
                                                    <Heading el="h2" option={['h1', 'delay1']} utility={['anim']}>
                                                        <Translation value="tutorialList.pageHeader" />
                                                    </Heading>
                                                </LayoutItem>
                                                <LayoutItem utility={['3/8@from-small']}>
                                                    <InfoText option={['delay1']} utility={['anim']}>
                                                        <Translation value="tutorialList.infoText" />
                                                    </InfoText>
                                                </LayoutItem>
                                            </Layout>
                                        </SectionHeader>
                                        <Card option={['large', 'delay3']} utility={['anim']}>
                                            <CardHeading>
                                                {project.tutorials.length}{' '}
                                                <Translation value="tutorialList.videosTitle" />
                                            </CardHeading>
                                            <Layout option={['gutter-small']}>
                                                {project.tutorials.map(item => (
                                                    <LayoutItem utility={['1/2@from-small']} key={item.id}>
                                                        <CardVideo video={item} onClick={handleCardVideoClick} />
                                                    </LayoutItem>
                                                ))}
                                            </Layout>
                                        </Card>
                                    </Section>
                                </Container>
                            </LayoutItem>
                        </Layout>
                    </View>
                )}
            />
            {state.isLightboxOpen === true && (
                <LightboxVideo
                    isOpen
                    onClose={handleLightboxVideoClickClose}
                    platform={state.currentVideoPlatform}
                    videoId={state.currentVideoId}
                />
            )}
        </div>
    )
}

export default TutorialListView
