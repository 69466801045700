import React from 'react'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux'
// import { getLanguages } from 'react-localize-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// import { apiClient } from '../../actions'
// import { logout } from '../../actions/userActions'
import { renderClasses } from '../../utils'
import { Sidebar } from '../Sidebar'

import './_page.scss'

const Page = ({ children, ...classProps }) => (
    <div className={renderClasses('page', classProps)}>
        <aside className="page_sidebar">
            <Sidebar />
        </aside>
        <main className="page_main">{children}</main>
        <ToastContainer position="bottom-right" hideProgressBar draggable={false} theme="colored" />
    </div>
)

Page.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]).isRequired,
}

// const mapStateToProps = state => ({
//     languages: getLanguages(state.localize),
//     isAuthenticated: state.auth.isAuthenticated,
//     // pathname: state.router.location.pathname,
//     // project: state.currentProject.data,
//     user: state.auth.user,
// })

// const mapDispatchToProps = dispatch => ({
//     handleLogout: () => {
//         apiClient.clearCache()
//         dispatch(logout())
//     },
// })

export default Page
// export default connect(mapStateToProps, mapDispatchToProps)(Page)
