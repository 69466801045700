import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchCompanies } from '../../actions/companyActions'

import { LoadingError } from '../LoadingError'

const CompanyContainer = ({ dispatch, render, isLoading, entities, newCompany, wasQueried, errors, data }) => {
    useEffect(() => {
        if (!wasQueried) {
            dispatch(fetchCompanies())
        }
    }, [dispatch, wasQueried])

    if (isLoading) {
        return null
    } else if (errors !== null) {
        console.warn(errors)
        return <LoadingError />
    } else {
        return render({ companies: entities, newCompany })
    }
}

const mapStateToProps = ({ companies }) => ({
    ...companies,
})

export default connect(mapStateToProps)(CompanyContainer)
