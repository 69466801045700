import classNames from 'classnames'
import _ from 'lodash'
import { createForm } from 'rc-form'
import React, { useCallback, useState } from 'react'
import { withLocalize } from 'react-localize-redux'

import { createLostPasswordToken } from '../actions/userActions'
import { formatFormNotifications } from '../utils/forms'
import { Button } from '../components/Button'
import { Card, CardFooter, CardHeading, CardMain } from '../components/Card'
import { Form, FormError, FormInput, FormItem, FormLabel, FormNotifications } from '../components/Form'
import { Link } from '../components/Link'
import { Page } from '../components/Page'
import { Translation } from '../components/Translation'

const LoginResetPasswordView = ({ form, form: { getFieldError, validateFields }, translate }) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const handleSubmit = useCallback(
        event => {
            event.preventDefault()

            if (isLoading || isSubmitted) {
                return false
            }

            validateFields(async (formError, formValues) => {
                if (!formError) {
                    setIsLoading(true)
                    createLostPasswordToken({
                        ...formValues,
                    })
                        .then(response => {
                            setErrors([])
                            setIsLoading(false)
                            setIsSubmitted(true)
                        })
                        .catch(error => {
                            setErrors(error.response.data)
                            setIsLoading(false)
                        })
                }
            })

            return false
        },
        [isLoading, isSubmitted, setErrors, setIsLoading, setIsSubmitted, validateFields]
    )

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors
        ? formatFormNotifications(errors)
        : isSubmitted
        ? [
              {
                  message: translate('resetPasswordForm.confirmation'),
              },
          ]
        : []
    const hasNotifications = notifications.length > 0

    return (
        <Page option={['login']}>
            <Card option={['auth']}>
                <CardHeading>
                    <Translation value="resetPasswordForm.heading" />
                </CardHeading>
                <CardMain>
                    {hasNotifications && (
                        <FormItem className={classNames({ '-no-margin': isSubmitted })}>
                            <FormNotifications hasErrors={hasErrors} hasSuccess={isSubmitted} items={notifications} />
                        </FormItem>
                    )}
                    {!isSubmitted && (
                        <Form onSubmit={handleSubmit}>
                            <FormItem className={classNames({ 'has-error': !!getFieldError('email') })}>
                                <FormLabel inputId="email" option={['small']}>
                                    <Translation value="form.email" />
                                </FormLabel>
                                <FormInput
                                    form={form}
                                    id="email"
                                    name="email"
                                    type="email"
                                    fieldOptions={{
                                        initialValue: '',
                                        rules: [
                                            {
                                                required: true,
                                                message: translate('form.emailMissing'),
                                            },
                                        ],
                                    }}
                                    option={['small', 'blue-light']}
                                />
                                <FormError>{getFieldError('email')}</FormError>
                            </FormItem>
                            <Button
                                className={classNames({ 'is-loading': isLoading })}
                                hasWrapper
                                option={['black', 'block', 'spinner']}
                                type="submit"
                            >
                                <Translation value="resetPasswordForm.submit" />
                            </Button>
                        </Form>
                    )}
                </CardMain>
                <CardFooter>
                    <Link route="login">
                        <Translation value="loginForm.heading" />
                    </Link>
                </CardFooter>
            </Card>
        </Page>
    )
}

export default withLocalize(createForm()(LoginResetPasswordView))
