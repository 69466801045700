import { apiClient } from './apiClient'
import { baseUrl as projectsBaseUrl } from './projectActions'

export const baseUrl = 'resource-groups'

/**
 * Create a Resource Group from an project ID.
 */
export function createResourceGroup(projectId, formData, config = {}) {
    return apiClient.post(`${projectsBaseUrl}/${projectId}/${baseUrl}`, formData, config)
}

/**
 * Update a Resource Group from an project ID.
 */
export function updateResourceGroup(projectId, groupId, formData, config = {}) {
    return apiClient.patch(`${projectsBaseUrl}/${projectId}/${baseUrl}/${groupId}`, formData, config)
}
