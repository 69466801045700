import {
    CREATE_PROJECT_SUCCESS,
    FETCH_PROJECT_LIST_REQUEST,
    FETCH_PROJECT_LIST_SUCCESS,
    FETCH_PROJECT_LIST_FAILURE,
    REFRESH_PROJECT_LIST_SUCCESS,
} from '../actions/projectActions'

const initialState = {
    entities: [],
    errors: null,
    isLoading: true,
    wasQueried: false,
}

export function projects(state = initialState, action) {
    switch (action.type) {
        case FETCH_PROJECT_LIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                errors: null,
            }
        case FETCH_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                entities: action.result.data,
                errors: null,
                isLoading: false,
                wasQueried: true,
            }
        case FETCH_PROJECT_LIST_FAILURE:
            return {
                ...initialState,
                errors: action.error.response.data,
                isLoading: false,
                wasQueried: true,
            }
        case CREATE_PROJECT_SUCCESS:
            return {
                ...state,
                entities: [action.result.data, ...state.entities],
            }
        case REFRESH_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                entities: action.result.data,
            }
        default:
            return state
    }
}
