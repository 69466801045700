import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import { Circle } from '../Circle'
import { Icon } from '../Icon'

const TimelineItem = ({ children, completion = 100, completionLabel, isCompleted = false, isCurrent = false }) => (
    <div
        className={classNames('timeline_item', {
            'is-upcoming': isCurrent === false && isCompleted === false,
            'is-current': isCurrent,
            'is-done': isCompleted,
        })}
    >
        <div className="timeline_item_dot">
            {isCompleted && <Icon name="check" />}
            {isCurrent && (
                <div className="timeline_item_dot_wrap">
                    <Circle
                        progress={completion}
                        lineWidth="20"
                        progressColor="white"
                        bgColor="transparent"
                        roundedStroke={true}
                        showPercentage={false}
                    />
                    <span className="timeline_item_dot_text">{completionLabel}</span>
                </div>
            )}
        </div>
        <div className="timeline_item_content">{children}</div>
    </div>
)

TimelineItem.propTypes = {
    completion: PropTypes.number,
    isCompleted: PropTypes.bool,
    isCurrent: PropTypes.bool,
}

export default TimelineItem
