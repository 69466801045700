import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import { NavActionsItem } from '../NavActions'
import { Translation } from '../Translation'

import { renderClasses } from '../../utils'

import './_navActions.scss'

const NavActions = ({ items = [], ...classProps }) => {
    const [isOpen, setIsOpen] = useState(false)

    const handleToggleIsOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen, setIsOpen])

    const onClickOutsideHandler = useCallback(
        event => {
            if (isOpen) {
                setIsOpen(false)
            }
        },
        [setIsOpen, isOpen]
    )

    useEffect(() => {
        window.addEventListener('click', onClickOutsideHandler)

        return () => {
            window.removeEventListener('click', onClickOutsideHandler)
        }
    })

    return (
        <div className={renderClasses(classNames('navActions', { 'is-open': isOpen }), classProps)}>
            <button className="navActions_button" onClick={handleToggleIsOpen} type="button">
                <span className="u-screen-reader-text">
                    <Translation value="common.open" />
                </span>
                <span className="navActions_button_dots"></span>
            </button>
            <nav className="navActions_nav">
                <ul className="navActions_nav_list">
                    {items.map(item => (
                        <NavActionsItem item={item} key={item.label} />
                    ))}
                </ul>
            </nav>
        </div>
    )
}

NavActions.propTypes = {
    items: PropTypes.array,
}

export default NavActions
