import classNames from 'classnames'
import Immutable from 'immutable'
import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'

import { createProject, updateProject } from '../../actions/projectActions'
import { formatFormNotifications, translate as utilTranslate } from '../../utils'

import { Button, ButtonLink } from '../Button'
import { CardHeading } from '../Card'
import { CompanyContainer, CompanySelector } from '../Company'
import { ClientContainer, ClientSelector } from '../Client'
import {
    FormContainer,
    FormError,
    FormInput,
    FormInputFile,
    FormItem,
    FormLabel,
    FormNotifications,
    FormTextarea,
} from '../Form'
import { Layout, LayoutItem } from '../Layout'
import { ProjectBlueprintContainer, ProjectBlueprintSelector } from '../Project'
import { TeamMemberContainer, TeamMemberSelector } from '../TeamMember'
import { Translation } from '../Translation'

const ProjectForm = ({
    dispatch,
    formId = 'create',
    project = {},
    projectSubmitted = () => {},
    translate,
    updateQuickNav = () => {},
    user,
}) => {
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [initialValues, setInitialValues] = useState(Immutable.Map({}))
    const [timelineFile, setTimelineFile] = useState(null)

    const isEdit = !_.isEmpty(project) ? true : false

    useEffect(() => {
        if (!_.isEmpty(project)) {
            setInitialValues(Immutable.Map(project))
        }
        return () => {
            setInitialValues(Immutable.Map({}))
        }
    }, [project])

    const formSelectedFileResource = useCallback(resourceId => {
        setTimelineFile(resourceId)
    }, [])

    const handleSubmit = ({ event, form: { validateFields }, formId }) => {
        event.preventDefault()

        if (isLoading) {
            return false
        }

        validateFields(async (formErrors, formValues) => {
            if (timelineFile !== null) {
                formValues.projectForm.timeline = timelineFile
            }

            const data = formValues.projectForm
            if (!formErrors) {
                const action =
                    formId === 'update'
                        ? () => {
                              return dispatch(updateProject(project.id, data))
                          }
                        : () => {
                              return dispatch(createProject(data))
                          }

                setIsLoading(true)
                action()
                    .then(response => {
                        setIsLoading(false)
                        projectSubmitted(response.data.id)
                    })
                    .catch(error => {
                        setErrors(error.response.data)
                        setIsLoading(false)
                    })
                    .finally(() => {
                        window.scrollTo(0, 0)
                    })
            } else {
                requestAnimationFrame(() => {
                    const $errorInput = event.target.querySelector('.is-error')

                    if ($errorInput) {
                        window.scrollTo({
                            top: $errorInput.offsetTop - 100,
                            behavior: 'smooth',
                        })

                        setTimeout(() => {
                            $errorInput.focus()
                        }, 500)
                    }
                })
            }
        })

        return false
    }

    const hasErrors = !_.isEmpty(errors)
    const notifications = hasErrors ? formatFormNotifications(errors) : []
    const hasNotifications = notifications.length > 0

    return (
        <FormContainer
            formId={formId}
            handleSubmit={handleSubmit}
            option={['delay2']}
            utility={['anim']}
            render={({ form }) => (
                <React.Fragment>
                    {hasNotifications && (
                        <FormItem>
                            <FormNotifications hasErrors={hasErrors} items={notifications} />
                        </FormItem>
                    )}

                    {/* 1. */}
                    <div className="u-margin-bottom-y" id="project-form-1">
                        <CardHeading>
                            <Translation value="projectForm.form1Title" />
                        </CardHeading>
                        <ProjectBlueprintContainer
                            render={({ items }) => (
                                <ProjectBlueprintSelector
                                    initialValue={initialValues.get('blueprint') ? initialValues.get('blueprint') : ''}
                                    isEdit={isEdit}
                                    items={items}
                                    form={form}
                                />
                            )}
                        />
                    </div>

                    {/* 2. */}
                    <div className="u-margin-y" id="project-form-2">
                        <CardHeading>
                            <Translation value="projectForm.form2Title" />
                        </CardHeading>

                        <Layout option={['gutter-small']}>
                            <LayoutItem>
                                <FormItem
                                    className={classNames({ 'has-error': !!form.getFieldError('projectForm.name') })}
                                >
                                    <FormLabel inputId="projectForm.name" isRequired>
                                        <Translation value="projectForm.name" />
                                    </FormLabel>
                                    <FormInput
                                        id="projectForm.name"
                                        name="projectForm.name"
                                        form={form}
                                        fieldOptions={{
                                            initialValue:
                                                initialValues.get('name') instanceof Object
                                                    ? utilTranslate(initialValues.get('name'))
                                                    : '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: translate('form.requiredField'),
                                                },
                                            ],
                                        }}
                                        className={classNames({ 'is-error': !!form.getFieldError('projectForm.name') })}
                                    />
                                    <FormError>{form.getFieldError('projectForm.name')}</FormError>
                                </FormItem>
                            </LayoutItem>
                            <LayoutItem>
                                <FormItem
                                    className={classNames({
                                        'has-error': !!form.getFieldError('projectForm.description'),
                                    })}
                                >
                                    <FormLabel inputId="projectForm.description">
                                        <Translation value="projectForm.description" />
                                    </FormLabel>
                                    <FormTextarea
                                        id="projectForm.description"
                                        name="projectForm.description"
                                        form={form}
                                        fieldOptions={{
                                            initialValue:
                                                initialValues.get('description') instanceof Object
                                                    ? utilTranslate(initialValues.get('description'))
                                                    : '',
                                        }}
                                        className={classNames({
                                            'is-error': !!form.getFieldError('projectForm.description'),
                                        })}
                                    />
                                    <FormError>{form.getFieldError('projectForm.description')}</FormError>
                                </FormItem>
                            </LayoutItem>
                        </Layout>
                    </div>

                    {/* 3. */}
                    <div className="u-margin-y" id="project-form-3">
                        <CardHeading>
                            <Translation value="projectForm.form3Title" />
                        </CardHeading>

                        <Layout option={['gutter-small']}>
                            <LayoutItem utility={['1/2@from-small']}>
                                <FormItem
                                    className={classNames({
                                        'has-error': !!form.getFieldError('projectForm.timeline'),
                                    })}
                                >
                                    <FormLabel isDecorative>
                                        <Translation value="projectForm.planning" />
                                    </FormLabel>
                                    <FormInputFile
                                        id="projectForm.timeline"
                                        name="projectForm.timeline"
                                        form={form}
                                        label="projectForm.planningInfo"
                                        selectedFileResource={serverId => formSelectedFileResource(serverId)}
                                        currentFileUrl={
                                            initialValues.get('timeline') instanceof Object
                                                ? initialValues.get('timeline').downloadUrl
                                                : ''
                                        }
                                        option={['full-height']}
                                        className={classNames({
                                            'is-error': !!form.getFieldError('projectForm.timeline'),
                                        })}
                                    />
                                    <FormError>{form.getFieldError('projectForm.timeline')}</FormError>
                                </FormItem>
                            </LayoutItem>
                            <LayoutItem utility={['1/2@from-small']}>
                                <FormItem
                                    className={classNames({
                                        'has-error': !!form.getFieldError('projectForm.timelineDate'),
                                    })}
                                >
                                    <FormLabel inputId="projectForm.timelineDate">
                                        <Translation value="projectForm.date-update" />
                                    </FormLabel>
                                    <FormInput
                                        id="projectForm.timelineDate"
                                        name="projectForm.timelineDate"
                                        form={form}
                                        type="date"
                                        fieldOptions={{
                                            initialValue:
                                                initialValues.get('timeline') instanceof Object &&
                                                initialValues.get('timeline').revisionDate !== null
                                                    ? moment(initialValues.get('timeline').revisionDate).format(
                                                          'YYYY-MM-DD'
                                                      )
                                                    : '',
                                        }}
                                        className={classNames({
                                            'is-error': !!form.getFieldError('projectForm.timelineDate'),
                                        })}
                                    />
                                    <FormError>{form.getFieldError('projectForm.timelineDate')}</FormError>
                                </FormItem>
                                <FormItem
                                    className={classNames({
                                        'has-error': !!form.getFieldError('projectForm.startDate'),
                                    })}
                                >
                                    <FormLabel inputId="projectForm.startDate">
                                        <Translation value="projectForm.date-begin" />
                                    </FormLabel>
                                    <FormInput
                                        id="projectForm.startDate"
                                        name="projectForm.startDate"
                                        form={form}
                                        type="date"
                                        fieldOptions={{
                                            initialValue: initialValues.get('startDate')
                                                ? moment(initialValues.get('startDate')).format('YYYY-MM-DD')
                                                : '',
                                        }}
                                        className={classNames({
                                            'has-error': !!form.getFieldError('projectForm.startDate'),
                                        })}
                                    />
                                    <FormError>{form.getFieldError('projectForm.startDate')}</FormError>
                                </FormItem>
                                <FormItem
                                    className={classNames({
                                        'has-error': !!form.getFieldError('projectForm.launchDate'),
                                    })}
                                >
                                    <FormLabel inputId="projectForm.launchDate">
                                        <Translation value="projectForm.date-launch" />
                                    </FormLabel>
                                    <FormInput
                                        id="projectForm.launchDate"
                                        name="projectForm.launchDate"
                                        form={form}
                                        type="date"
                                        fieldOptions={{
                                            initialValue: initialValues.get('launchDate')
                                                ? moment(initialValues.get('launchDate')).format('YYYY-MM-DD')
                                                : '',
                                        }}
                                        className={classNames({
                                            'has-error': !!form.getFieldError('projectForm.launchDate'),
                                        })}
                                    />
                                    <FormError>{form.getFieldError('projectForm.launchDate')}</FormError>
                                </FormItem>
                            </LayoutItem>
                        </Layout>
                    </div>

                    {/* 4. */}
                    <div className="u-margin-y" id="project-form-4">
                        <CardHeading>
                            <Translation value="projectForm.form4Title" />
                        </CardHeading>

                        <FormItem>
                            <FormError>{form.getFieldError('projectForm.employees')}</FormError>
                            <TeamMemberContainer
                                render={({ employees }) => (
                                    <TeamMemberSelector
                                        formId={formId}
                                        employees={employees}
                                        initialEmployeesValue={isEdit ? project.employees.map(item => item.id) : []}
                                        initialProducersValue={isEdit ? project.producers.map(item => item.id) : []}
                                        initialContactsValue={isEdit ? project.contacts.map(item => item.id) : []}
                                        form={form}
                                    />
                                )}
                            />
                        </FormItem>
                    </div>

                    {/* 5. */}
                    <div className="u-margin-y" id="project-form-5">
                        <CardHeading>
                            <Translation value="projectForm.form5Title" />
                        </CardHeading>

                        <CompanyContainer
                            render={({ companies, newCompany }) => (
                                <CompanySelector
                                    companies={companies}
                                    newCompany={newCompany}
                                    initialValue={initialValues.get('company') ? initialValues.get('company').id : ''}
                                    selectedCompany={updateQuickNav}
                                    form={form}
                                />
                            )}
                        />
                    </div>

                    {/* 6. */}
                    <ClientContainer
                        render={({ clients, newClient }) =>
                            form.getFieldValue('projectForm.company') !== '' && (
                                <div className="u-margin-y" id="project-form-6">
                                    <CardHeading>
                                        <Translation value="projectForm.form6Title" />
                                    </CardHeading>
                                    <ClientSelector
                                        clients={clients}
                                        newClient={newClient}
                                        company={form.getFieldValue('projectForm.company')}
                                        formId={formId}
                                        initialClientsValue={isEdit ? project.clients.map(item => item.id) : []}
                                        form={form}
                                    />
                                </div>
                            )
                        }
                    />

                    <div className="u-margin-y">
                        <Layout option={['gutter-small', 'middle']}>
                            <LayoutItem utility={['text-center', 'margin-y']}>
                                <Button
                                    className={classNames({ 'is-loading': isLoading })}
                                    hasWrapper
                                    option={['blue', 'medium', 'block', 'spinner', 'max-width']}
                                    type="submit"
                                >
                                    <Translation
                                        value={formId === 'create' ? 'projectForm.submit' : 'projectForm.update'}
                                    />
                                </Button>
                            </LayoutItem>

                            <LayoutItem utility={['text-center']}>
                                <ButtonLink option={['classic', 'gray']} route="project-list">
                                    <Translation value="projectForm.abort" />
                                </ButtonLink>
                            </LayoutItem>
                        </Layout>
                    </div>
                </React.Fragment>
            )}
        />
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default withLocalize(connect(mapStateToProps)(ProjectForm))
