import {
    FETCH_COMPANY_LIST_REQUEST,
    FETCH_COMPANY_LIST_SUCCESS,
    FETCH_COMPANY_LIST_FAILURE,
    CREATE_COMPANY_REQUEST,
    CREATE_COMPANY_SUCCESS,
    CREATE_COMPANY_FAILURE,
} from '../actions/companyActions'

const initialState = {
    entities: [],
    errors: null,
    isLoading: true,
    wasQueried: false,
    newCompany: null,
}

export function companies(state = initialState, action) {
    switch (action.type) {
        /**
         * Fetch project list.
         */
        case FETCH_COMPANY_LIST_REQUEST:
            return {
                ...state,
                errors: null,
            }
        case FETCH_COMPANY_LIST_SUCCESS:
            return {
                ...state,
                entities: action.result.data,
                errors: null,
                isLoading: false,
                wasQueried: true,
            }
        case FETCH_COMPANY_LIST_FAILURE:
            return {
                ...initialState,
                errors: action.error.response.data,
                isLoading: false,
                wasQueried: true,
            }

        /**
         * Add new company to list entites.
         */
        case CREATE_COMPANY_REQUEST:
            return {
                ...state,
            }
        case CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                newCompany: action.result.data,
                entities: [...state.entities, action.result.data],
            }
        case CREATE_COMPANY_FAILURE:
            return {
                ...state,
            }

        default:
            return state
    }
}
