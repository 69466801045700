import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { getCurrentRoute, renderRoute } from '../../utils'

import { Heading } from '../Heading'
import { Icon } from '../Icon'
import { Nav } from '../Nav'
import { UserMenu } from '../UserMenu'

import './_header.scss'

const Header = ({ handleLogout, languages = [], pathname, project = null, user, type }) => {
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)

    const handleToggleIsOpen = useCallback(() => {
        setIsUserMenuOpen(!isUserMenuOpen)
    }, [isUserMenuOpen, setIsUserMenuOpen])

    const isSimple = type === 'simple'

    const match = useRouteMatch()

    const currentRoute = getCurrentRoute(match.path)
    const languageRoutes = languages
        .filter(item => !item.active)
        .map(item => {
            return {
                lang: item.code,
                route: renderRoute(currentRoute.id, match.params, item.code),
            }
        })

    return (
        <header className="header">
            <div className="header_logo">
                <Icon name="logo-locomotive" title="Locomotive" />
            </div>
            {!isSimple && (
                <div>
                    <Nav pathname={pathname} project={project} />
                    <div className="header_hi">
                        <Heading option={['h1']}>Hello, {user.fullName}</Heading>
                    </div>
                </div>
            )}

            <div className="header_langswitch">
                {_.map(languageRoutes, (languageRoute, index) => (
                    <a className="header_langswitch_item" href={languageRoute.route} key={index}>
                        {languageRoute.lang}
                    </a>
                ))}
            </div>

            <UserMenu
                handleLogout={handleLogout}
                handleToggleIsOpen={handleToggleIsOpen}
                isOpen={isUserMenuOpen}
                languages={languages}
                project={project}
                user={user}
                languageRoutes={languageRoutes}
            />
        </header>
    )
}

Header.propTypes = {
    handleLogout: PropTypes.func.isRequired,
    languages: PropTypes.array,
    pathname: PropTypes.string.isRequired,
    project: PropTypes.object,
    user: PropTypes.object,
}

export default Header
