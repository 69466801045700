import PropTypes from 'prop-types'
import { formShape } from 'rc-form'
import React from 'react'

import { renderClasses } from '../../utils'

import './_formInput.scss'

const TYPES = ['text', 'email', 'phone', 'radio', 'password', 'number', 'url', 'date', 'hidden']

const FormInput = ({ fieldOptions = {}, form, id, value, name, placeholder = '', type = 'text', ...classProps }) => {
    if (TYPES.indexOf(type) === -1) {
        throw new Error('Invalid input type for FormInput.')
    }

    return form.getFieldDecorator(
        name,
        fieldOptions
    )(
        <input
            id={id}
            type={type}
            className={renderClasses('formInput', classProps)}
            placeholder={placeholder}
            name={name}
        />
    )
}

FormInput.propTypes = {
    fieldOptions: PropTypes.object,
    form: formShape,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
}

export default FormInput
