import _ from 'lodash'
import React, { useCallback, useReducer } from 'react'
import { connect } from 'react-redux'

import { fetchMilestone, updateMilestone } from '../../actions/milestoneActions'
import { itemDetailsInitialState, itemDetailsReducer, useFetchData, UPDATE_FAILURE, UPDATE_SUCCESS } from '../../utils'
import { NotFoundView } from '../../views'

const MilestoneContainer = ({
    milestoneId,
    milestoneRefreshTimestamp,
    onApproveSuccess,
    project,
    render,
    signature,
    user,
}) => {
    const [state, dispatch] = useReducer(itemDetailsReducer, itemDetailsInitialState)
    const { id: projectId } = project

    const tempMilestone = _.find(project.milestones, { id: milestoneId })

    const handleClickApprove = useCallback(() => {
        updateMilestone(projectId, milestoneId, {
            approved: true,
            approvalBy: user.id,
            approvalSignature: signature,
        })
            .then(response => {
                dispatch({ type: UPDATE_SUCCESS, payload: response.data })
                onApproveSuccess()
            })
            .catch(error => {
                dispatch({ type: UPDATE_FAILURE, payload: error.response })
            })
    }, [milestoneId, onApproveSuccess, projectId, signature, user])

    const fetchMilestoneCallback = useCallback(() => {
        return fetchMilestone(projectId, milestoneId)
    }, [milestoneId, projectId])

    useFetchData(fetchMilestoneCallback, state, dispatch, milestoneRefreshTimestamp)

    const { error, isLoading, item: milestone } = state

    if (isLoading && tempMilestone) {
        return render({ handleClickApprove, milestone: { ...tempMilestone, isTemp: true } })
    } else if (error !== null || typeof tempMilestone === 'undefined') {
        console.warn(error)
        return <NotFoundView />
    } else {
        if (_.isNil(milestone)) {
            console.warn(`Milestone not found: ${milestoneId}`)
            console.log(milestone)
            return <NotFoundView />
        } else {
            return render({ handleClickApprove, milestone })
        }
    }
}

const mapStateToProps = state => ({
    milestoneRefreshTimestamp: state.currentMilestone.refreshTimestamp,
    user: state.auth.user,
})

export default connect(mapStateToProps)(MilestoneContainer)
